import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Search,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import RemappingModal from 'components/custom_segments/RemappingModal';
import { dimensionProps } from 'components/custom_segments/UnparsableCampaigns';
import ItemsTable from 'components/molecules/ItemsTable';
import { getUniques } from 'utilities/array';
import { saveFile } from 'utilities/file';
import {
  getItemsCsv,
  getItemSortBy,
} from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import {
  remapDimensionsTaxonomyCustomManuallyMappedCampaignPath,
} from 'utilities/routes';
import styles from './ManuallyMappedCampaigns.module.css';
import { getValuesPerCustomDimension } from './shared';
import { toSnakeCaseKeys } from '../../utilities/object';
import {
  addFormDataArray,
  post,
} from '../../utilities/requests';
import { saveToLocalStorage } from '../../utilities/storage';
import { addToast } from '../organisms/Toasts';

const campaignProps = PropTypes.shape({
  accountId: PropTypes.string,
  channel: PropTypes.string,
  channelLabel: PropTypes.string,
  customDimensions: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number,
  name: PropTypes.string,
});

const propTypes = {
  customDimensionsWithValues: PropTypes.arrayOf(dimensionProps).isRequired,
  campaigns: PropTypes.arrayOf(campaignProps),
};

const defaultProps = {
  campaigns: [],
};

async function submitRemapping(campaignId, channel, remappedDimensions) {
  const dimensionsList = Object.values(remappedDimensions).map(
    (dimension) => toSnakeCaseKeys(dimension),
  );

  const formData = new FormData();
  formData.append('channel', channel);
  addFormDataArray(formData, 'dimensions', dimensionsList);

  const response = await post(
    remapDimensionsTaxonomyCustomManuallyMappedCampaignPath(campaignId),
    formData,
  );
  return response.data;
}

function getHeaders(isCsv) {
  return [
    {
      key: 'name',
      label: 'Campaign Name',
    },
    {
      key: 'dimensions',
      label: 'Manually Mapped Segments',
    },
    {
      key: 'accountId',
      label: 'Ad Account ID',
    },
    {
      key: isCsv ? 'channelLabel' : 'channel',
      label: 'Channel',
    },
  ];
}

function getCustomDimensionOptions(customDimensionsWithValues) {
  const uniqueDimensions = getUniques(
    customDimensionsWithValues.map((dimension) => dimension.name),
  ).sort();

  return uniqueDimensions.map((dimension) => ({
    label: dimension,
    value: dimension,
  }));
}

function getChannelOptions(campaigns) {
  const uniqueChannels = getUniques(campaigns.map((campaign) => campaign.channelLabel)).sort();

  return uniqueChannels.map((channel) => ({
    label: channel,
    value: channel,
  }));
}

function getDimensionsString(customDimensions) {
  return customDimensions.map((dimension) => dimension.split('::')[0]).join(', ');
}

function getItems(campaigns, setSelectedCampaign) {
  return campaigns.map((campaign) => setItemElement({
    id: { value: campaign.id },
    name: {
      element: (
        <Button
          label={campaign.name}
          variant="tertiary"
          onClick={() => setSelectedCampaign(campaign)}
        />
      ),
      value: campaign.name,
    },
    dimensions: { value: getDimensionsString(campaign.customDimensions) },
    accountId: { value: campaign.accountId },
    channel: {
      element: (
        <ChannelLogo channel={campaign.channel} size="size-24" />
      ),
      value: campaign.channel,
    },
    channelLabel: { value: campaign.channelLabel },
  }));
}

function ManuallyMappedCampaigns({
  campaigns,
  customDimensionsWithValues,
}) {
  const [customDimensionFilter, setCustomDimensionFilter] = useState(null);
  const [channelFilter, setChannelFilter] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [sort, setSort] = useState({
    key: 'name',
    asc: false,
  });
  const [page, setPage] = useState(1);

  const customDimensionOptions = useMemo(
    () => getCustomDimensionOptions(customDimensionsWithValues), [customDimensionsWithValues],
  );
  const channelOptions = useMemo(() => getChannelOptions(campaigns), [campaigns]);
  const valuesPerCustomDimension = useMemo(
    () => getValuesPerCustomDimension(customDimensionsWithValues), [customDimensionsWithValues],
  );

  const filteredCampaigns = useMemo(() => campaigns.filter(({
    channelLabel,
    accountId,
    name,
    customDimensions,
  }) => {
    if (channelFilter && channelLabel !== channelFilter.value) return false;
    if (search) {
      const term = search.toLowerCase();
      return accountId.toLowerCase().includes(term) || name.toLowerCase().includes(term);
    }
    if (customDimensionFilter) {
      return customDimensions.some((dimension) => dimension.includes(customDimensionFilter.value));
    }
    return true;
  }), [campaigns, channelFilter, customDimensionFilter, search]);

  const items = useMemo(
    () => getItems(filteredCampaigns, setSelectedCampaign), [filteredCampaigns],
  );
  const sortedItems = useMemo(() => {
    const dir = getItemSortBy(sort.key, sort.asc);
    return items.slice().sort(dir);
  }, [items, sort]);

  const hasFiltersOrSearch = customDimensionFilter || channelFilter || search;

  const handleClearFiltersAndSearch = () => {
    setCustomDimensionFilter(null);
    setChannelFilter(null);
    setSearch('');
  };

  const handleExportCsv = () => {
    const fileName = 'manually_mapped_custom_campaigns.csv';
    const csv = getItemsCsv(sortedItems, getHeaders(true));
    saveFile(fileName, csv);
  };

  const handleMappingUpdate = async (remappedDimensions) => {
    const { id, channel } = selectedCampaign;
    const response = await submitRemapping(id, channel, remappedDimensions);

    addToast(response.message, { type: response.success ? 'success' : 'error' });
    saveToLocalStorage(page, 'manuallyMappedCampaignsPage');
    window.location.reload();
  };

  return (
    <>
      <Card>
        <div className="u-flexRow u-justifyBetween u-marginBottom-16 u-alignEnd">
          <div className="u-flexRow u-gap-16 u-alignEnd">
            <Dropdown
              disabled={customDimensionOptions.length === 0}
              label="Custom Segment"
              menuProps={{ size: 'medium' }}
              options={customDimensionOptions}
              selected={customDimensionFilter}
              size="medium"
              onChange={(value) => setCustomDimensionFilter(value)}
            />
            <Dropdown
              disabled={channelOptions.length === 0}
              label="Channel"
              menuProps={{ size: 'medium' }}
              options={channelOptions}
              selected={channelFilter}
              size="medium"
              onChange={(value) => setChannelFilter(value)}
            />
            <Search
              disabled={campaigns.length === 0}
              size="medium"
              value={search}
              onChange={setSearch}
            />
            { hasFiltersOrSearch && (
              <Button
                label="Clear"
                variant="tertiary"
                onClick={handleClearFiltersAndSearch}
              />
            ) }
          </div>
          <Button
            label="Export CSV"
            onClick={handleExportCsv}
          />
        </div>
        <Divider />
        <ItemsTable
          className={`${styles.campaignsTable} ${styles.fullWidth}`}
          headers={getHeaders(false)}
          items={sortedItems}
          page={page}
          sort={sort}
          onPageChange={(p) => setPage(p)}
          onSortChange={(value) => setSort(value)}
        />
      </Card>
      <RemappingModal
        campaign={selectedCampaign}
        isOpen={!!selectedCampaign}
        valuesPerCustomDimension={valuesPerCustomDimension}
        onClose={() => setSelectedCampaign(null)}
        onUpdate={handleMappingUpdate}
      />
    </>
  );
}

ManuallyMappedCampaigns.propTypes = propTypes;
ManuallyMappedCampaigns.defaultProps = defaultProps;

export default ManuallyMappedCampaigns;
