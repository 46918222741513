// Helper functions for manipulating javascript numbers

// Check whether a value is an integer, within an optional range
export function isInteger(value, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY) {
  return Number.isInteger(value) && value >= min && value <= max;
}

// Round to 'decimals' digits of precision and convert to string.
// If skipWholeNums is true, skip adding digits (ex: 50 -> 50 rather than 50.00)
export function toStringWithDecimals(num, decimalDigits = 2, skipWholeNums = true) {
  const isWholeNum = num % 1 === 0;
  if (skipWholeNums && isWholeNum) return num.toString();

  return num.toFixed(decimalDigits);
}

export function stringToInt(str) {
  const value = parseInt(str);

  return Number.isNaN(value) ? null : value;
}

export function clampToRange(min, value, max) {
  return Math.max(min, Math.min(max, value));
}

export function rate(numerator, denominator) {
  return denominator > 0 ? numerator / denominator : null;
}

/**
 * Takes a number and returns a "nicer" (whole) number.
 * For example, given the number 12345, this function will return 10000.
 * This is because the number 12345 is represented as 1.2345 x 10^4,
 * and the function returns 1 x 10^4, which is 10000.
 *
 * Useful for axis labels, ranges, buckets, etc.
 *
 * @param {number} value - The number to be "nicified"
 * @return {number} The nicified number
 */
export function getNiceNumber(value) {
  const exponent = Math.floor(Math.log10(value));
  const fraction = value / (10 ** exponent);
  let niceFraction;

  if (fraction < 6) {
    niceFraction = Math.floor(fraction);
  } else {
    niceFraction = 10;
  }

  return niceFraction * (10 ** exponent);
}
