import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Table,
  Tag,
} from '@makeably/creativex-design-system';
import Accordion from 'components/atoms/Accordion';
import { guidelinesPath } from 'utilities/routes';
import { toPercent } from 'utilities/string';
import styles from './ScoreRow.module.css';

const propTypes = {
  brand: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      scores: PropTypes.arrayOf(PropTypes.number).isRequired,
      weight: PropTypes.string.isRequired,
      definitions: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          weight: PropTypes.string.isRequired,
          passed: PropTypes.bool,
        }),
      ),
      passed: PropTypes.bool,
      templateUrl: PropTypes.string,
    }),
  ).isRequired,
  isDefaultScore: PropTypes.bool.isRequired,
  isPreflight: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  placements: PropTypes.arrayOf(PropTypes.string).isRequired,
  scoreId: PropTypes.number.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setTemplateUrl: PropTypes.func.isRequired,
  rank: PropTypes.string,
  tier: PropTypes.string,
  value: PropTypes.number,
};

const defaultProps = {
  rank: null,
  tier: null,
  value: null,
};

const rankColors = {
  highest: 'green',
  high: 'blue',
  medium: 'orange',
  low: 'orange',
  lowest: 'red',
};

export const renderScore = (overallScore, defaultRank, tier) => {
  if (overallScore === null || defaultRank === null || tier === null) {
    return null;
  }

  const color = rankColors[defaultRank];

  return (
    <Tag
      color={color}
      label={`${tier}: ${toPercent(parseFloat(overallScore))}`}
    />
  );
};

const PASS_CONTENT = (
  <>
    <div>Yes</div>
    <Icon color="green" name="checkCircle" />
  </>
);

const FAIL_CONTENT = (
  <>
    <div>No</div>
    <Icon color="red" name="exclamationCircle" />
  </>
);

const PROCESSING_CONTENT = (
  <>
    <div>Processing</div>
    <Icon color="grey" name="processing" />
  </>
);

export const statusIcon = (passed) => (
  <div className="u-flexRow u-alignCenter u-gap-8 u-justifyEnd">
    { passed === null && PROCESSING_CONTENT }
    { passed === true && PASS_CONTENT }
    { passed === false && FAIL_CONTENT }
  </div>
);

const standardHeaders = [
  { value: 'Definition' },
];

const headers = (templateUrlPresent) => {
  let headerList = standardHeaders;
  if (templateUrlPresent) {
    headerList = headerList.concat({ value: '' });
  }

  return headerList.concat(
    [
      {
        value: 'Weight',
      },
      {
        className: styles.criteriaMet,
        value: 'Criteria Met',
      },
    ],
  );
};

const titleRow = ({
  assetType,
  brand,
  channel,
  hasTemplateUrl,
  id,
  isPreflight,
  market,
  name,
  placements,
}) => {
  const guidelinePath = guidelinesPath({
    assetType,
    brand,
    campaignStatus: isPreflight ? 'preflight' : 'audit',
    channel,
    guideline: id,
    market,
    placements: placements ? placements.join(',') : undefined,
  });
  const cells = [
    {
      className: styles.noBorder,
      value: (
        <a
          href={guidelinePath}
          rel="noreferrer"
          target="_blank"
        >
          { name }
        </a>
      ),
    },
    {
      className: styles.noBorder,
      value: '',
    },
    {
      className: styles.noBorder,
      value: '',
    },
  ];
  if (hasTemplateUrl) {
    cells.push({
      className: styles.noBorder,
      value: '',
    });
  }
  return {
    key: name,
    cells,
  };
};

const guidelineRow = ({
  description,
  hasTemplateUrl,
  isDefinition,
  isLast,
  name,
  passed,
  rowTemplateUrl,
  setIsModalOpen,
  setTemplateUrl,
  weight,
}) => {
  let cells = [{
    className: !isLast && styles.noBorder,
    value: (
      <div className="u-flexColumn">
        {
          isDefinition ? `${name}: ${description}` : description
        }

      </div>
    ),
  }];
  if (hasTemplateUrl) {
    if (rowTemplateUrl) {
      cells.push({
        className: !isLast && styles.noBorder,
        value: (
          <Button
            label="View"
            variant="text"
            onClick={() => {
              setTemplateUrl(rowTemplateUrl);
              setIsModalOpen(true);
            }}
          />
        ),
      });
    } else {
      cells.push({
        className: !isLast && styles.noBorder,
        value: '',
      });
    }
  }
  cells = cells.concat([{
    className: !isLast && styles.noBorder,
    value: (
      <div className="u-flexColumn">
        { weight }
      </div>
    ),
  }, {
    className: !isLast && styles.noBorder,
    value: statusIcon(passed),
  }]);
  return {
    key: name,
    cells,
  };
};

const ScoreRow = ({
  brand,
  channel,
  contentType,
  guidelines,
  isDefaultScore,
  isPreflight,
  label,
  market,
  placements,
  rank,
  scoreId,
  setIsModalOpen,
  setTemplateUrl,
  value,
  tier,
}) => {
  const guidelineRowConstructor = (guideline, hasTemplateUrl) => {
    const commonProps = {
      brand,
      channel,
      contentType,
      hasTemplateUrl,
      isPreflight,
      market,
      placements,
      rowTemplateUrl: guideline.templateUrl,
      setIsModalOpen,
      setTemplateUrl,
    };

    if (guideline.definitions) {
      return [
        titleRow({
          ...guideline,
          ...commonProps,
        }),
        ...guideline.definitions.map((definition, index) => guidelineRow({
          ...definition,
          isDefinition: true,
          isLast: index === guideline.definitions.length - 1,
          ...commonProps,
        })),
      ];
    }
    return [
      titleRow({
        ...guideline,
        ...commonProps,
      }),
      guidelineRow({
        ...guideline,
        ...commonProps,
        isLast: true,
      }),
    ];
  };

  const hasTemplateUrl = guidelines.some(
    (guideline) => guideline.templateUrl && guideline.templateUrl != null,
  );
  const scoreLabelVisible = !guidelines.some(
    (guideline) => guideline.weight !== '0%' && guideline.passed === null,
  );

  return (
    <Accordion
      key={scoreId}
      header={(
        <div className={styles.scoreRow}>
          <div className="u-flexRow u-alignCenter u-gap-8">
            <div className="u-flexRow u-alignCenter">
              <div className="t-body-1">{ label }</div>
            </div>
            { isDefaultScore && (
              <Tag color="grey" label="Default" />
            ) }
          </div>
          { scoreLabelVisible && renderScore(value, rank, tier) }
        </div>
      )}
      id={scoreId}
      initiallyExpanded={false}
    >
      <Table
        className={styles.table}
        headers={headers(hasTemplateUrl)}
        rows={guidelines.flatMap((guideline) => guidelineRowConstructor(guideline, hasTemplateUrl))}
      />
    </Accordion>
  );
};

ScoreRow.propTypes = propTypes;
ScoreRow.defaultProps = defaultProps;

export default ScoreRow;
