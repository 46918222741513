// Used for filtering Pre-flights and Audits for admin
import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Divider,
  FormGroup,
  FormGroupContainer,
  Radio,
  Select,
  Widget,
  WidgetContent,
  WidgetHeader,
} from '@duik/it';
import {
  Button,
  Icon,
  TextInput,
} from '@makeably/creativex-design-system';
import DuikSelectDate from 'components/molecules/DuikSelectDate';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import InputValidation from 'components/reusable/forms/InputValidation';
import {
  HiddenInput,
  HiddenInputs,
} from 'components/reusable/HiddenInput';
import Loader from 'components/reusable/Loader';
import SearchableSelect from 'components/reusable/SearchableSelect';
import ArrayHelper from 'components/utils/ArrayHelper';
import ObjectHelper from 'components/utils/ObjectHelper';
import StringHelper from 'components/utils/StringHelper';
import { adminAuditReprocessesPath } from 'utilities/routes';

const propTypes = {
  dataUrl: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

function reprocessDetails(type) {
  switch (type) {
    case 'keep':
      return {
        label: 'Keep Existing Checks and Process',
        description: 'For applying new Rules and/or new Definitions without altering pre-existing content',
      };
    case 'remove':
      return {
        label: 'Override Existing Checks and Process',
        description: 'For when you updated an older definition and/or would like to remove all existing checks and have them re-evaluated',
      };
    case 'remove_all_checks':
      return {
        label: 'REMOVE Existing Checks and DO NOT REAPPLY',
        description: 'For when you want to burn it all down (ex. rule deletion) and remove existing checks without reapplying',
      };
    case 'representation':
      return {
        label: 'Reprocess Audit Assets for Representation',
        description: 'For when you want to process historical assets for representation review',
      };
    default:
      throw new Error('reprocessDetails requires a type to be set');
  }
}

function reprocessQueueDetails(type) {
  switch (type) {
    case 'high':
      return {
        label: 'High Priority',
        description: 'Reserved for time-sensitive jobs. These will be run independently from the Standard Priority Queue and should run more efficiently.',
      };
    case 'standard':
      return {
        label: 'Standard Priority',
        description: 'For regular jobs that aren’t time-sensitive. Most jobs should be run using this queue.',
      };
    default:
      throw new Error('reprocessQueueDetails requires a type to be set');
  }
}

function FormDetails(props) {
  const {
    adAccountApiIds,
    adCampaignIds,
    assetType,
    auditAssetIds,
    brands,
    channels,
    endDate,
    error,
    loading,
    markets,
    reprocessName,
    onAssetTypeChange,
    onDateChange,
    onReprocessNameChange,
    onReprocessQueueChange,
    onReprocessTypeChange,
    onSelectChange,
    onTextChange,
    options,
    reprocessQueue,
    reprocessType,
    rules,
    startDate,
    visible,
  } = props;

  if (!visible) return null;
  if (loading) return <Loader />;
  if (error) {
    return (
      <Alert danger>
        Encountered an error. If the problem persists contact Engineering.
      </Alert>
    );
  }

  const minDate = StringHelper.stringToDate('2018-01-01');
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);

  const rulesDisabled = reprocessType === 'representation';
  const ruleButtonProps = { disabled: rulesDisabled };

  return (
    <FormGroupContainer>
      <Divider margin />
      <FormGroupContainer horizontal>
        <DuikSelectDate
          initialVisibleDate={startOfYear}
          label="Start Date (required)"
          maxDate={today}
          minDate={minDate}
          value={startDate}
          block
          onDateChange={(date) => onDateChange(date, 'startDate')}
        />
        <DuikSelectDate
          label="End Date (if left blank, will default to today)"
          maxDate={today}
          minDate={startDate}
          value={endDate}
          block
          onDateChange={(date) => onDateChange(date, 'endDate')}
        />
      </FormGroupContainer>
      <Divider />
      <FormGroupContainer horizontal>
        <Select
          activeOption={assetType}
          label="Asset Type"
          options={ObjectHelper.valuesToObjects(options.assetTypes)}
          block
          onOptionClick={onAssetTypeChange}
        />
        <SearchableSelect
          activeOption={channels}
          label="Channel(s)"
          name="channels"
          options={options.channels}
          block
          multiple
          onOptionClick={onSelectChange}
        />
        <SearchableSelect
          activeOption={brands}
          label="Brand(s)"
          name="brands"
          options={ObjectHelper.valuesToObjects(options.brands)}
          block
          multiple
          onOptionClick={onSelectChange}
        />
        <SearchableSelect
          activeOption={markets}
          label="Market(s)"
          name="markets"
          options={ObjectHelper.valuesToObjects(options.markets)}
          block
          multiple
          onOptionClick={onSelectChange}
        />
      </FormGroupContainer>
      <Divider />
      <FormGroupContainer horizontal>
        <TextInput
          label="Name"
          name="reprocessName"
          placeholder="Name of Reprocessing Job"
          size="medium"
          value={reprocessName}
          onChange={(value) => onReprocessNameChange(value)}
        />
        <TextInput
          label="Audit Asset IDs"
          name="auditAssetIds"
          placeholder="Comma-separated ids"
          size="medium"
          value={auditAssetIds}
          onChange={(value) => onTextChange(value, 'auditAssetIds')}
        />
        <TextInput
          label="Ad Account IDs"
          name="adAccountApiIds"
          placeholder="Comma-separated ids"
          size="medium"
          value={adAccountApiIds}
          onChange={(value) => onTextChange(value, 'adAccount')}
        />
        <TextInput
          label="Ad Campaign IDs"
          name="adCampaignIds"
          placeholder="Comma-separated ids"
          size="medium"
          value={adCampaignIds}
          onChange={(value) => onTextChange(value, 'adCampaign')}
        />
      </FormGroupContainer>
      <Divider />
      <FormGroupContainer horizontal>
        <SearchableSelect
          activeOption={rules}
          buttonProps={ruleButtonProps}
          label="Rule(s)"
          name="rules"
          options={options.rules}
          block
          multiple
          onOptionClick={onSelectChange}
        />
        <FormGroupContainer>
          <div className="asLabel">Type of Reprocess</div>
          {
            options.reprocessTypes.map((type) => {
              const { label, description } = reprocessDetails(type);

              return (
                <Radio
                  key={type}
                  checked={reprocessType === type}
                  description={description}
                  label={label}
                  value={type}
                  onChange={(e) => onReprocessTypeChange(e.target.value)}
                />
              );
            })
          }
        </FormGroupContainer>
      </FormGroupContainer>
      <Divider />
      <FormGroupContainer horizontal>
        <div className="asLabel">Job Priority</div>
        {
          options.reprocessQueues.map((type) => {
            const { label, description } = reprocessQueueDetails(type);

            return (
              <Radio
                key={type}
                checked={reprocessQueue === type}
                description={description}
                label={label}
                value={type}
                onChange={(e) => onReprocessQueueChange(e.target.value)}
              />
            );
          })
        }
      </FormGroupContainer>
    </FormGroupContainer>
  );
}
FormDetails.propTypes = {
  assetType: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  markets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  options: PropTypes.shape({
    assetTypes: PropTypes.arrayOf(PropTypes.string),
    brands: PropTypes.arrayOf(PropTypes.string),
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    markets: PropTypes.arrayOf(PropTypes.string),
    reprocessQueues: PropTypes.arrayOf(PropTypes.string),
    reprocessTypes: PropTypes.arrayOf(PropTypes.string),
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  reprocessQueue: PropTypes.oneOf(['standard', 'high']).isRequired,
  reprocessType: PropTypes.oneOf(['keep', 'remove']).isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  visible: PropTypes.bool.isRequired,
  onAssetTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onReprocessNameChange: PropTypes.func.isRequired,
  onReprocessQueueChange: PropTypes.func.isRequired,
  onReprocessTypeChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  adAccountApiIds: PropTypes.string,
  adCampaignIds: PropTypes.string,
  auditAssetIds: PropTypes.string,
  endDate: PropTypes.instanceOf(Date),
  reprocessName: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
};
FormDetails.defaultProps = {
  adAccountApiIds: undefined,
  adCampaignIds: undefined,
  auditAssetIds: undefined,
  endDate: undefined,
  reprocessName: undefined,
  startDate: undefined,
};

class ReprocessAuditForm extends React.Component {
  static isIncomplete(state) {
    const {
      assetType,
      brands,
      channels,
      error,
      errorMessages,
      markets,
      orgId,
      startDate,
      rules,
      reprocessType,
    } = state;

    const complete = orgId
      && startDate
      && assetType
      && brands.length > 0
      && channels.length > 0
      && markets.length > 0
      && (rules.length > 0 || reprocessType === 'representation')
      && errorMessages.length === 0
      && !error;

    return !complete;
  }

  constructor(props) {
    super(props);

    this.state = {
      adAccount: '',
      adCampaign: '',
      assetType: {
        label: 'All',
        value: 'All',
      },
      auditAssetIds: '',
      brands: [],
      channels: [],
      endDate: undefined,
      error: false,
      errorMessages: [],
      loading: false,
      markets: [],
      reprocessName: undefined,
      options: {},
      orgId: undefined,
      reprocessQueue: 'standard',
      reprocessType: 'keep',
      rules: [],
      startDate: undefined,
    };
  }

  onAssetTypeChange(assetType) {
    this.setState({ assetType });
  }

  onReprocessQueueChange(reprocessQueue) {
    this.setState({ reprocessQueue });
  }

  onReprocessTypeChange(reprocessType) {
    this.setState({ reprocessType });
    if (reprocessType === 'representation') {
      this.setState({ rules: [] });
    }
  }

  onSelectChange(option, name) {
    this.setState((prevState) => {
      const values = ArrayHelper.withOrWithoutObject(prevState[name], option);

      let { errorMessages } = prevState;
      const error = `${StringHelper.titleize(name)} can't include All and Other Option(s)`;
      if (ArrayHelper.objectInArray(values, {
        label: 'All',
        value: 'All',
      }) && values.length > 1) {
        errorMessages.push(error);
      } else {
        errorMessages = ArrayHelper.removeFromArray(prevState.errorMessages, error);
      }
      errorMessages.sort();

      return {
        [name]: values,
        errorMessages,
      };
    });
  }

  onDateChange(date, name) {
    this.setState({ [name]: date });
  }

  onReprocessNameChange(text) {
    this.setState({ reprocessName: text });
  }

  onTextChange(text, name) {
    this.setState((prevState) => {
      let { errorMessages } = prevState;
      const characterError = `${StringHelper.titleize(name)} can't include delimiters other than a comma (., ;, ?, etc)`;
      if (text.match(new RegExp('[.;:\'"!?\\/]')) !== null) {
        // Avoid adding the same error message multiple times
        if (!errorMessages.includes(characterError)) {
          errorMessages.push(characterError);
        }
      } else {
        errorMessages = ArrayHelper.removeFromArray(errorMessages, characterError);
      }

      const textArray = text.split(',');
      const duplicates = textArray.filter((item, index) => textArray.indexOf(item) !== index);
      const duplicatesError = `${StringHelper.titleize(name)} can't include duplicate entries`;
      if (duplicates.length >= 1) {
        // Avoid adding the same error message multiple times
        if (!errorMessages.includes(duplicatesError)) {
          errorMessages.push(duplicatesError);
        }
      } else {
        errorMessages = ArrayHelper.removeFromArray(errorMessages, duplicatesError);
      }

      errorMessages.sort();

      return {
        [name]: text,
        errorMessages,
      };
    });
  }

  getFormData(orgId) {
    this.setState({ loading: true });

    const { dataUrl } = this.props;
    const url = new URL(dataUrl);
    url.searchParams.append('org_id', orgId);

    fetch(url)
      .then((resp) => resp.json())
      .then((jsonData) => {
        this.setState(
          {
            adAccount: '',
            adCampaign: '',
            assetType: {
              label: 'All',
              value: 'All',
            },
            auditAssetIds: '',
            brands: [],
            channels: [],
            endDate: undefined,
            loading: false,
            markets: [],
            reprocessName: undefined,
            options: jsonData,
            rules: [],
            startDate: undefined,
          },
        );
      })
      .catch((_error) => this.setState({
        loading: false,
        error: true,
      }));
  }

  updateOrgId(orgId) {
    this.setState({ orgId }, this.getFormData(orgId));
  }

  render() {
    const {
      formUrl,
      organizations,
    } = this.props;

    const {
      adAccount,
      adCampaign,
      assetType,
      auditAssetIds,
      brands,
      channels,
      endDate,
      error,
      errorMessages,
      loading,
      markets,
      reprocessName,
      options,
      orgId,
      reprocessQueue,
      reprocessType,
      rules,
      startDate,
    } = this.state;

    const activeOrg = organizations.find((elm) => elm.value === orgId);
    const visible = orgId !== undefined;

    return (
      <div className="dashboardLayout auditReprocessor">
        <div className="auditReprocessor-alert u-marginAboveSm u-marginBelow">
          <Alert warning>
            <div className="u-flexRow">
              <Icon color="darkPurple" name="exclamationTriangle" />
              <span>
                NOTE: Completing this form will cause a reprocess for the in-flight content
                relevant to the filters you selected. This action CAN&apos;T be undone.
              </span>
            </div>
          </Alert>
        </div>
        <Widget>
          <WidgetHeader>
            <h5>Select in-flight content to reprocess</h5>
          </WidgetHeader>
          <WidgetContent>
            <FormGroupContainer>
              <SearchableSelect
                activeOption={activeOrg}
                label="Client"
                options={organizations}
                onOptionClick={(option) => this.updateOrgId(option.value)}
              />
              <FormDetails
                adAccountApiIds={adAccount}
                adCampaignIds={adCampaign}
                assetType={assetType}
                auditAssetIds={auditAssetIds}
                brands={brands}
                channels={channels}
                endDate={endDate}
                error={error}
                loading={loading}
                markets={markets}
                options={options}
                reprocessName={reprocessName}
                reprocessQueue={reprocessQueue}
                reprocessType={reprocessType}
                rules={rules}
                startDate={startDate}
                visible={visible}
                onAssetTypeChange={(option) => this.onAssetTypeChange(option)}
                onDateChange={(date, name) => this.onDateChange(date, name)}
                onReprocessNameChange={(option) => this.onReprocessNameChange(option)}
                onReprocessQueueChange={(option) => this.onReprocessQueueChange(option)}
                onReprocessTypeChange={(option) => this.onReprocessTypeChange(option)}
                onSelectChange={(option, name) => this.onSelectChange(option, name)}
                onTextChange={(option, name) => this.onTextChange(option, name)}
              />
            </FormGroupContainer>
          </WidgetContent>
          <WidgetContent>
            <FormGroupContainer>
              <form action={formUrl} method="post">
                <AuthenticityTokenInput />
                <HiddenInput name="audit_reprocess[end_date]" value={StringHelper.dateToString(endDate)} />
                <HiddenInput name="audit_reprocess[organization_id]" value={orgId} />
                <HiddenInput name="audit_reprocess[start_date]" value={StringHelper.dateToString(startDate)} />
                <HiddenInput name="audit_reprocess[asset_type]" value={assetType.value} />
                <HiddenInput name="audit_reprocess[reprocess_priority]" value={reprocessQueue} />
                <HiddenInput name="audit_reprocess[reprocess_type]" value={reprocessType} />
                <HiddenInput name="audit_reprocess[reprocess_name]" value={reprocessName} />
                <HiddenInputs name="audit_reprocess[brands][]" values={ArrayHelper.values(brands)} />
                <HiddenInputs name="audit_reprocess[channels][]" values={ArrayHelper.values(channels)} />
                <HiddenInputs name="audit_reprocess[markets][]" values={ArrayHelper.values(markets)} />
                <HiddenInputs name="audit_reprocess[rules][]" values={ArrayHelper.values(rules)} />
                <HiddenInputs name="audit_reprocess[ad_account][]" values={adAccount.split(',')} />
                <HiddenInputs name="audit_reprocess[ad_campaign][]" values={adCampaign.split(',')} />
                <HiddenInputs name="audit_reprocess[audit_asset_ids][]" values={auditAssetIds.split(',')} />
                <div className="u-flexColumn">
                  {
                    errorMessages.map((errorMsg, idx) => (
                      /* eslint-disable react/no-array-index-key */
                      <InputValidation
                        key={idx}
                        label={errorMsg}
                        success={false}
                        value="value"
                      />
                      /* eslint-enable react/no-array-index-key */
                    ))
                  }
                </div>
                <FormGroup>
                  <div className="u-buttonGroup u-spaceBetween">
                    <Button
                      label="Cancel"
                      url={adminAuditReprocessesPath()}
                      variant="secondary"
                    />
                    <input
                      className="btn btn-primary"
                      disabled={ReprocessAuditForm.isIncomplete(this.state)}
                      name="continue"
                      type="submit"
                      value="Continue"
                    />
                  </div>
                </FormGroup>
              </form>
            </FormGroupContainer>
          </WidgetContent>
        </Widget>
      </div>
    );
  }
}

ReprocessAuditForm.propTypes = propTypes;

export default ReprocessAuditForm;
