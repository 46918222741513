import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  BasicModal,
  Button,
  CloseButton,
} from '@makeably/creativex-design-system';
import { track } from 'utilities/mixpanel';
import { auditScorecardPath } from 'utilities/routes';
import styles from './AssetModal.module.css';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  assetId: PropTypes.number,
  canViewCreatives: PropTypes.bool,
  videoSource: PropTypes.string,
};

const defaultProps = {
  assetId: undefined,
  canViewCreatives: false,
  videoSource: undefined,
};

function calcImageStyle(aspectRatio) {
  const maxPercent = 70;

  if (!aspectRatio) {
    return {};
  }

  if (aspectRatio > 1.0) {
    return {
      height: `${maxPercent / aspectRatio}vw`,
      width: `${maxPercent}vw`,
    };
  }

  return {
    height: `${maxPercent}vh`,
    width: `${aspectRatio * maxPercent}vh`,
  };
}

function AssetModal({
  assetId,
  canViewCreatives,
  isOpen,
  onClose,
  url,
  videoSource,
}) {
  const [aspectRatio, setAspectRatio] = useState();

  const bodyClasses = classNames(
    styles.body,
    {
      [styles.bodyWithoutFooter]: !canViewCreatives,
    },
  );

  const showAssetDetails = () => {
    const params = {
      include_matched_posts: true,
      all_time: true,
    };

    const scorecardUrl = auditScorecardPath(assetId, params);

    track('view_creative_details');
    window.open(scorecardUrl, '_blank', 'noreferrer');
  };

  const handleImageLoad = ({ target }) => {
    setAspectRatio(target.width / target.height);
  };

  const handleVideoLoad = ({ target }) => {
    setAspectRatio(target.videoWidth / target.videoHeight);
  };

  const renderAsset = () => {
    if (videoSource) {
      return (
        <video
          alt="Core Asset Preview"
          src={videoSource}
          autoPlay
          controls
          onLoadedData={handleVideoLoad}
        />
      );
    }

    return (
      <img
        alt="Core Asset Preview"
        src={url}
        onLoad={handleImageLoad}
      />
    );
  };

  return (
    <div className={styles.modal}>
      <BasicModal
        isOpen={isOpen}
        padding={false}
        onClose={onClose}
      >
        <div className={styles.main}>
          <div className={styles.header}>
            <CloseButton onClick={onClose} />
          </div>
          <div className={bodyClasses}>
            <div
              className={styles.image}
              style={calcImageStyle(aspectRatio)}
            >
              { renderAsset() }
            </div>
          </div>
          { canViewCreatives && (
            <div className={styles.footer}>
              <Button
                iconRight="arrowRight"
                label="View Asset Details"
                onClick={() => showAssetDetails()}
              />
            </div>
          ) }
        </div>
      </BasicModal>
    </div>
  );
}

AssetModal.propTypes = propTypes;
AssetModal.defaultProps = defaultProps;

export default AssetModal;
