import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';

const propTypes = {
  value: PropTypes.bool.isRequired,
};

function BoolIcon({ value }) {
  return (
    <Icon
      color={value ? 'green' : 'red'}
      name={value ? 'checkCircle' : 'xCircle'}
    />
  );
}

BoolIcon.propTypes = propTypes;

export default BoolIcon;
