// Helper functions for Mixpanel tracking

/* global MIXPANEL_DEBUG_ENABLED */
/* global MIXPANEL_ENABLED */
/* global MIXPANEL_TOKEN */

import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { retrieveFromLocalStorage } from 'utilities/storage';

function getPageProperties() {
  return {
    pageName: retrieveFromLocalStorage('pageName'),
    productArea: retrieveFromLocalStorage('productArea'),
    source: window.location.pathname,
  };
}

function generateEventName(controller, name) {
  const controllerName = controller.name ?? 'unknown';
  const actionName = controller.action ?? 'unknown';
  const formattedName = name.toLowerCase().replace(/ /g, '_');

  return `${controllerName}:${actionName}:${formattedName}`;
}

export function track(name, extraProperties = {}) {
  const userAttributes = retrieveFromLocalStorage('userAttributes') ?? {};
  const controller = retrieveFromLocalStorage('controller') ?? {};
  const eventName = generateEventName(controller, name);

  const properties = {
    ...userAttributes,
    ...getPageProperties(),
    ...extraProperties,
  };

  if (MIXPANEL_DEBUG_ENABLED) {
    console.info(`mixpanel ${eventName}`, properties); // eslint-disable-line no-console
  }

  if (MIXPANEL_ENABLED) {
    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.identify(properties.userId);
    mixpanel.track(eventName, properties);
  }
}

// pageName is only needed when several pages share one controller method
export function useViewPage(pageName = null, extraProperties = {}) {
  const prefix = pageName ? `${pageName}:` : '';
  useEffect(() => track(`${prefix}view_page`, extraProperties), []);
}
