import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  Divider,
  MoreButton,
} from '@makeably/creativex-design-system';
import SegmentModal from 'components/custom_segments/SegmentModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { setItemElement } from 'utilities/itemElement';
import {
  destroy,
  patch,
  post,
} from 'utilities/requests';
import {
  taxonomyCustomSegmentValuePath,
  taxonomyCustomSegmentValuesPath,
} from 'utilities/routes';
import { dateToString } from 'utilities/string';
import styles from './CustomSegments.module.css';
import { getItemSortBy } from '../../utilities/item';

const propTypes = {
  segmentId: PropTypes.number.isRequired,
  segmentName: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

const headers = [
  {
    key: 'value',
    label: 'Value Name',
  },
  {
    key: 'createdAt',
    label: 'Date Added',
  },
  {
    key: 'actions',
    label: '',
    sortable: false,
  },
];

async function saveOrUpdateValue(ids, value, isUpdate = false) {
  const { segmentId, valueId } = ids;
  const formData = new FormData();
  formData.append('value', value);

  let response;
  if (isUpdate) {
    response = await patch(taxonomyCustomSegmentValuePath(segmentId, valueId), formData);
  } else {
    response = await post(taxonomyCustomSegmentValuesPath(segmentId), formData);
  }

  return response.data;
}

async function deleteCode(valueId, segmentId) {
  const response = await destroy(taxonomyCustomSegmentValuePath(segmentId, valueId));
  return response.data;
}

function getActions(handleEdit, value, handleDelete) {
  return (
    <MoreButton
      menuSize="small"
      options={[{
        label: 'Edit',
        onClick: () => handleEdit(value),
        testId: `edit-${value.value}`,
      },
      {
        label: 'Delete',
        onClick: () => handleDelete(value.id),
        testId: `delete-${value.value}`,
      }]}
    />
  );
}

function getItems(values, handleEdit, handleDelete) {
  return values.map((value) => setItemElement({
    value: { value: value.value },
    createdAt: {
      value: dateToString(value.createdAt),
    },
    actions: { element: getActions(handleEdit, value, handleDelete) },
    id: { value: value.id },
  }));
}

function SegmentValues({
  segmentId,
  segmentName,
  values,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [hasAddedValue, setHasAddedValue] = useState(false);
  const [sort, setSort] = useState({
    key: 'createdAt',
    asc: false,
  });

  const handleDeleteValue = async (valueId) => {
    const response = await deleteCode(valueId, segmentId);
    addToast(response.message, { type: response.success ? 'success' : 'error' });
    window.location.reload();
  };

  const handleEditValue = (value) => {
    setEditValue(value);
    setIsModalOpen(true);
  };

  const handleValueSaveOrUpdate = async (value, isUpdate = false) => {
    const ids = {
      segmentId,
      valueId: isUpdate ? editValue.id : null,
    };
    const response = await saveOrUpdateValue(ids, value, isUpdate);
    addToast(response.message, { type: response.success ? 'success' : 'error' });
    if (!isUpdate) {
      setHasAddedValue(true);
    } else {
      window.location.reload();
    }
  };

  const handleCancel = () => {
    if (hasAddedValue) {
      window.location.reload();
    }
    setIsModalOpen(false);
    setEditValue({});
  };

  const items = useMemo(() => (getItems(
    values,
    handleEditValue,
    handleDeleteValue,
  )), [values]);
  const sortedItems = useMemo(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      return items.slice().sort(byKeyDir);
    }
    return items;
  }, [items, sort]);

  return (
    <div>
      <Card>
        <div className="u-flexColumn u-gap-24">
          <div className="u-flexRow u-justifyBetween u-alignCenter">
            <h4>{ `${segmentName}: Values` }</h4>
            <AddNewButton
              label="New Value"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
          <Divider />
          <ItemsTable
            className={styles.table}
            headers={headers}
            items={sortedItems}
            sort={sort}
            onSortChange={setSort}
          />
          {
            !values.length && (
              <div className="u-textCenter u-marginTop-16">
                <p>No available values.</p>
              </div>
            )
          }
        </div>
      </Card>
      <SegmentModal
        existingValue={editValue.value}
        isOpen={isModalOpen}
        type="value"
        onCancel={handleCancel}
        onSave={handleValueSaveOrUpdate}
      />
    </div>
  );
}

SegmentValues.propTypes = propTypes;

export default SegmentValues;
