import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  ClickableTag,
  Divider,
  Drawer,
  MessageModal,
  MoreButton,
  Table,
} from '@makeably/creativex-design-system';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { track } from 'utilities/mixpanel';
import { destroy } from 'utilities/requests';
import {
  editThirdPartyIntegrationConfigEligibilityRulePath,
  newThirdPartyIntegrationConfigEligibilityRulePath,
  thirdPartyIntegrationConfigEligibilityRulePath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';
import styles from './EligibilityTable.module.css';

const HEADERS = [
  { value: 'Name' },
  { value: 'Date Added' },
  { value: 'Campaign Status' },
  { value: 'Channel' },
  { value: 'Publisher' },
  { value: 'Asset Type' },
  { value: 'Brands' },
  { value: 'Markets' },
];

export const eligibilityProps = {
  brandLookup: PropTypes.objectOf(PropTypes.string).isRequired,
  canManage: PropTypes.bool.isRequired,
  eligibility: PropTypes.arrayOf(
    PropTypes.shape({
      assetTypes: PropTypes.arrayOf(PropTypes.string),
      brands: PropTypes.arrayOf(PropTypes.string),
      campaignStatus: PropTypes.string,
      channelName: PropTypes.string,
      createdAt: PropTypes.string,
      id: PropTypes.number,
      markets: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      publishers: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  marketLookup: PropTypes.objectOf(PropTypes.string).isRequired,
  publisherLookup: PropTypes.objectOf(PropTypes.string).isRequired,
};

const propTypes = {
  id: PropTypes.number.isRequired,
  ...eligibilityProps,
};

function formatAssetType(assetTypes) {
  if (assetTypes.length === 0) {
    return 'All';
  }

  return titleize(assetTypes[0]);
}

function getHeaders(canManage) {
  if (!canManage) return HEADERS;

  return [
    ...HEADERS,
    { value: '' },
  ];
}

function renderEligibilityTable(eligibilityRows, canManage) {
  if (eligibilityRows.length === 0) {
    return (
      <div className={`u-flexRow u-alignCenter u-justifyCenter t-empty ${styles.emptyTable}`}>
        Click the &quot;Add New Eligibility&quot; button to add new eligibility criteria
      </div>
    );
  }

  return (
    <Table
      className={styles.table}
      headers={getHeaders(canManage)}
      rows={eligibilityRows}
    />
  );
}

function EligibilityTable({
  brandLookup,
  canManage,
  eligibility,
  id: configId,
  marketLookup,
  publisherLookup,
}) {
  const [drawerHeader, setDrawerHeader] = useState();
  const [drawerBody, setDrawerBody] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [selectedEligibility, setSelectedEligibility] = useState(null);

  const handleShowPublishers = (ids) => {
    setDrawerHeader(`Publishers (${ids?.length})`);
    setDrawerBody(ids.map((id) => publisherLookup[id]).sort().join('\n'));
  };

  const handleShowBrands = (ids) => {
    setDrawerHeader(`Brands (${ids?.length})`);
    setDrawerBody(ids.map((id) => brandLookup[id]).sort().join('\n'));
  };

  const handleShowMarkets = (ids) => {
    setDrawerHeader(`Markets (${ids?.length})`);
    setDrawerBody(ids.map((id) => marketLookup[id]).sort().join('\n'));
  };

  const formatTag = (ids, handler) => (
    <ClickableTag
      label={ids.length.toString()}
      onClick={() => handler(ids)}
    />
  );

  const eligibilityRows = useMemo(() => eligibility.map(({
    assetTypes,
    brands,
    campaignStatus,
    channelName,
    createdAt,
    id: eligibilityId,
    markets,
    name,
    publishers,
  }) => {
    const brandOptions = brands.length === 0 ? Object.keys(brandLookup) : brands;
    const marketOptions = markets.length === 0 ? Object.keys(marketLookup) : markets;

    const menuOptions = [
      {
        label: 'Edit',
        url: editThirdPartyIntegrationConfigEligibilityRulePath(
          eligibilityId,
          { integration_config_id: configId },
        ),
      },
      {
        label: 'Duplicate',
        url: editThirdPartyIntegrationConfigEligibilityRulePath(
          eligibilityId,
          {
            duplicate: true,
            integration_config_id: configId,
          },
        ),
      },
      {
        label: 'Delete',
        onClick: () => setSelectedEligibility(eligibilityId),
      },
    ];
    const moreButton = (
      <MoreButton
        menuSize="small"
        options={menuOptions}
      />
    );

    const cells = [
      { value: name || '-' },
      { value: createdAt },
      { value: campaignStatus },
      { value: channelName },
      { value: publishers.length === 0 ? 'N/A' : formatTag(publishers, handleShowPublishers) },
      { value: formatAssetType(assetTypes) },
      { value: formatTag(brandOptions, handleShowBrands) },
      { value: formatTag(marketOptions, handleShowMarkets) },
    ];

    if (canManage) cells.push({ value: moreButton });

    return {
      key: eligibilityId.toString(),
      cells,
    };
  }), [eligibility]);

  const handleDelete = async () => {
    setIsBusy(true);
    track('delete_eligibility_criteria', {
      eligibilityId: selectedEligibility,
      integrationConfigId: configId,
    });

    const response = await destroy(thirdPartyIntegrationConfigEligibilityRulePath(
      selectedEligibility,
      { integration_config_id: configId },
    ));

    if (!response.isError) {
      addToast('Eligibility criteria deleted.');
      window.location.reload();
    } else {
      addErrorToast('Something went wrong, please try again.');
      setIsBusy(false);
    }
  };

  const handleDrawerClose = () => {
    setDrawerHeader(undefined);
    setDrawerBody(undefined);
  };

  const addNewUrl = newThirdPartyIntegrationConfigEligibilityRulePath({
    integration_config_id: configId,
  });

  return (
    <>
      <div className="u-flexRow u-alignCenter u-justifyBetween u-marginBottom-16">
        <h5>Automatic Score: Eligibility Criteria</h5>
        { canManage && (
          <AddNewButton
            label="Add New Criteria"
            url={addNewUrl}
          />
        ) }
      </div>
      { renderEligibilityTable(eligibilityRows, canManage) }
      <MessageModal
        actionButtonActive={isBusy}
        actionButtonLabel="Delete"
        actionButtonVariant="destructive"
        isOpen={selectedEligibility !== null}
        title="Delete Eligibility Criteria"
        onActionButtonClick={handleDelete}
        onClose={() => setSelectedEligibility(null)}
      >
        Ads that meet this criteria will no longer be sent to this partner
        for scoring. This change will take place immediately.
      </MessageModal>
      <Drawer
        isOpen={drawerHeader !== undefined}
        onClose={handleDrawerClose}
      >
        <div className={`u-flexColumn ${styles.drawer}`}>
          <h5 className={styles.padding}>{ drawerHeader }</h5>
          <Divider />
          <div className={`t-body-1 u-scrollShadowBottom ${styles.content}`}>{ drawerBody }</div>
          <div className={styles.drawerFooter}>
            <Button
              label="Close"
              onClick={handleDrawerClose}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
}

EligibilityTable.propTypes = propTypes;

export default EligibilityTable;
