import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Button,
  Card,
  Checkbox,
  Divider,
  Dropdown,
  TextArea,
  TextInput,
} from '@makeably/creativex-design-system';
import {
  applicabilityLabels,
  globalOptions,
  roleOptions,
} from 'components/internal/guidelines/GuidelineDisplay';
import { optionArrayProps } from 'components/internal/shared';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { findObjectByValue } from 'utilities/array';
import { isDefined } from 'utilities/item';
import { isInteger } from 'utilities/number';
import {
  patch,
  post,
} from 'utilities/requests';
import {
  internalGuidelinePath,
  internalGuidelinesPath,
} from 'utilities/routes';
import {
  toSnakeCase,
  isString,
} from 'utilities/string';
import {
  getParams,
  redirectWithParam,
} from 'utilities/url';

const guidelineProps = PropTypes.shape({
  applicability: PropTypes.string,
  brandCue: PropTypes.bool,
  branded: PropTypes.bool,
  company: PropTypes.string,
  description: PropTypes.string,
  evaluationOrder: PropTypes.number,
  global: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  organizationOrder: PropTypes.number,
  samplingRate: PropTypes.number,
  standard: PropTypes.bool,
});

const propTypes = {
  applicabilityValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  guideline: guidelineProps.isRequired,
  typeOptions: optionArrayProps.isRequired,
  selectedTypeValue: PropTypes.string,
  subscriptionCount: PropTypes.number,
};

const defaultProps = {
  selectedTypeValue: undefined,
  subscriptionCount: 0,
};

function getTitle(guideline) {
  if (guideline.id) {
    return `Edit Guideline (${guideline.id})`;
  }

  return 'New Guideline';
}

export function findRoleOption(guideline) {
  return roleOptions.find(({ brandCue, standard }) => (
    brandCue === guideline.brandCue && standard === guideline.standard
  ));
}

function asInt(str, fallback) {
  const value = parseInt(str);

  return Number.isNaN(value) ? fallback : value;
}

function isValid(guideline) {
  const {
    applicability,
    brandCue,
    branded,
    description,
    evaluationOrder,
    global,
    guidelineType,
    name,
    organizationOrder,
    samplingRate,
    standard,
    paid,
  } = guideline;

  return (
    isDefined(guidelineType)
      && isString(name, 1)
      && isString(description, 1)
      && isDefined(standard)
      && isDefined(brandCue)
      && isDefined(applicability)
      && isDefined(global)
      && isDefined(paid)
      && isDefined(branded)
      && isInteger(organizationOrder, 1)
      && isInteger(evaluationOrder, 1)
      && isInteger(samplingRate, 0, 100)
  );
}

const formKeys = [
  'brandCue',
  'branded',
  'description',
  'evaluationOrder',
  'global',
  'guidelineType',
  'name',
  'organizationOrder',
  'paid',
  'samplingRate',
  'standard',
];

function getFormKey(key) {
  switch (key) {
    case 'guidelineType':
      return 'type';
    default:
      return toSnakeCase(key);
  }
}

async function saveGuideline(guideline) {
  const formData = formKeys.reduce((data, key) => {
    data.append(getFormKey(key), guideline[key]);
    return data;
  }, new FormData());

  if (guideline.id) {
    return patch(internalGuidelinePath(guideline.id), formData);
  }
  return post(internalGuidelinesPath(), formData);
}

function GuidelineForm({
  applicabilityValues,
  guideline: initialGuideline,
  selectedTypeValue,
  subscriptionCount,
  typeOptions,
}) {
  const params = getParams(window);
  const [selectedType, setSelectedType] = useState(
    findObjectByValue(typeOptions, selectedTypeValue),
  );
  const [guideline, setGuideline] = useState(initialGuideline);
  const applicabilityOptions = applicabilityValues.map((value) => ({
    label: applicabilityLabels[value],
    value,
  }));
  const [isSaving, setIsSaving] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const isSubscribed = subscriptionCount > 0;
  const isDisabled = !selectedTypeValue;

  const handleTypeChange = (option) => {
    setSelectedType(option);
    redirectWithParam('type', option.value, params, window);
  };

  const handleChange = (key, value) => {
    setGuideline((last) => ({
      ...last,
      [key]: value,
    }));
  };

  const handleRoleChange = (option) => {
    handleChange('brandCue', option.brandCue);
    handleChange('standard', option.standard);
  };

  const handleClose = (id) => {
    const url = id ? internalGuidelinePath(id) : internalGuidelinesPath();

    window.location.replace(url);
  };

  const handleSave = async () => {
    setIsSaving(true);

    const { data, isError } = await saveGuideline(guideline);

    if (isError) {
      addErrorToast('Could not save guideline. Please try again later');
      setIsSaving(false);
    } else {
      addToast('Saved guideline');
      handleClose(data.id);
    }
  };

  const handleConfirmAndSave = () => {
    if (guideline.id && isSubscribed) {
      setConfirmSave(true);
    } else {
      handleSave();
    }
  };

  return (
    <>
      <Card className="u-flexColumn u-gap-24">
        <div className="u-flexColumn u-gap-16">
          <h4>{ getTitle(guideline) }</h4>
          <Dropdown
            disabled={Boolean(initialGuideline.id)}
            label="Guideline Type"
            menuProps={{ size: 'large' }}
            options={typeOptions}
            selected={selectedType}
            size="large"
            onChange={handleTypeChange}
          />
        </div>
        <Divider />
        <div className="u-flexColumn u-gap-16">
          <TextInput
            disabled={isDisabled}
            label="Name"
            placeholder="Name"
            size="large"
            value={guideline.name ?? ''}
            onChange={(value) => handleChange('name', value)}
          />
          <TextArea
            disabled={isDisabled}
            label="Description"
            placeholder="Description"
            size="large"
            value={guideline.description ?? ''}
            onChange={(value) => handleChange('description', value)}
          />
          <div className="u-flexRow u-gap-16">
            <Dropdown
              disabled={isDisabled || isSubscribed}
              label="Role"
              menuProps={{ size: 'medium' }}
              options={roleOptions}
              selected={findRoleOption(guideline)}
              size="medium"
              onChange={handleRoleChange}
            />
            <Dropdown
              disabled={isDisabled}
              label="Applicability"
              menuProps={{ size: 'medium' }}
              options={applicabilityOptions}
              selected={applicabilityOptions.find(({ value }) => value === guideline.applicability)}
              size="medium"
              onChange={({ value }) => handleChange('applicability', value)}
            />
            <Dropdown
              disabled={isDisabled || isSubscribed}
              label="Global / Local"
              menuProps={{ size: 'medium' }}
              options={globalOptions}
              selected={globalOptions.find(({ global }) => global === guideline.global)}
              size="medium"
              onChange={({ global }) => handleChange('global', global)}
            />
          </div>
          <div className="u-flexRow u-gap-16">
            <TextInput
              disabled={isDisabled}
              label="Position"
              min={1}
              size="medium"
              type="number"
              value={guideline.organizationOrder ?? 1}
              onChange={(value) => handleChange('organizationOrder', asInt(value, 1))}
            />
            <TextInput
              disabled={isDisabled || isSubscribed}
              label="Evaluation Position"
              min={1}
              size="medium"
              type="number"
              value={guideline.evaluationOrder ?? 1}
              onChange={(value) => handleChange('evaluationOrder', asInt(value, 1))}
            />
            <TextInput
              disabled={isDisabled}
              label="Sampling Rate (%)"
              max={100}
              min={0}
              size="medium"
              type="number"
              value={guideline.samplingRate ?? 0}
              onChange={(value) => handleChange('samplingRate', asInt(value, 0))}
            />
          </div>
          <div className="u-flexRow u-gap-16">
            <Checkbox
              checked={guideline.paid}
              disabled={isDisabled || isSubscribed}
              label="Include in Usage Reporting"
              onChange={(e) => handleChange('paid', e.target.checked)}
            />
            <Checkbox
              checked={guideline.branded}
              disabled={isDisabled || isSubscribed}
              label="Branded"
              onChange={(e) => handleChange('branded', e.target.checked)}
            />
          </div>
        </div>
        <div className="u-flexRow u-justifyEnd u-gap-8">
          <Button
            label="Cancel"
            variant="secondary"
            onClick={() => handleClose(guideline.id)}
          />
          <ActionButton
            active={isSaving}
            disabled={!isValid(guideline)}
            label="Save"
            onClick={handleConfirmAndSave}
          />
        </div>
      </Card>
      <ConfirmationModal
        isOpen={confirmSave}
        message={`Saving will update the ${subscriptionCount} guideline(s) that are subscribed to this template`}
        title="Confirm Template Save"
        type="warning"
        onClose={() => setConfirmSave(false)}
        onConfirm={handleSave}
      />
    </>
  );
}

GuidelineForm.defaultProps = defaultProps;
GuidelineForm.propTypes = propTypes;

export default GuidelineForm;
