import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  MoreButton,
  Tag,
} from '@makeably/creativex-design-system';
import SearchableTable from 'components/organisms/SearchableTable';
import ConfirmAdAccountActionModal from 'components/settings/ConfirmAdAccountActionModal';
import {
  destroy,
  post,
} from 'utilities/requests';
import {
  accountSetupFacebookAdsPath,
  editSettingsLinkedPlatformAccountPath,
  relinkInstructionsAccountSetupDV360AdPath,
  relinkInstructionsAccountSetupInstagramPostPath,
  relinkInstructionsAccountSetupSnapchatAdPath,
  requestRelinkAccountSetupAdwordPath,
  updateStateSettingsLinkedPlatformAccountPath,
  relinkAccountSetupPinterestPath,
  settingsLinkedPlatformAccountPath,
} from 'utilities/routes';
import { saveToLocalStorage } from 'utilities/storage';

const accountProps = PropTypes.shape({
  accountClass: PropTypes.string,
  accountId: PropTypes.string,
  brand: PropTypes.string,
  channel: PropTypes.string,
  connection: PropTypes.oneOf(['oauth', 'partner']),
  dateLinked: PropTypes.string,
  market: PropTypes.string,
  orgName: PropTypes.string,
  owner: PropTypes.string,
  partner: PropTypes.string,
  recordClass: PropTypes.string,
  recordId: PropTypes.number,
  scheduledForDeletion: PropTypes.bool,
  status: PropTypes.string,
});
export const propTypes = {
  accountsByStatus: PropTypes.objectOf(PropTypes.arrayOf(accountProps)).isRequired,
  afterConnection: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  dv360RelinkUrl: PropTypes.string.isRequired,
  fbAdAccountPageCountLookup: PropTypes.objectOf(PropTypes.number).isRequired,
};

const filterKeys = ['brand', 'market', 'platform', 'partner', 'owner'];
const tabs = ['lapsed', 'active', 'inactive', 'all'];

export const pageStorageKey = 'adAccountFilter';

const OAUTH_CONNECTION_TYPE = 'oauth';

const menuOptionLabels = {
  activate: 'Activate',
  deactivate: 'Deactivate',
  delete: 'Delete',
  relink: 'Activate',
};

function getStatusDisplayName(value) {
  const statusLabels = {
    active: 'Active',
    all: 'All',
    inactive: 'Inactive',
    lapsed: 'Needs Reconnecting',
  };

  return statusLabels[value];
}

function getHeaders(key) {
  const allHeaders = [
    {
      label: 'Platform',
      key: 'platform',
    },
    {
      label: 'Account ID',
      key: 'accountId',
    },
    {
      label: 'Brand',
      key: 'brand',
    },
    {
      label: 'Market',
      key: 'market',
    },
    {
      label: 'Associated Brand Pages',
      key: 'associatedCount',
      tooltip: 'The number of Facebook Brand Pages associated with each account.',
    },
    {
      label: 'Partner',
      key: 'partner',
    },
    {
      label: 'Owner',
      key: 'owner',
    },
    {
      label: 'Date Linked',
      key: 'dateLinked',
    },
    {
      label: 'Status',
      key: 'status',
      filter: 'all',
    },
    {
      label: '',
      key: 'moreButton',
      sortable: false,
    },
  ];

  return allHeaders.filter((header) => (header.filter ? header.filter === key : true));
}

function getAdAccountRelinkUrl(item, dv360RelinkUrl) {
  const isOAuth = item.connection === OAUTH_CONNECTION_TYPE;
  const { recordId } = item;

  switch (item.accountClass) {
    case 'AdWordsAccount':
      return requestRelinkAccountSetupAdwordPath(recordId);
    case 'SnapchatAccount':
      return relinkInstructionsAccountSetupSnapchatAdPath(recordId);
    case 'DV360Account':
      return isOAuth ? relinkInstructionsAccountSetupDV360AdPath(recordId) : dv360RelinkUrl;
    case 'PinterestAccount':
      return relinkAccountSetupPinterestPath(recordId);
    default:
      return null;
  }
}

function getRelinkUrl(item, dv360RelinkUrl) {
  const isOAuth = item.connection === OAUTH_CONNECTION_TYPE;

  switch (item.recordClass) {
    case 'InstagramBusinessAccount':
      return relinkInstructionsAccountSetupInstagramPostPath(item.recordId);
    case 'FacebookAdAccount':
      return isOAuth ? '/auth/meta_ads_reconnection' : accountSetupFacebookAdsPath();
    case 'AdAccount':
      return getAdAccountRelinkUrl(item, dv360RelinkUrl);
    default:
      return null;
  }
}

function relinkAdAccount(adAccount, dv360RelinkUrl) {
  const relinkUrl = getRelinkUrl(adAccount, dv360RelinkUrl);
  if (relinkUrl) {
    window.location.href = relinkUrl;
  }
}

async function updateAdAccountState(adAccount, newState) {
  const response = await post(updateStateSettingsLinkedPlatformAccountPath(adAccount.recordId, {
    account_class: adAccount.recordClass,
    facebook_ad_account_id: adAccount.facebookAdAccountId,
    state: newState,
  }));
  if (response.data && response.data.redirect_to) {
    window.location.href = response.data.redirect_to;
  }
}

async function deleteAdAccount(adAccount) {
  const response = await destroy(settingsLinkedPlatformAccountPath(adAccount.recordId, {
    account_class: adAccount.recordClass,
  }));
  if (response.data && response.data.redirect_to) {
    window.location.href = response.data.redirect_to;
  }
}

function generateItemAssociatedCount({ recordClass, recordId }, fbAdAccountPageCountLookup) {
  if (recordClass === 'FacebookAdAccount') {
    const count = fbAdAccountPageCountLookup[recordId] || 0;
    return {
      text: count.toString(),
      value: count,
    };
  }
  return {
    text: 'N/A',
    value: -1,
  };
}

function generateAccountUrl({ recordClass, recordId }) {
  const params = { account_class: recordClass };
  return editSettingsLinkedPlatformAccountPath(recordId, params);
}

function generateAccountId(item) {
  return {
    display: { url: generateAccountUrl(item) },
    value: item.accountId,
  };
}

function generateDeletingTag() {
  return {
    element: <Tag color="red" label="Deleting..." />,
    value: '',
  };
}

function generateMenuOptions(item, actions, onOptionClick) {
  if (item.scheduledForDeletion) {
    return generateDeletingTag();
  }

  const menuOptions = actions.map((action) => ({
    label: menuOptionLabels[action],
    onClick: () => onOptionClick(action, item),
  }));

  return {
    element: (
      <MoreButton
        menuSize="small"
        options={menuOptions}
      />
    ),
    value: '',
  };
}

function generateGroupedTableItems(
  accountsByStatus,
  actionsByAccountStatus,
  fbAdAccountPageCountLookup,
  onOptionClick,
) {
  const statuses = Object.keys(accountsByStatus);

  return statuses.reduce((items, status) => {
    const itemsByStatus = { ...items };
    itemsByStatus[status] = accountsByStatus[status].map((item) => ({
      accountId: generateAccountId(item),
      associatedCount: generateItemAssociatedCount(item, fbAdAccountPageCountLookup),
      brand: { value: item.brand },
      dateLinked: {
        value: item.dateLinked,
        format: 'date',
      },
      id: { value: `${item.accountClass}:${item.recordId}` },
      market: { value: item.market },
      moreButton: generateMenuOptions(item, actionsByAccountStatus[item.status], onOptionClick),
      owner: { value: item.owner },
      partner: { value: item.partner },
      platform: { value: item.channel },
      status: {
        text: getStatusDisplayName(item.status),
        value: item.status,
      },
    }));
    return itemsByStatus;
  }, {});
}

function AdAccounts({
  accountsByStatus,
  afterConnection,
  canDelete,
  dv360RelinkUrl,
  fbAdAccountPageCountLookup,
}) {
  const [showModal, setShowModal] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [focusedAdAccount, setFocusedAdAccount] = useState(null);

  const actionsByStatus = {
    active: ['deactivate'],
    inactive: canDelete ? ['activate', 'delete'] : ['activate'],
    lapsed: ['relink', 'deactivate'],
  };

  const tabDetails = tabs.map((key) => ({
    key,
    name: getStatusDisplayName(key),
    icon: (key === 'lapsed') ? <Icon color="red" name="exclamationCircle" /> : undefined,
  }));

  if (afterConnection) {
    const defaultState = {
      sort: {
        asc: false,
        key: 'dateLinked',
      },
      tab: 'active',
    };

    saveToLocalStorage(defaultState, pageStorageKey);
  }

  const performAction = async (action, adAccount) => {
    switch (action) {
      case 'activate':
        await updateAdAccountState(adAccount, 'active');
        break;
      case 'deactivate':
        await updateAdAccountState(adAccount, 'inactive');
        break;
      case 'delete':
        await deleteAdAccount(adAccount);
        break;
      default:
        break;
    }
    setShowModal(false);
  };

  const onAdAccountActionIntent = (action, adAccount) => {
    if (action === 'relink') {
      relinkAdAccount(adAccount, dv360RelinkUrl);
      return;
    }

    setActionToConfirm(action);
    setFocusedAdAccount(adAccount);
    setShowModal(true);
  };

  const formattedAccountsByStatus = generateGroupedTableItems(
    accountsByStatus,
    actionsByStatus,
    fbAdAccountPageCountLookup,
    onAdAccountActionIntent,
  );

  return (
    <>
      <SearchableTable
        csvFileName="accounts"
        filterKeys={filterKeys}
        getHeaders={getHeaders}
        groupedItems={formattedAccountsByStatus}
        pageStorageKey={pageStorageKey}
        tabDetails={tabDetails}
      />
      { showModal && (
        <ConfirmAdAccountActionModal
          action={actionToConfirm}
          adAccount={focusedAdAccount}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={performAction}
        />
      ) }
    </>
  );
}

AdAccounts.propTypes = propTypes;

export default AdAccounts;
