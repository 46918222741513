import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CloseButton,
  Icon,
  MaxWidthText,
} from '@makeably/creativex-design-system';
import styles from './ReviewGuideAsset.module.css';

export const assetProps = PropTypes.shape({
  creativeType: PropTypes.oneOf(['image', 'video']).isRequired,
  url: PropTypes.string.isRequired,
});

const propTypes = {
  asset: assetProps.isRequired,
  showLarge: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

const defaultProps = {
  onClick: undefined,
  onRemove: undefined,
  showLarge: false,
};

function parseFilename(url) {
  const index = url.lastIndexOf('_');

  if (index === -1) return url;

  return decodeURIComponent(url.slice(index + 1));
}

function renderContent({ creativeType, url }, showLarge) {
  if (creativeType === 'video') {
    return (
      <>
        <video
          alt="Review Guide Asset"
          autoPlay={showLarge}
          controls={showLarge}
          src={url}
        />
        { !showLarge && (
          <div className={styles.play}>
            <Icon color="white" name="play" />
          </div>
        ) }
      </>
    );
  }

  return (
    <img
      alt="Review Guide Asset"
      src={url}
    />
  );
}

function renderRemove(onRemove) {
  if (!onRemove) return null;

  return (
    <div className={styles.remove}>
      <CloseButton onClick={onRemove} />
    </div>
  );
}

function ReviewGuideAsset({
  asset,
  showLarge,
  onClick,
  onRemove,
}) {
  const fullClasses = classNames({
    [styles.small]: !showLarge,
    [styles.large]: showLarge,
  });
  const assetClasses = classNames(
    styles.asset,
    {
      [styles.clickable]: onClick,
      [styles.assetSmall]: !showLarge,
    },
  );
  const filename = parseFilename(asset.url);

  return (
    <div className={fullClasses}>
      <div className={styles.wrap}>
        <button
          key={asset.url}
          className={assetClasses}
          type="button"
          onClick={onClick}
        >
          { renderContent(asset, showLarge) }
        </button>
        { renderRemove(onRemove) }
      </div>
      <h6 className={styles.name}>
        <MaxWidthText text={filename} />
      </h6>
    </div>
  );
}

ReviewGuideAsset.propTypes = propTypes;
ReviewGuideAsset.defaultProps = defaultProps;

export default ReviewGuideAsset;
