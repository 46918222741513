import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Dropdown,
  Pagination,
  Search,
  Spinner,
} from '@makeably/creativex-design-system';
import CampaignCard, { campaignProps } from 'components/custom_segments/CampaignCard';
import CampaignModal from 'components/custom_segments/CampaignModal';
import { getValuesPerCustomDimension } from 'components/custom_segments/shared';
import AssetModal from 'components/molecules/AssetModal';
import { addToast } from 'components/organisms/Toasts';
import { getUniques } from 'utilities/array';
import { toSnakeCaseKeys } from 'utilities/object';
import {
  addFormDataArray,
  post,
} from 'utilities/requests';
import { mapDimensionsTaxonomyCustomUnparsableCampaignPath } from 'utilities/routes';
import {
  retrieveFromLocalStorageAndRemove,
  saveToLocalStorage,
} from 'utilities/storage';
import styles from './UnparsableCampaigns.module.css';

const CAMPAIGNS_PER_PAGE = 20;

export const dimensionProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueId: PropTypes.number.isRequired,
});

const propTypes = {
  campaigns: PropTypes.arrayOf(campaignProps).isRequired,
  customDimensionsWithValues: PropTypes.arrayOf(dimensionProps).isRequired,
};

async function submitMapping(campaignId, channel, mappedDimensions) {
  const dimensionsList = Object.values(mappedDimensions).map(
    (dimension) => toSnakeCaseKeys(dimension),
  );

  const formData = new FormData();
  formData.append('channel', channel);
  addFormDataArray(formData, 'dimensions', dimensionsList);

  const response = await post(
    mapDimensionsTaxonomyCustomUnparsableCampaignPath(campaignId),
    formData,
  );
  return response.data;
}

function getOptions(campaigns) {
  const optionSets = campaigns.reduce((acc, campaign) => {
    const { channelLabel, accountId } = campaign;

    acc.channels.add(channelLabel);
    acc.accountIds.add(accountId);

    return acc;
  }, {
    channels: new Set(),
    accountIds: new Set(),
  });

  return {
    channelOptions: Array.from(optionSets.channels).sort().map((channel) => ({
      label: channel,
      value: channel,
    })),
    accountIdOptions: Array.from(optionSets.accountIds).sort().map((accountId) => ({
      label: accountId,
      value: accountId,
    })),
  };
}

function getStatusOptions(dimensions) {
  const statusOptions = getUniques(dimensions.map((dimension) => dimension.name)).sort();

  return statusOptions.map((status) => ({
    label: `Missing ${status}`,
    value: status,
  }));
}

function dimensionMissingValue(customDimensions, value) {
  const dimension = customDimensions.find((cd) => cd.split('::')[0] === value);
  return dimension?.split('::')[1] === '';
}

function sortCampaigns(a, b, sortFilter) {
  if (sortFilter.value === 'spend') {
    return b.spend - a.spend;
  }
  return new Date(b.spendDate) - new Date(a.spendDate);
}

function UnparsableCampaigns({
  customDimensionsWithValues,
  campaigns,
}) {
  const [channelFilter, setChannelFilter] = useState(retrieveFromLocalStorageAndRemove('unparsableCampaignsChannelFilter'));
  const [accountIdFilter, setAccountIdFilter] = useState(retrieveFromLocalStorageAndRemove('unparsableCampaignsAccountIdFilter'));
  const [statusFilter, setStatusFilter] = useState(retrieveFromLocalStorageAndRemove('unparsableCampaignsStatusFilter'));
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(retrieveFromLocalStorageAndRemove('unparsableCampaignsPage') || 1);
  const [assetModalShown, setAssetModalShown] = useState(false);
  const [assetUrl, setAssetUrl] = useState('');
  const [assetVideoSource, setAssetVideoSource] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sortFilter, setSortFilter] = useState({
    label: 'Spend',
    value: 'spend',
  });

  const { channelOptions, accountIdOptions } = useMemo(() => getOptions(campaigns), [campaigns]);
  const statusOptions = useMemo(
    () => getStatusOptions(customDimensionsWithValues), [customDimensionsWithValues],
  );
  const valuesPerCustomDimension = useMemo(
    () => getValuesPerCustomDimension(customDimensionsWithValues), [customDimensionsWithValues],
  );

  const filteredCampaigns = useMemo(() => campaigns.filter(({
    channelLabel,
    accountId,
    name,
    customDimensions,
  }) => {
    if (channelFilter && channelLabel !== channelFilter.value) return false;
    if (accountIdFilter && accountId !== accountIdFilter.value) return false;
    if (search) {
      const term = search.toLowerCase();
      return (
        accountId.toLowerCase().includes(term) || (name && name.toLowerCase().includes(term))
      );
    }
    if (statusFilter) {
      return dimensionMissingValue(customDimensions, statusFilter.value);
    }
    return true;
  }).sort((a, b) => sortCampaigns(a, b, sortFilter)),
  [campaigns, channelFilter, accountIdFilter, statusFilter, search, sortFilter]);

  const paginatedCampaignsWithSpend = useMemo(() => filteredCampaigns.slice(
    (page - 1) * CAMPAIGNS_PER_PAGE, page * CAMPAIGNS_PER_PAGE,
  ), [filteredCampaigns, page]);

  const hasFiltersOrSearch = channelFilter || accountIdFilter || statusFilter || search;
  const mappingModalOpen = Object.keys(selectedCampaign).length > 0;
  const showPagination = paginatedCampaignsWithSpend.length > 0
    && filteredCampaigns.length > CAMPAIGNS_PER_PAGE;

  const handleClearFilters = () => {
    setChannelFilter(null);
    setAccountIdFilter(null);
    setStatusFilter(null);
    setSearch('');
  };

  const handleSetFilter = (filterSetter, value) => {
    filterSetter(value);
    setPage(1);
  };

  const handleAssetClick = (url, videoSource) => {
    setAssetUrl(url);
    setAssetVideoSource(videoSource);
    setAssetModalShown(true);
  };

  const handleMapSegments = (campaign) => {
    setSelectedCampaign(campaign);
  };

  const handleCloseMappingModal = () => {
    setSelectedCampaign({});
  };

  const handleSubmitMapping = async (mappedDimensions) => {
    const { id, channel } = selectedCampaign;
    setIsLoading(true);
    setSelectedCampaign({});
    const response = await submitMapping(id, channel, mappedDimensions);

    addToast(response.message, { type: response.success ? 'success' : 'error' });
    saveToLocalStorage(page, 'unparsableCampaignsPage');
    saveToLocalStorage(channelFilter, 'unparsableCampaignsChannelFilter');
    saveToLocalStorage(accountIdFilter, 'unparsableCampaignsAccountIdFilter');
    saveToLocalStorage(statusFilter, 'unparsableCampaignsStatusFilter');
    window.location.reload();
  };

  const emptyState = (
    <div>
      <Divider />
      <div className="u-flexRow u-justifyCenter u-marginTop-16 t-empty">
        Your search query produced no results
      </div>
    </div>
  );

  return (
    <>
      <div className="u-flexColumn u-gap-24">
        <div className="u-flexRow u-spaceBetween">
          <div className="u-flexRow u-gap-16 u-alignEnd">
            <Dropdown
              disabled={channelOptions.length === 0}
              label="Channel"
              menuProps={{ size: 'medium' }}
              options={channelOptions}
              selected={channelFilter}
              size="small"
              onChange={(value) => handleSetFilter(setChannelFilter, value)}
            />
            <Dropdown
              disabled={accountIdOptions.length === 0}
              label="Ad Account ID"
              menuProps={{ size: 'large' }}
              options={accountIdOptions}
              selected={accountIdFilter}
              size="small"
              onChange={(value) => handleSetFilter(setAccountIdFilter, value)}
            />
            <Dropdown
              disabled={statusOptions.length === 0}
              label="Parsing Status"
              menuProps={{ size: 'large' }}
              options={statusOptions}
              selected={statusFilter}
              size="small"
              onChange={(value) => handleSetFilter(setStatusFilter, value)}
            />
            <Search
              size="small"
              value={search}
              onChange={(value) => handleSetFilter(setSearch, value)}
            />
            { hasFiltersOrSearch && (
            <Button
              label="Clear"
              variant="tertiary"
              onClick={handleClearFilters}
            />
            ) }
          </div>
          <Dropdown
            label="Sort By"
            options={[
              {
                label: 'Spend',
                value: 'spend',
              },
              {
                label: 'Date',
                value: 'spendDate',
              },
            ]}
            selected={sortFilter}
            size="small"
            onChange={setSortFilter}
          />
        </div>
        { paginatedCampaignsWithSpend.length === 0 && emptyState }
        { paginatedCampaignsWithSpend.map((campaign) => (
          <CampaignCard
            key={`${campaign.id}-${campaign.channel}`}
            campaign={campaign}
            onAssetClick={handleAssetClick}
            onMapSegments={handleMapSegments}
          />
        )) }
        { showPagination && (
          <div className="u-flexRow u-justifyCenter">
            <Pagination
              currentPage={page}
              perPage={CAMPAIGNS_PER_PAGE}
              total={filteredCampaigns.length}
              onPageChange={setPage}
            />
          </div>
        ) }
      </div>
      <AssetModal
        isOpen={assetModalShown}
        url={assetUrl}
        videoSource={assetVideoSource}
        onClose={() => setAssetModalShown(false)}
      />
      <CampaignModal
        campaignCustomDimensions={selectedCampaign.customDimensions}
        campaignName={selectedCampaign.name}
        isLoading={isLoading}
        isOpen={mappingModalOpen}
        valuesPerCustomDimension={valuesPerCustomDimension}
        onClose={handleCloseMappingModal}
        onSubmit={handleSubmitMapping}
      />

      { isLoading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) }
    </>
  );
}

UnparsableCampaigns.propTypes = propTypes;

export default UnparsableCampaigns;
