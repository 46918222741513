import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Logo,
  MessageModal,
  Tag,
} from '@makeably/creativex-design-system';
import ButtonCard from 'components/atoms/ButtonCard';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import { post } from 'utilities/requests';
import {
  activateThirdPartyIntegrationPath,
  thirdPartyIntegrationConfigPath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';
import styles from './ThirdPartyConnections.module.css';

const propTypes = {
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      configId: PropTypes.number,
      id: PropTypes.number,
      logoUrl: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    }),
  ).isRequired,
};

function renderLogo(logoUrl) {
  if (logoUrl) {
    return <img alt="Platform Logo" src={logoUrl} />;
  }

  return <Logo name="DEFAULT" />;
}

function renderCardDecorator(state) {
  if (state === 'active' || state === 'pending') {
    return <Tag color={state === 'active' ? 'green' : 'orange'} label={titleize(state)} />;
  }

  return (
    <div className={styles.icon}>
      <Icon name="chevronRight" />
    </div>
  );
}

function renderPlatformCard(platform, onClick) {
  const {
    configId,
    id,
    logoUrl,
    name,
    state,
  } = platform;

  const configurable = state === 'active' || state === 'pending';
  const onCardClick = () => {
    if (!configurable) onClick(platform);
  };

  return (
    <React.Fragment key={id}>
      <ButtonCard
        className={styles.platformCard}
        url={configurable && thirdPartyIntegrationConfigPath(configId)}
        onClick={onCardClick}
      >
        <div className="u-flexRow u-gap-8 u-alignCenter">
          <div className={styles.logo}>
            { renderLogo(logoUrl) }
          </div>
          <h4 className={styles.name}>{ name }</h4>
          <div className={styles.rightAlign}>
            { renderCardDecorator(state) }
          </div>
        </div>
      </ButtonCard>
    </React.Fragment>
  );
}

function ThirdPartyConnections({ platforms }) {
  useViewPage();

  const [configId, setConfigId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [showContinueModal, setShowContinueModal] = useState(false);

  const showActivateModal = selectedPlatform !== undefined;

  const onActivate = async () => {
    setIsLoading(true);
    track('third_party_integration_activate', { integrationId: selectedPlatform?.id });

    const { id } = selectedPlatform;

    const response = await post(activateThirdPartyIntegrationPath(id));

    if (response.isError) {
      addErrorToast('Something went wrong, please try again', { size: 'large' });
    } else {
      const { configId: newConfigId } = response.data;
      setConfigId(newConfigId);

      addToast('Your activation request has been sent!', { size: 'large' });
    }

    setSelectedPlatform(undefined);
    setShowContinueModal(true);
    setIsLoading(false);
  };

  const onContinue = () => {
    window.location.href = thirdPartyIntegrationConfigPath(configId);
  };

  return (
    <>
      <div className="t-subtitle">Choose the partner to link with CreativeX.</div>
      <div className={styles.cards}>
        { platforms.map((platform) => renderPlatformCard(platform, setSelectedPlatform)) }
      </div>
      <MessageModal
        actionButtonActive={isLoading}
        actionButtonLabel="Activate"
        isOpen={showActivateModal}
        title="Activate Third-Party Partner (1/2)"
        onActionButtonClick={onActivate}
        onClose={() => setSelectedPlatform(undefined)}
      >
        <div className="t-body-2">
          When you click &quot;Activate&quot;, we&apos;ll send a request to the selected partner
          to confirm the connection to CreativeX. You&apos;ll receive an email notification when
          the partner accepts or declines the request.
        </div>
      </MessageModal>
      <MessageModal
        closeButtonLabel=""
        isOpen={showContinueModal}
        title="Automatic Score Requests (2/2)"
        onActionButtonClick={onContinue}
        onClose={() => setShowContinueModal(false)}
      >
        <div className="t-body-2">
          Next, click &quot;Continue&quot; to set up the criteria for which ads will be
          automatically sent to this partner for third-party scoring. This configuration will
          become active once the partner accepts the connection request.
        </div>
      </MessageModal>
    </>
  );
}

ThirdPartyConnections.propTypes = propTypes;

export default ThirdPartyConnections;
