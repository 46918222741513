import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Button,
  Divider,
} from '@makeably/creativex-design-system';
import StickyTop from 'components/atoms/StickyTop';
import ActivationCard, { activationProps } from 'components/creative_lifecycle/ActivationCard';
import CoreAssetDetails, { coreAssetProps } from 'components/creative_lifecycle/CoreAssetDetails';
import { addToast } from 'components/organisms/Toasts';
import { toggleProperty } from 'utilities/object';
import {
  addFormDataArray,
  post,
} from 'utilities/requests';
import { matchCreativeLifecycleCoreAssetsPath } from 'utilities/routes';
import styles from './AddActivations.module.css';

const propTypes = {
  activationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.node.isRequired,
    }),
  ).isRequired,
  coreAsset: PropTypes.shape(coreAssetProps).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape(activationProps),
  ).isRequired,
  showAssetModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

async function postMatch(assetComparisonIds) {
  try {
    const url = matchCreativeLifecycleCoreAssetsPath();

    const formData = new FormData();
    addFormDataArray(formData, 'asset_comparison_ids', assetComparisonIds);

    const response = await post(url, formData);
    const result = response.data;

    if (response.isError) {
      return assetComparisonIds.length;
    }
    return result.error.length;
  } catch (e) {
    return assetComparisonIds.length;
  }
}

function AddActivations({
  items,
  activationDetails,
  coreAsset,
  onClose,
  showAssetModal,
}) {
  const [selectedIds, setSelectedIds] = useState({});
  const [active, setActive] = useState(false);
  const selectedCount = Object.values(selectedIds).length;

  const handleSelectAll = () => {
    const allSelectedIds = items.reduce((ids, item) => ({
      ...ids,
      [item.assetComparisonId.value]: true,
    }), {});

    setSelectedIds(allSelectedIds);
  };

  const handleAdd = async () => {
    const assetComparisonIds = Object.keys(selectedIds);
    setActive(true);

    const failedCount = await postMatch(assetComparisonIds);
    const okCount = assetComparisonIds.length - failedCount;

    if (okCount) {
      addToast(`(${okCount}) Activations successfully added!`);
    }
    if (failedCount) {
      addToast(`Failed to add (${failedCount}) Activations!`, { type: 'error' });
    }
    setSelectedIds({});
    setActive(false);
    onClose();
    window.location.reload();
  };

  const renderActivations = () => {
    if (items.length === 0) {
      return (
        <>
          <Divider />
          <div className="t-empty u-flexRow u-alignCenter u-marginTop-24">
            This core asset has no similar activations to activate.
          </div>
        </>
      );
    }

    return (
      <div className={styles.cards}>
        { items.map((item) => {
          const id = item.assetComparisonId.value;

          return (
            <ActivationCard
              key={id}
              item={item}
              selected={selectedIds[id]}
              selectable
              onClick={() => setSelectedIds((last) => toggleProperty(last, id))}
              onExpand={() => showAssetModal(item)}
            />
          );
        }) }
      </div>
    );
  };

  return (
    <>
      <StickyTop>
        <CoreAssetDetails
          activationDetails={activationDetails}
          coreAsset={coreAsset}
          isEditing
        />
        <div className={styles.controls}>
          <h5>Select from the similar assets below that you would like to add to activations.</h5>
          <div className="u-flexRow u-gap-8">
            <div className="u-marginRight-16">
              <Button
                label="Select All"
                variant="tertiary"
                onClick={handleSelectAll}
              />
            </div>
            <ActionButton
              active={active}
              disabled={selectedCount === 0}
              label={`Activate Selected (${selectedCount})`}
              variant="secondary"
              onClick={handleAdd}
            />
            <Button
              label="Cancel"
              onClick={onClose}
            />
          </div>
        </div>
      </StickyTop>
      { renderActivations() }
    </>
  );
}

AddActivations.propTypes = propTypes;

export default AddActivations;
