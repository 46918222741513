import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from '@makeably/creativex-design-system';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

const defaultProps = {
  active: false,
  disabled: false,
  label: 'Delete',
};

function DeleteButton({
  active,
  disabled,
  label,
  onClick,
}) {
  return (
    <ActionButton
      active={active}
      disabled={disabled}
      label={label}
      variant="destructive"
      onClick={onClick}
    />
  );
}

DeleteButton.propTypes = propTypes;
DeleteButton.defaultProps = defaultProps;

export default DeleteButton;
