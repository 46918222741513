import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  MessageModal,
  MultipleDropdown,
  TextInput,
  Toggle,
  toggleObject,
} from '@makeably/creativex-design-system';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import {
  patch,
  post,
} from 'utilities/requests';
import {
  thirdPartyIntegrationConfigEligibilityRulePath,
  thirdPartyIntegrationConfigEligibilityRulesPath,
  thirdPartyIntegrationConfigPath,
} from 'utilities/routes';
import styles from './EligibilityConfiguration.module.css';

const propTypes = {
  action: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  configId: PropTypes.number.isRequired,
  markets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  publishers: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ).isRequired,
  eligibility: PropTypes.shape({
    brands: PropTypes.arrayOf(PropTypes.string),
    channelId: PropTypes.number,
    creativeTypes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    inflight: PropTypes.bool,
    markets: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    preflight: PropTypes.bool,
    publishers: PropTypes.arrayOf(PropTypes.string),
  }),
};

const defaultProps = {
  eligibility: {},
};

const subtitle = 'Define the criteria that will determine which ads will be automatically sent '
  + 'to this partner for scoring. You’ll need to do configure this once for each channel.';

function initialCreativeType(initial, type) {
  return initial?.includes(type) || initial?.length === 0;
}

function initialName(initial, action) {
  if (action === 'duplicate') {
    return `(Copy of) ${initial}`;
  }

  return initial || '';
}

function initialChannel(initial, channels) {
  return channels.find(({ value }) => value === initial);
}

function initialMultiSelect(initial, options) {
  return options.filter(({ value }) => initial?.includes(value.toString()));
}

function formatCreativeTypes(isImage, isVideo) {
  if (isImage && !isVideo) {
    return ['image'];
  } else if (isVideo && !isImage) {
    return ['video'];
  }

  return [];
}

function formatArray(selected, options) {
  if (selected.length === options.length) return [];

  return selected.map((item) => item.value);
}

async function determineRoute(action, configId, eligibilityId, data) {
  if (action === 'edit') {
    return patch(thirdPartyIntegrationConfigEligibilityRulePath(
      eligibilityId,
      { integration_config_id: configId },
    ), data);
  }

  return post(thirdPartyIntegrationConfigEligibilityRulesPath(
    { integration_config_id: configId },
  ), data);
}

function EligibilityConfiguration({
  action,
  brands: brandOptions,
  channels: channelOptions,
  configId,
  eligibility: {
    brands,
    channelId: initialChannelId,
    creativeTypes: initialCreativeTypes,
    inflight: initialInflight,
    id: eligibilityId,
    markets,
    name: initName,
    preflight: initialPreflight,
    publishers: initialPublishers,
  },
  markets: marketOptions,
  publishers: publisherLookup,
}) {
  useViewPage(null, {
    action,
    eligibilityId,
    integrationConfigId: configId,
  });

  const initialBrands = action !== 'new' && brands.length === 0 ? Object.keys(brandOptions) : brands;
  const initialMarkets = action !== 'new' && markets.length === 0 ? Object.keys(marketOptions) : markets;

  const [isBusy, setIsBusy] = useState(false);
  const [isImage, setIsImage] = useState(initialCreativeType(initialCreativeTypes, 'image'));
  const [isInflight, setIsInflight] = useState(initialInflight);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreflight, setIsPreflight] = useState(initialPreflight);
  const [isVideo, setIsVideo] = useState(initialCreativeType(initialCreativeTypes, 'video'));
  const [name, setName] = useState(initialName(initName, action));
  const [selectedBrands, setSelectedBrands] = useState(
    initialMultiSelect(initialBrands, brandOptions),
  );
  const [selectedChannel, setSelectedChannel] = useState(
    initialChannel(initialChannelId, channelOptions),
  );
  const [selectedMarkets, setSelectedMarkets] = useState(
    initialMultiSelect(initialMarkets, marketOptions),
  );

  const publisherOptions = useMemo(() => {
    if (!selectedChannel) return [];

    const channelPublishers = publisherLookup[selectedChannel?.value.toString()] || [];

    return channelPublishers.map((publisher) => {
      const [label, value] = publisher.split('::');

      return {
        label,
        value,
      };
    });
  }, [selectedChannel]);

  const [selectedPublishers, setSelectedPublishers] = useState(
    initialMultiSelect(initialPublishers, publisherOptions),
  );

  const isSaveDisabled = name === ''
    || (!isImage && !isVideo)
    || (!isPreflight && !isInflight)
    || selectedBrands.length === 0
    || selectedMarkets.length === 0
    || selectedChannel === undefined
    || (Object.keys(publisherLookup).includes(selectedChannel?.value.toString())
      && selectedPublishers.length === 0);

  const handleChannelChange = (channel) => {
    setSelectedChannel(channel);
    setSelectedPublishers([]);
  };

  const handlePublisherChange = (publishers) => {
    setSelectedPublishers(toggleObject(selectedPublishers, publishers));
  };

  const handleSave = async () => {
    setIsBusy(true);
    track('save_eligibility_configuration', {
      action,
      eligibilityId,
      integrationConfigId: configId,
    });

    const data = {
      brands: formatArray(selectedBrands, brandOptions),
      channel_id: selectedChannel?.value,
      creative_types: formatCreativeTypes(isImage, isVideo),
      inflight: isInflight,
      markets: formatArray(selectedMarkets, marketOptions),
      name,
      preflight: isPreflight,
      publishers: selectedPublishers.map((publisher) => publisher.value),
    };

    const response = await determineRoute(action, configId, eligibilityId, data);

    if (!response.isError) {
      addToast('Eligibility criteria saved successfully.');
      window.location.href = thirdPartyIntegrationConfigPath(configId);
    } else {
      addErrorToast('Something went wrong, please try again.');
      setIsBusy(false);
    }
  };

  return (
    <>
      <Card padding={false}>
        <div className={styles.cardPadding}>
          <h5>Eligibility Criteria</h5>
          <div className="t-subtitle u-marginTop-8">{ subtitle }</div>
        </div>
        <Divider />
        <div className={`u-flexColumn u-gap-24 ${styles.cardPadding}`}>
          <TextInput
            label="Name"
            size="large"
            value={name}
            onChange={setName}
          />
          <div className="u-flexColumn u-gap-24">
            <h5>Brand/Market Eligibility</h5>
            <div className="u-flexRow u-gap-24 u-alignCenter">
              <MultipleDropdown
                label="Brands"
                menuProps={{ size: 'medium' }}
                options={brandOptions}
                selected={selectedBrands}
                size="medium"
                onChange={(b) => setSelectedBrands(toggleObject(selectedBrands, b))}
                onSelectAll={setSelectedBrands}
              />
              <MultipleDropdown
                label="Markets"
                menuProps={{ size: 'medium' }}
                options={marketOptions}
                selected={selectedMarkets}
                size="medium"
                onChange={(m) => setSelectedMarkets(toggleObject(selectedMarkets, m))}
                onSelectAll={setSelectedMarkets}
              />
            </div>
          </div>
          <div className="u-flexColumn u-gap-24">
            <h5>Channel Eligibility</h5>
            <div className="u-flexRow u-gap-24 u-alignCenter">
              <Dropdown
                label="Channel"
                menuProps={{ size: 'medium' }}
                options={channelOptions}
                selected={selectedChannel}
                size="medium"
                onChange={handleChannelChange}
              />
              { publisherOptions.length > 0 && (
                <MultipleDropdown
                  label="Publisher"
                  menuProps={{ size: 'medium' }}
                  options={publisherOptions}
                  selected={selectedPublishers}
                  size="medium"
                  onChange={handlePublisherChange}
                />
              ) }
            </div>
          </div>
          <div className="u-flexRow">
            <div className="u-flexColumn">
              <div className="t-caption-1 u-marginBottom-16">Asset Type</div>
              <div className="u-flexColumn u-gap-24">
                <Toggle
                  checked={isImage}
                  label="Image"
                  right
                  onChange={(e) => setIsImage(e.target.checked)}
                />
                <Toggle
                  checked={isVideo}
                  label="Video"
                  right
                  onChange={(e) => setIsVideo(e.target.checked)}
                />
              </div>
            </div>
            <div className={`u-flexColumn ${styles.marginLeft}`}>
              <div className="t-caption-1 u-marginBottom-16">Campaign Status</div>
              <div className="u-flexColumn u-gap-24">
                <Toggle
                  checked={isPreflight}
                  label="Pre-Flight"
                  right
                  onChange={(e) => setIsPreflight(e.target.checked)}
                />
                <Toggle
                  checked={isInflight}
                  label="In-Flight"
                  right
                  onChange={(e) => setIsInflight(e.target.checked)}
                />
              </div>
            </div>
          </div>
          <div className="u-buttonGroup u-justifyEnd">
            <Button
              label="Cancel"
              url={thirdPartyIntegrationConfigPath(configId)}
              variant="tertiary"
            />
            <Button
              disabled={isSaveDisabled || isBusy}
              label="Save"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      </Card>
      <MessageModal
        actionButtonActive={isBusy}
        actionButtonLabel="Save"
        isOpen={isModalOpen}
        title="Confirm Automatic Request Eligibility Configuration"
        onActionButtonClick={handleSave}
        onClose={() => setIsModalOpen(false)}
      >
        Clicking “Save” will enable automatic score requests for all future
        CreativeX assets that are eligible under this configuration.
      </MessageModal>
    </>
  );
}

EligibilityConfiguration.defaultProps = defaultProps;
EligibilityConfiguration.propTypes = propTypes;

export default EligibilityConfiguration;
