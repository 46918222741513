import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';
import Chart from 'components/molecules/Chart';
import { getCssVarsObj } from 'utilities/css';
import {
  getItemLabel,
  itemProps,
} from 'utilities/item';

const metricProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const propTypes = {
  items: PropTypes.arrayOf(itemProps).isRequired,
  metrics: PropTypes.arrayOf(metricProps).isRequired,
  segments: PropTypes.arrayOf(metricProps).isRequired,
};

const BAR_PERCENTAGE = 0.75;
const BAR_THICKNESS = 36;
const CHART_PADDING = 80;
const DEFAULT_SHOWN = 5;
const PER_ITEM_HEIGHT = BAR_THICKNESS / BAR_PERCENTAGE;

const barMetrics = [
  'reviewed',
  'pending',
  'unreviewable',
  'mbmm',
  'missBp',
  'display',
  'progress',
  'disBp',
  'unsupported',
];

const metricColorVars = {
  disBp: '--grey-400',
  display: '--orange-500',
  mbmm: '--red-500',
  missBp: '--red-300',
  pending: '--purple-300',
  progress: '--green-400',
  reviewed: '--purple-400',
  unreviewable: '--blue-300',
  unsupported: '--grey-200',
};

const metricColors = getCssVarsObj(metricColorVars);

function getLabels(items, segments) {
  const keys = segments.map(({ value }) => value);

  return items.map((item) => (
    keys.map((key) => getItemLabel(item, key)).join(' | ')
  ));
}

function getChartData(items, segments, metricLabels) {
  const labels = getLabels(items, segments);
  const datasets = barMetrics.map((metric) => ({
    backgroundColor: metricColors[metric],
    data: items.map((item) => item[metric]?.value ?? 0),
    dataLabels: items.map((item) => item[metric]?.label),
    label: metricLabels[metric],
  }));

  return {
    datasets,
    labels,
  };
}

function getChartOptions() {
  return {
    animation: {
      duration: 0,
    },
    barPercentage: BAR_PERCENTAGE,
    barThickness: BAR_THICKNESS,
    categoryPercentage: 1,
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: ({ dataset, dataIndex }) => {
            const labels = dataset.dataLabels ?? [];
            return ` ${dataset.label}: ${labels[dataIndex]}`;
          },
          title: () => null,
        },
        displayColors: true,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
}

function MetricsBarChart({
  items,
  metrics,
  segments,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [allShown, setAllShown] = useState(false);
  const chartOptions = useMemo(() => getChartOptions(), []);

  const shownItems = useMemo(() => (
    allShown ? items : items.slice(0, DEFAULT_SHOWN)
  ), [items, allShown]);

  const metricLabels = useMemo(() => (
    metrics.reduce((obj, { label, value }) => ({
      ...obj,
      [value]: label,
    }), {})
  ), [metrics]);

  const chartData = useMemo(() => (
    getChartData(shownItems, segments, metricLabels)
  ), [shownItems, segments]);

  const height = CHART_PADDING + (shownItems.length * PER_ITEM_HEIGHT);

  return (
    <div>
      <div className="u-flexRow u-alignCenter u-gap-8">
        <h5>Visualization</h5>
        <Button
          iconLeft={isOpen ? 'chevronUp' : 'chevronDown'}
          variant="round"
          onClick={() => setIsOpen((last) => !last)}
        />
      </div>
      { isOpen && (
        <div>
          <div style={{ height: `${height}px` }}>
            <Chart
              data={chartData}
              options={chartOptions}
              type="bar"
            />
          </div>
          { items.length > DEFAULT_SHOWN && (
            <div className="u-flexRow u-justifyEnd u-marginTop-8">
              <Button
                label={allShown ? 'Show Less' : 'Show All'}
                variant="tertiary"
                onClick={() => setAllShown((last) => !last)}
              />
            </div>
          ) }
        </div>
      ) }
    </div>
  );
}

MetricsBarChart.propTypes = propTypes;

export default MetricsBarChart;
