import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Table,
  Tag,
  Tooltip,
} from '@makeably/creativex-design-system';
import Accordion from 'components/atoms/Accordion';
import ChannelLogo from 'components/atoms/ChannelLogo';
import ScoreRow, {
  renderScore,
  statusIcon,
} from 'components/audit/ScoreRow';
import styles from './AuditPostCard.module.css';

const rulePropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  passed: PropTypes.bool.isRequired,
  weight: PropTypes.string,
});

export const postPropTypes = {
  adAccountId: PropTypes.string.isRequired,
  adId: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(rulePropTypes).isRequired,
  channel: PropTypes.string.isRequired,
  channelDisplay: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isPreflight: PropTypes.bool.isRequired,
  market: PropTypes.string.isRequired,
  placements: PropTypes.arrayOf(PropTypes.string).isRequired,
  regulatory: PropTypes.shape({
    rating: PropTypes.string,
    rules: PropTypes.arrayOf(rulePropTypes),
  }).isRequired,
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      defaultScore: PropTypes.bool.isRequired,
      guidelines: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          passed: PropTypes.bool.isRequired,
          scores: PropTypes.arrayOf(PropTypes.number).isRequired,
          weight: PropTypes.string.isRequired,
          definitions: PropTypes.arrayOf(rulePropTypes),
          templateUrl: PropTypes.string,
        }),
      ).isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      tier: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      rank: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setTemplateUrl: PropTypes.func.isRequired,
  snapshots: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  summaryMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultRank: PropTypes.string,
  defaultScore: PropTypes.number,
  defaultTier: PropTypes.string,
};

const defaultProps = {
  defaultRank: null,
  defaultScore: null,
  defaultTier: null,
};

const tooltip = "Your company's default score for this activation";

const versionsHeaders = [
  { value: 'Version' },
  { value: 'Date Used' },
  { value: 'Score' },
  { value: 'Spend' },
  { value: 'CTA' },
  { value: 'Description' },
];

const snapshotRow = ({
  details,
  version,
}) => {
  const cells = [
    {
      value: version,
    },
  ];
  details.forEach(({ _, value }) => {
    cells.push({
      value,
    });
  });
  return {
    key: version,
    cells,
  };
};

export const tableRow = ({
  name, passed,
}) => ({
  key: name,
  cells: [
    {
      value: name,
    },
    {
      value: statusIcon(passed),
    },
  ],
});

function AuditPostCard({
  adAccountId,
  adId,
  brand,
  categories,
  channel,
  channelDisplay,
  contentType,
  defaultRank,
  defaultScore,
  defaultTier,
  description,
  id,
  isPreflight,
  market,
  placements,
  regulatory,
  scores,
  setIsModalOpen,
  setTemplateUrl,
  snapshots,
  summaryMetrics: postSummaryMetrics,
}) {
  const [categoriesExpanded, setCategoriesExpanded] = useState(false);
  const [regulatoryExpanded, setRegulatoryExpanded] = useState(false);
  const [versionsExpanded, setVersionsExpanded] = useState(false);

  const additionalMetricSectionVisible = snapshots.length > 0
    || categories.length > 0
    || regulatory.rules.length > 0;
  const scoresVisible = scores.length > 0;
  const scoreLabelVisible = scores.length > 0 ? !scores.find(
    (score) => score.default_score,
  ).guidelines.some(
    (guideline) => guideline.weight !== '0%' && guideline.passed === null,
  ) : false;
  const scoreLabel = renderScore(defaultScore, defaultRank, defaultTier);

  return (
    <Card
      key={id}
      className={`u-flexColumn u-gap-24 ${styles.auditPostCard}`}
      padding={false}
    >
      <div className="u-flexRow u-spaceBetween">
        <div className="u-flexRow u-alignCenter u-gap-8">
          <ChannelLogo
            channel={channel}
            showPaid={false}
            size="size-32"
          />
          <h5>{ channelDisplay }</h5>
          { (scoreLabel && scoreLabelVisible) && (
            <Tooltip label={tooltip}>
              { scoreLabel }
            </Tooltip>
          ) }
          <Tag color="grey" label={isPreflight ? 'Pre-Flight' : 'In-Flight'} />
        </div>
        <div className="t-caption-1">
          { adId && (
            <div className="u-textAlignRight">
              { `Ad ID: ${adId}` }
            </div>
          ) }
          { adAccountId && (
            <div className="u-textAlignRight">
              { `Ad Account Id: ${adAccountId}` }
            </div>
          ) }
        </div>
      </div>
      <h5>Overview</h5>
      <div className={
        isPreflight ? styles.preflightScoreOverviewGrid : styles.inflightScoreOverviewGrid
        }
      >
        { postSummaryMetrics.map(({ label, value }) => (
          <div key={label} className={styles.metric}>
            <div className="t-caption-1">{ label }</div>
            <div className="t-body-1">{ value }</div>
          </div>
        )) }
      </div>
      <div className={styles.metric}>
        <div className="t-caption-1">Placements</div>
        <div className="t-body-1">{ placements.length > 0 ? placements.join(' | ') : 'N/A' }</div>
      </div>
      <div className={`${styles.metric} ${additionalMetricSectionVisible && !scoresVisible ? 'u-marginBottom-24' : ''}`}>
        <div className="t-caption-1">Description</div>
        <div className="t-body-1">{ description }</div>
      </div>
      { scoresVisible && (
        <div>
          <h5 className="u-marginBottom-24">Scores</h5>
          <div className="u-marginBottom-24"><Divider /></div>
          { scores.map((score) => (
            <ScoreRow
              key={score.id}
              brand={brand}
              channel={channel}
              contentType={contentType}
              guidelines={score.guidelines}
              isDefaultScore={score.default_score}
              isPreflight={isPreflight}
              label={score.label}
              market={market}
              placements={placements}
              rank={score.rank}
              scoreId={score.id}
              setIsModalOpen={setIsModalOpen}
              setTemplateUrl={setTemplateUrl}
              tier={score.tier}
              value={score.value}
            />
          )) }
        </div>
      ) }
      { additionalMetricSectionVisible && (
        <div className="u-flexColumn u-gap-24">
          { snapshots.length > 0 && (
            <Accordion
              chevronOnRight={false}
              divider={false}
              header={(
                <div className={styles.headerRow}>
                  { `${versionsExpanded ? 'Hide' : 'Show'} Versions` }
                </div>
              )}
              initiallyExpanded={false}
              onClick={() => setVersionsExpanded(!versionsExpanded)}
            >
              <Table
                className={styles.table}
                headers={versionsHeaders}
                rows={snapshots.map(snapshotRow)}
              />
            </Accordion>
          ) }
          { categories.length > 0 && (
            <Accordion
              chevronOnRight={false}
              divider={false}
              header={(
                <div className={styles.headerRow}>
                  Brand Cues
                </div>
              )}
              initiallyExpanded={false}
              onClick={() => setCategoriesExpanded(!categoriesExpanded)}
            >
              <Table
                className={styles.table}
                divider={false}
                headers={[
                  { value: 'Brand Cues' },
                  {
                    className: styles.criteriaMet,
                    value: 'Criteria Met',
                  },
                ]}
                rows={categories.map(tableRow)}
              />
            </Accordion>
          ) }
          { regulatory.rules.length > 0 && (
            <Accordion
              chevronOnRight={false}
              divider={false}
              header={(
                <div className={styles.headerRow}>
                  Compliance
                </div>
              )}
              initiallyExpanded={false}
              onClick={() => setRegulatoryExpanded(!regulatoryExpanded)}
            >
              <Table
                className={styles.table}
                headers={[
                  { value: 'Name' },
                  {
                    className: styles.criteriaMet,
                    value: 'Criteria Met',
                  },
                ]}
                rows={regulatory.rules.map((tableRow))}
              />
            </Accordion>
          ) }
        </div>
      ) }
    </Card>
  );
}

AuditPostCard.propTypes = postPropTypes;
AuditPostCard.defaultProps = defaultProps;

export default AuditPostCard;
