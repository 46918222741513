import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Logo,
  Tag,
} from '@makeably/creativex-design-system';
import EligibilityTable, { eligibilityProps } from 'components/third_party/EligibilityTable';
import { useViewPage } from 'utilities/mixpanel';
import { toCount } from 'utilities/string';
import styles from './IntegrationDetails.module.css';

const propTypes = {
  config: PropTypes.shape({
    assetCount: PropTypes.number,
    dateLinked: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    scores: PropTypes.arrayOf(PropTypes.string),
    state: PropTypes.string,
  }).isRequired,
  ...eligibilityProps,
};

function renderLogo(logoUrl) {
  if (logoUrl) {
    return <img alt="Platform Logo" src={logoUrl} />;
  }

  return <Logo name="DEFAULT" />;
}

function renderMetric({ label, value }) {
  return (
    <div className="u-flexColumn u-gap-8">
      <div className="t-caption-1">{ label }</div>
      <div className="t-body-1">{ value || '-' }</div>
    </div>
  );
}

function IntegrationDetails({
  brandLookup,
  canManage,
  config: {
    assetCount,
    dateLinked,
    description,
    id,
    logoUrl,
    name,
    scores,
    state,
  },
  eligibility,
  marketLookup,
  publisherLookup,
}) {
  useViewPage(null, { integrationConfigId: id });

  return (
    <div className="u-flexColumn u-gap-24">
      <Card>
        <div className="u-flexRow u-alignCenter u-justifyBetween">
          <div className="u-flexRow u-alignCenter">
            <div className={styles.logo}>
              { renderLogo(logoUrl) }
            </div>
            <h5>{ name }</h5>
          </div>
          { state === 'pending' && <Tag color="orange" label="Pending" /> }
        </div>
        <div className="t-body-1 u-marginTop-16">{ description }</div>
        <div className="u-flexRow u-marginTop-24 u-marginBottom-24 u-gap-24">
          { renderMetric({
            label: 'Date Linked',
            value: dateLinked,
          }) }
          { renderMetric({
            label: 'Assets Analyzed',
            value: toCount(assetCount),
          }) }
        </div>
        <div className="u-flexRow u-marginTop-16">
          { renderMetric({
            label: 'Scores',
            value: scores.length > 0 ? scores.join(', ') : null,
          }) }
        </div>
        <Divider margin />
        <EligibilityTable
          brandLookup={brandLookup}
          canManage={canManage}
          eligibility={eligibility}
          id={id}
          marketLookup={marketLookup}
          publisherLookup={publisherLookup}
        />
      </Card>
    </div>
  );
}

IntegrationDetails.propTypes = propTypes;

export default IntegrationDetails;
