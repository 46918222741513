import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import DefinitionDisplay from 'components/internal/guidelines/DefinitionDisplay';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { itemProps } from 'utilities/item';
import {
  post,
  destroy,
} from 'utilities/requests';
import {
  editAdminGuidelineDetailPath,
  internalGuidelineDefinitionPath,
  newAdminGuidelineGuidelineDetailPath,
  toggleStateInternalGuidelineDefinitionPath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';

const propTypes = {
  guideline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    subscriptionCount: PropTypes.number.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: itemProps.isRequired,
  onIsOpenToggle: PropTypes.func.isRequired,
};

function DefinitionCard({
  guideline,
  isOpen,
  item,
  onIsOpenToggle,
}) {
  const isGuidelineSubscribed = guideline.state === 'active' && guideline.subscriptionCount > 0;
  const isActive = item.state.value === 'active';
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState(false);

  const handleDelete = async () => {
    const { isError } = await destroy(internalGuidelineDefinitionPath(guideline.id, item.id.value));

    if (isError) {
      addErrorToast('Definition could not be deleted. Please try again later');
    } else {
      addToast('Deleted definition');
    }
    window.location.reload();
  };

  const toggleActive = async () => {
    const action = isActive ? 'deactivated' : 'activated';
    const url = toggleStateInternalGuidelineDefinitionPath(guideline.id, item.id.value);
    const { isError } = await post(url);

    if (isError) {
      addErrorToast(`Definition could not be ${action}. Please try again later`);
    } else {
      addToast(`${titleize(action)} definition`);
    }
    window.location.reload();
  };

  const confirmAndToggleActive = () => {
    if (isGuidelineSubscribed) {
      setConfirmToggle(true);
    } else {
      toggleActive();
    }
  };

  return (
    <>
      <Card className="u-flexColumn u-gap-16">
        <DefinitionDisplay
          isOpen={isOpen}
          item={item}
          onIsOpenToggle={onIsOpenToggle}
        />
        { isOpen && (
          <div className="u-flexRow u-justifyEnd u-gap-8">
            <Button
              disabled={isGuidelineSubscribed || isActive}
              label="Delete"
              variant="destructive"
              onClick={() => setConfirmDelete(true)}
            />
            <Button
              label={isActive ? 'Deactivate' : 'Activate'}
              variant="secondary"
              onClick={confirmAndToggleActive}
            />
            <Button
              label="Duplicate"
              url={newAdminGuidelineGuidelineDetailPath(
                guideline.id, { duplicate_id: item.id.value },
              )}
              variant="secondary"
            />
            <Button
              label="Edit"
              url={editAdminGuidelineDetailPath(item.id.value)}
              variant="secondary"
            />
          </div>
        ) }
      </Card>
      <ConfirmationModal
        isOpen={confirmDelete}
        message="Are you sure you want to delete this definition?"
        title="Confirm Definition Delete"
        onClose={() => setConfirmDelete(false)}
        onConfirm={handleDelete}
      />
      <ConfirmationModal
        isOpen={confirmToggle}
        message={`${isActive ? 'Deactivating' : 'Activating'} will update the 
                 ${guideline.subscriptionCount} guideline(s) that are subscribed to this template`}
        title={`Confirm Definition ${isActive ? 'Deactivate' : 'Activate'}`}
        type="warning"
        onClose={() => setConfirmToggle(false)}
        onConfirm={toggleActive}
      />
    </>
  );
}

DefinitionCard.propTypes = propTypes;

export default DefinitionCard;
