import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MessageModal } from '@makeably/creativex-design-system';

const propTypes = {
  action: PropTypes.oneOf(['activate', 'deactivate', 'delete', 'relink']).isRequired,
  adAccount: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    market: PropTypes.string.isRequired,
    recordId: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const actionButtonLabels = {
  activate: 'Activate',
  deactivate: 'Deactivate',
  delete: 'Delete',
  relink: 'Activate',
};

const actionButtonVariants = {
  activate: 'primary',
  deactivate: 'primary',
  delete: 'destructive',
  relink: 'primary',
};

const actionNames = {
  activate: 'Activation',
  deactivate: 'Deactivation',
  delete: 'Deletion',
};

function generateSubtitle(adAccount) {
  return (
    <div className="u-flexColumn">
      <div>{ `Ad Account ID: ${adAccount.recordId}` }</div>
      <div>{ `Brand: ${adAccount.brand}` }</div>
      <div>{ `Market: ${adAccount.market}` }</div>
      <div>{ `Channel: ${adAccount.channel}` }</div>
    </div>
  );
}

function ConfirmAdAccountActionModal({
  action,
  adAccount,
  isOpen,
  onClose,
  onConfirm,
}) {
  const [actionInProgress, setActionInProgress] = useState(false);

  const onConfirmClick = () => {
    setActionInProgress(true);
    onConfirm(action, adAccount);
  };

  return (
    <MessageModal
      actionButtonActive={actionInProgress}
      actionButtonLabel={actionButtonLabels[action]}
      actionButtonVariant={actionButtonVariants[action]}
      isOpen={isOpen}
      subtitle={generateSubtitle(adAccount)}
      title={`Confirm Ad Account ${actionNames[action]}`}
      onActionButtonClick={onConfirmClick}
      onClose={onClose}
    >
      { action === 'delete' && (
        <span>
          Deleting this ad account permanently removes all of its data from our system.
          This action cannot be undone and may take a few minutes for larger accounts.
        </span>
      ) }
    </MessageModal>
  );
}

ConfirmAdAccountActionModal.propTypes = propTypes;

export default ConfirmAdAccountActionModal;
