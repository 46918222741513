import { v4 as uuidv4 } from 'uuid';
import { NULL_VALUE } from 'components/internal/shared';
import {
  removeAccents,
  splitLines,
  toDate,
  toPercent,
  toSpend,
} from 'utilities/string';

export const MULTI_BRAND = 'Multi-Brand';
export const MULTI_MARKET = 'Multi-Market';

export function createRow(headers) {
  return headers.reduce((obj, { key, type }) => ({
    ...obj,
    [key]: type === 'text' ? '' : null,
  }), {
    uuid: uuidv4(),
  });
}

export function duplicateLastRow(rows, headers) {
  const duplicate = rows.at(-1) ?? createRow(headers);

  return [
    ...rows,
    {
      ...duplicate,
      uuid: uuidv4(),
    },
  ];
}

function parseCsvIndices(firstLine, headers) {
  const labels = firstLine.split(',');

  return headers.map((column) => {
    const index = labels.indexOf(column.label);

    if (index === -1) {
      return labels.indexOf(column.alternateLabel);
    }
    return index;
  });
}

export function parseCsv(csv, headers, optionsByKey) {
  const unaccented = removeAccents(csv);
  const lines = splitLines(unaccented);
  const indices = parseCsvIndices(lines[0], headers);

  return lines.slice(1).map((line) => {
    const values = line.split(',');

    return indices.reduce((obj, valueIndex, index) => {
      if (valueIndex === -1) {
        return obj;
      }

      const { key, type } = headers[index];
      const value = values[valueIndex];

      if (type === 'text') {
        return {
          ...obj,
          [key]: value,
        };
      }

      const options = optionsByKey[key] ?? [];
      const found = options.find((option) => option.label.toLowerCase() === value.toLowerCase())
        ?? null;

      return {
        ...obj,
        [key]: found,
      };
    }, createRow(headers));
  });
}

function getClientWarning(brandId, marketId, clients) {
  if (brandId && marketId) {
    if (brandId === MULTI_BRAND || marketId === MULTI_MARKET) return null;

    if (!clients[`${brandId}::${marketId}`]) {
      return 'No existing client with the selected brand and market. Adding this link will create a new client.';
    }
  }

  return null;
}

export function addClientWarning(connection, clients) {
  const { brand, market } = connection;

  return {
    ...connection,
    warning: getClientWarning(brand?.value, market?.value, clients),
  };
}

function getDataValues(connection, headers) {
  return headers.map(({ key }) => connection[key]);
}

export function hasContent(connection, headers) {
  return getDataValues(connection, headers).some((value) => Boolean(value));
}

export function areValid(connections, headers) {
  if (connections.length === 0) return false;

  return connections.every((connection) => (
    getDataValues(connection, headers).every((value) => Boolean(value))
  ));
}

function getBinTag(item, keys) {
  const values = keys.map((key) => item[key]?.value);

  return values.join('::');
}

export function getBins(items, segments) {
  const segmentKeys = segments.map((segment) => segment.value);
  const defaultBin = { items: [] };

  // Split the items into unique segmentKey combinations
  const binsByTag = items.reduce((bins, item) => {
    const tag = getBinTag(item, segmentKeys);
    const bin = bins[tag] ?? defaultBin;

    return {
      ...bins,
      [tag]: {
        ...bin,
        tag,
        items: [...bin.items, item],
      },
    };
  }, {});

  return Object.values(binsByTag);
}

function getBinItemSegments(bin, segments) {
  // All items in the bin have the same segments, so take the first one
  const keys = segments.map((segment) => segment.value);
  const item = bin.items[0];

  return keys.reduce((obj, key) => ({
    ...obj,
    [key]: item[key],
  }), {});
}

function calculateTotals(items) {
  return items.reduce((sum, {
    disBp,
    display,
    mbmm,
    missBp,
    pending,
    progress,
    reviewable,
    reviewed,
    stale,
    top,
    unreviewable,
    unsupported,
  }) => ({
    disBpTotal: sum.disBpTotal + disBp.value,
    displayTotal: sum.displayTotal + display.value,
    mbmmBpTotal: sum.mbmmBpTotal + mbmm.value,
    missBpTotal: sum.missBpTotal + missBp.value,
    pendingTotal: sum.pendingTotal + pending.value,
    progressTotal: sum.progressTotal + progress.value,
    reviewableTotal: sum.reviewableTotal + reviewable.value,
    reviewedTotal: sum.reviewedTotal + reviewed.value,
    staleTotal: sum.staleTotal + stale.value,
    topTotal: sum.topTotal + top.value,
    unreviewableTotal: sum.unreviewableTotal + unreviewable.value,
    unsupportedTotal: sum.unsupportedTotal + unsupported.value,
  }), {
    disBpTotal: 0,
    displayTotal: 0,
    mbmmBpTotal: 0,
    missBpTotal: 0,
    pendingTotal: 0,
    progressTotal: 0,
    reviewableTotal: 0,
    reviewedTotal: 0,
    staleTotal: 0,
    topTotal: 0,
    unreviewableTotal: 0,
    unsupportedTotal: 0,
  });
}

function getDateMetric(items, key) {
  const set = new Set(items.map((item) => item[key]?.value));
  const timestamp = set.values().toArray()[0];

  if (set.size > 1 || timestamp === NULL_VALUE) {
    return {
      label: 'Multiple',
      value: NULL_VALUE,
    };
  }

  return {
    label: toDate(new Date(timestamp), true),
    value: timestamp ?? NULL_VALUE,
  };
}

function getPercentMetric(value) {
  const label = Number.isNaN(value) ? 'N/A' : toPercent(value);

  return {
    label,
    value,
  };
}

function getSpendMetric(value) {
  return {
    label: toSpend(value),
    value,
  };
}

function getBinItemMetrics(bins) {
  const {
    disBpTotal,
    displayTotal,
    mbmmBpTotal,
    missBpTotal,
    pendingTotal,
    progressTotal,
    reviewableTotal,
    reviewedTotal,
    staleTotal,
    topTotal,
    unreviewableTotal,
    unsupportedTotal,
  } = calculateTotals(bins);

  const topCov = (reviewedTotal + pendingTotal) / topTotal;
  const reviewableCov = (reviewedTotal + pendingTotal) / reviewableTotal;
  const actionableRate = (missBpTotal + displayTotal + mbmmBpTotal) / reviewableTotal;
  const unreviewableRate = unreviewableTotal / topTotal;
  const unsupportedRate = unsupportedTotal / topTotal;

  return {
    actionableRate: getPercentMetric(actionableRate),
    disBp: getSpendMetric(disBpTotal),
    display: getSpendMetric(displayTotal),
    linked: getDateMetric(bins, 'linked'),
    mbmm: getSpendMetric(mbmmBpTotal),
    missBp: getSpendMetric(missBpTotal),
    pending: getSpendMetric(pendingTotal),
    progress: getSpendMetric(progressTotal),
    reviewable: getSpendMetric(reviewableTotal),
    reviewableCov: getPercentMetric(reviewableCov),
    reviewed: getSpendMetric(reviewedTotal),
    stale: getSpendMetric(staleTotal),
    top: getSpendMetric(topTotal),
    topCov: getPercentMetric(topCov),
    unreviewable: getSpendMetric(unreviewableTotal),
    unreviewableRate: getPercentMetric(unreviewableRate),
    unsupported: getSpendMetric(unsupportedTotal),
    unsupportedRate: getPercentMetric(unsupportedRate),
  };
}

export function getTotalBinItem(binItems, segments) {
  const firstSegment = segments[0]?.value ?? NULL_VALUE;

  return {
    id: { value: '' },
    [firstSegment]: { value: 'Total' },
    ...getBinItemMetrics(binItems),
  };
}

export function getBinItems(bins, segments) {
  return bins.map((bin) => ({
    id: { value: bin.tag },
    ...getBinItemSegments(bin, segments),
    ...getBinItemMetrics(bin.items),
  }));
}
