import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Search,
} from '@makeably/creativex-design-system';
import EmptyGuidelines from 'components/admin/review_queue/EmptyGuidelines';
import GuidelineFilter from 'components/admin/review_queue/GuidelineFilter';
import PreflightBanner from 'components/admin/review_queue/preflight/PreflightBanner';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { adminReviewPreflightGuidelineChecksPath } from 'utilities/routes';
import {
  getParams,
  getParamsObj,
  redirectToParams,
} from 'utilities/url';
import styles from './Guidelines.module.css';
import {
  filterDimensions,
  filterKeys,
  getFilterOptions,
  getFilterSelections,
  getRole,
  headers,
  PARAM_KEYS,
  searchItems,
  updateFilterParams,
} from '../shared';

const propTypes = {
  counts: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      guidelineId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  filterValues: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ).isRequired,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape({
      brandCue: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      ruleType: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

function getCountsByGuideline(counts) {
  return counts.reduce((obj, { guidelineId, count }) => ({
    ...obj,
    [guidelineId]: count,
  }), {});
}

function getCount(guidelineId, value, params) {
  const paramsObj = getParamsObj(params, PARAM_KEYS.concat(filterKeys));
  const url = adminReviewPreflightGuidelineChecksPath(guidelineId, paramsObj);

  return {
    value,
    element: <a href={url}>{ value }</a>,
  };
}

function Guidelines({
  counts,
  filterValues,
  guidelines,
}) {
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'count',
    asc: false,
  });
  const [sortedItems, setSortedItems] = useState(items);
  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState(items);
  const [filterOpen, setFilterOpen] = useState(false);
  const params = getParams(window);
  const filterOptions = getFilterOptions(filterDimensions, filterValues);
  const filterSelections = getFilterSelections(params, filterKeys, filterOptions);

  useEffect(() => {
    const countsObj = getCountsByGuideline(counts);
    const getItems = () => (
      guidelines.reduce((all, {
        id, brandCue, name, ruleType,
      }) => {
        const count = countsObj[id];

        if (!count) return all;

        return [
          ...all,
          {
            id: { value: id },
            name: { value: name },
            role: { value: getRole(brandCue, ruleType) },
            count: getCount(id, count, params),
          },
        ];
      }, [])
    );

    setItems(getItems());
  }, [guidelines, counts]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      setSortedItems(items.slice().sort(byKeyDir));
    }
  }, [items, sort]);

  useEffect(() => {
    setSearchedItems(searchItems(sortedItems, search));
  }, [sortedItems, search]);

  const handleFilterSelect = (selections) => {
    updateFilterParams(params, filterKeys, selections);
    redirectToParams(params, window);
  };

  return (
    <Card>
      <div className={styles.controls}>
        <div className={styles.filters}>
          <GuidelineFilter
            dimensions={filterDimensions}
            isOpen={filterOpen}
            options={filterOptions}
            selections={filterSelections}
            values={filterValues}
            valuesOld={{}}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelect={handleFilterSelect}
          />
        </div>
        <div className={styles.warningGap}>
          <PreflightBanner />
          <Search
            placeholder="Search Rule"
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </div>
      </div>
      <ItemsTable
        className={styles.table}
        headers={headers}
        items={searchedItems}
        maxWidthSize="custom"
        sort={sort}
        onSortChange={(value) => setSort(value)}
      />
      <EmptyGuidelines length={searchItems.length} search={search} />
    </Card>
  );
}

Guidelines.propTypes = propTypes;

export default Guidelines;
