// Utilities for organizing records into categorized bins and creating items from those bins
// Metric totals (spend, etc.) are calculated for each bin item
// by summing the record values in those bins
import { rate } from 'utilities/number';
import {
  titleize,
  toConciseCount,
  toConciseSpend,
  toCount,
  toPercent,
  toSpend,
} from 'utilities/string';
import {
  NONE_KEY,
} from './utilities';
import {
  EMPTY_LABEL,
  EMPTY_VALUE,
  getPublisherLabel,
} from '../shared/channel';

function getBinTags(record, keys) {
  const values = keys.map((key) => record[key]);

  // NOTE: Expand segment tags when custom filters with overlapping filter options are applied
  const segmentKeys = values.reduce((arr, key) => {
    if (Array.isArray(key)) {
      return key.flatMap((keyOption) => arr.map((item) => [...item, keyOption]));
    }

    return arr.map((item) => [...item, key]);
  }, [[]]);

  return segmentKeys.map((val) => val.join('::'));
}

export function getBinsByTag(segments, records) {
  const segmentKeys = segments.map((segment) => segment.value);
  const defaultBin = { records: [] };

  // Split the records into unique segmentKey combinations
  return records.reduce((bins, record) => {
    const tags = getBinTags(record, segmentKeys);

    // NOTE: In most cases, there will only be one tag. Records can only belong in
    // multiple bins if they have a custom filter with overlapping filter options
    return tags.reduce((obj, tag) => {
      const bin = obj[tag] ?? defaultBin;

      return {
        ...obj,
        [tag]: {
          ...bin,
          tag,
          records: [...bin.records, record],
        },
      };
    }, bins);
  }, {});
}

export function getSegmentLabel(record, key, tagValue) {
  // NOTE: When segment has multiple values, use the tag value to determine which value to display
  if (Array.isArray(record[key])) return tagValue;

  switch (key) {
    case 'assetType':
      return titleize(record[key]);
    case 'publisher':
      return getPublisherLabel(record[key]);
    default:
      return record[key] ?? EMPTY_LABEL;
  }
}

function getBinSegments(bin, segments) {
  const tags = bin.tag.split('::');
  const segmentValues = segments.map((segment, index) => ({
    key: segment.value,
    label: tags[index],
  }));
  // All records in the bin have the same segments, so take the first one
  const record = bin.records[0];

  if (segmentValues.length === 0) {
    return {
      [NONE_KEY]: { value: 'Total' },
    };
  }

  return segmentValues.reduce((obj, { key, label }) => ({
    ...obj,
    [key]: {
      label: getSegmentLabel(record, key, label),
      value: label ?? record[key] ?? EMPTY_VALUE,
    },
  }), {});
}

function getScoreDefaults(scoreIds) {
  return scoreIds.reduce((obj, id) => ({
    ...obj,
    [id]: {
      highestCount: 0,
      highestSpend: 0,
      rankedCount: 0,
      rankedSpend: 0,
      scoreSum: 0,
    },
  }), {});
}

function updateScoreTotals(record, totals, scoreIds, highestRanks) {
  return scoreIds.reduce((obj, id) => {
    const rank = record[`rank::${id}`];
    const isHighestRank = rank === highestRanks[id];

    return {
      ...obj,
      [id]: {
        highestCount: totals[id].highestCount + (isHighestRank ? record.postCount : 0),
        highestSpend: totals[id].highestSpend + (record[`excellentSpend::${id}`] ?? 0),
        rankedCount: totals[id].rankedCount + (rank ? record.postCount : 0),
        rankedSpend: totals[id].rankedSpend + (rank ? record.spend : 0),
        scoreSum: totals[id].scoreSum + (record[`score::${id}`] ?? 0),
      },
    };
  }, {});
}

function getMetricTotals(records, scores) {
  const scoreIds = scores.map(({ versionId }) => versionId);
  const highestRanks = scores.reduce((obj, { tiers, versionId }) => ({
    ...obj,
    [versionId]: tiers.find((tier) => tier.rank === 'highest')?.label,
  }), {});

  return records.reduce((totals, record) => ({
    assetIds: [...totals.assetIds, ...record.assetIds],
    count: totals.count + record.postCount,
    spend: totals.spend + (record.spend ?? 0),
    ...updateScoreTotals(record, totals, scoreIds, highestRanks),
  }), {
    assetIds: [],
    count: 0,
    spend: 0,
    ...getScoreDefaults(scoreIds),
  });
}

function getBinScoreMetrics(totals, scores) {
  const { spend } = totals;

  return scores.reduce((obj, { versionId }) => {
    const {
      highestCount,
      highestSpend,
      rankedCount,
      rankedSpend,
      scoreSum,
    } = totals[versionId];

    const lowQualitySpend = spend - highestSpend;
    const scoreRate = rate(highestCount, rankedCount);
    const score = rate(scoreSum, rankedCount);
    const spendRate = rate(highestSpend, rankedSpend);

    return {
      ...obj,
      [`averageScore::${versionId}`]: {
        label: toPercent(score) ?? EMPTY_LABEL,
        value: score ?? 0,
      },
      [`lowQualitySpend::${versionId}`]: {
        concise: toConciseSpend(lowQualitySpend),
        label: toSpend(lowQualitySpend),
        value: lowQualitySpend,
      },
      [`qualitySpend::${versionId}`]: {
        concise: toConciseSpend(highestSpend),
        label: toSpend(highestSpend),
        value: highestSpend,
      },
      [`qualitySpendRate::${versionId}`]: {
        label: toPercent(spendRate) ?? EMPTY_LABEL,
        value: spendRate ?? 0,
      },
      [`scoreRate::${versionId}`]: {
        label: toPercent(scoreRate) ?? EMPTY_LABEL,
        value: scoreRate ?? 0,
      },
    };
  }, {});
}

function getGuidelineBinScoreMetrics(records) {
  if (records[0].passedChecks === undefined) {
    return undefined;
  }
  const totalPassedChecks = records.reduce((sum, record) => sum + record.passedChecks, 0);
  const totalChecks = records.reduce((sum, record) => sum + record.totalChecks, 0);

  const val = totalPassedChecks / totalChecks;
  return {
    label: toPercent(val),
    value: val,
  };
}

function getBinMetrics(bin, scores) {
  const totals = getMetricTotals(bin.records, scores);
  const assetCount = new Set(totals.assetIds).size;
  const { count, spend } = totals;

  return {
    totalAssets: {
      concise: toConciseCount(assetCount),
      label: toCount(assetCount),
      value: assetCount,
    },
    totalPosts: {
      concise: toConciseCount(count),
      label: toCount(count),
      value: count,
    },
    totalSpend: {
      concise: toConciseSpend(spend),
      label: toSpend(spend),
      value: spend,
    },
    adoptionRate: getGuidelineBinScoreMetrics(bin.records),
    ...getBinScoreMetrics(totals, scores),
  };
}

export function getBinItems(bins, segments, scores) {
  return Object.values(bins).map((bin, index) => ({
    index: { value: index + 1 },
    id: { value: bin.tag },
    ...getBinSegments(bin, segments),
    ...getBinMetrics(bin, scores),
  }));
}
