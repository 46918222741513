import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
// TODO: update Tabs to TableTabs once they are in the DS
import {
  AddNewButton,
  Card,
  Divider,
  Search,
  Tabs,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import DeleteButton from 'components/atoms/DeleteButton';
import { parseGuidelineTypeLabel } from 'components/internal/guidelines/GuidelineDisplay';
import { findRoleOption } from 'components/internal/guidelines/GuidelineForm';
import {
  NULL_VALUE,
  emptyState,
  filterProps,
  getBoolDimension,
} from 'components/internal/shared';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { saveFilterQuery } from 'utilities/filtering';
import {
  isDefined,
  getItemSortBy,
} from 'utilities/item';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { searchItems } from 'utilities/itemSearch';
import { objectGroupBy } from 'utilities/object';
import { destroy } from 'utilities/requests';
import {
  internalGuidelinePath,
  internalGuidelineSubscriptionsPath,
  newInternalGuidelinePath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';
import {
  getPage,
  getParams,
  setParam,
} from 'utilities/url';

const guidelineProps = PropTypes.shape({
  applicability: PropTypes.string.isRequired,
  brandCue: PropTypes.bool.isRequired,
  definitionCount: PropTypes.number.isRequired,
  global: PropTypes.bool.isRequired,
  guidelineType: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  organizationOrder: PropTypes.number.isRequired,
  standard: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  subscriptionCount: PropTypes.number.isRequired,
});

const propTypes = {
  guidelines: PropTypes.arrayOf(guidelineProps).isRequired,
  filterSelections: filterProps,
};

const defaultProps = {
  filterSelections: {},
};

const headers = [
  {
    key: 'organizationOrder',
    label: 'Position',
  },
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'definitionCount',
    label: 'Definitions',
  },
  {
    key: 'subscriptionCount',
    label: 'Subscriptions',
  },
  {
    key: 'guidelineRole',
    label: 'Guideline Role',
  },
  {
    key: 'guidelineType',
    label: 'Guideline Type',
  },
  {
    key: 'applicability',
    label: 'Applicability',
  },
  {
    key: 'delete',
    label: '',
    sortable: false,
  },
];

const filterDimensions = [
  {
    value: 'applicability',
    label: 'Applicability',
  },
  {
    value: 'branded',
    label: 'Branded',
  },
  {
    value: 'guidelineType',
    label: 'Guideline Type',
  },
  {
    value: 'guidelineRole',
    label: 'Guideline Role',
  },
  {
    value: 'paid',
    label: 'Usage Reporting',
  },
];

const searchKeys = [
  'id',
  'name',
];

const sortDefault = {
  key: 'organizationOrder',
  asc: true,
};

const applicabilityLabels = {
  all: 'All',
  audit: 'In-Flight',
  pretest: 'Pre-Flight',
};

function getGuidelineRoleDimension(standard, brandCue) {
  const option = findRoleOption({
    brandCue,
    standard,
  });

  return {
    label: option.label,
    value: option.value,
  };
}

function getItems(guidelines, handleDelete) {
  return guidelines.map(({
    applicability,
    brandCue,
    branded,
    definitionCount,
    id,
    guidelineType,
    name,
    organizationOrder,
    paid,
    standard,
    state,
    subscriptionCount,
  }) => ({
    applicability: {
      label: applicabilityLabels[applicability],
      value: applicability,
    },
    branded: getBoolDimension(branded, true),
    definitionCount: { value: definitionCount },
    delete: {
      element: (
        <DeleteButton
          disabled={subscriptionCount !== 0}
          onClick={() => handleDelete(id)}
        />
      ),
      value: NULL_VALUE,
    },
    guidelineRole: getGuidelineRoleDimension(standard, brandCue),
    guidelineType: {
      label: parseGuidelineTypeLabel(guidelineType),
      value: guidelineType,
    },
    id: { value: id },
    name: {
      element: <a href={internalGuidelinePath(id)}>{ name }</a>,
      value: name,
    },
    organizationOrder: { value: organizationOrder },
    paid: getBoolDimension(paid, true),
    state: { value: state },
    subscriptionCount: {
      element: <a href={internalGuidelineSubscriptionsPath(id)}>{ subscriptionCount }</a>,
      value: subscriptionCount,
    },
  }));
}

function getTabs(itemsByState, setSelectedTab) {
  const states = ['active', 'editing', 'trial'];

  return states.map((value) => ({
    label: `${titleize(value)} (${itemsByState[value]?.length ?? 0})`,
    value,
    onClick: () => setSelectedTab(value),
  }));
}

function Guidelines({
  guidelines,
  filterSelections: initialFilterSelections,
}) {
  const params = getParams(window);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [search, setSearch] = useState('');
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useLocalStorage('cxIntGuidelineTab', 'active');
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const [sort, setSort] = useState(sortDefault);
  const [page, setPage] = useState(getPage(params));

  const handleDelete = async (id) => {
    const { isError } = await destroy(internalGuidelinePath(id));

    if (!isError) {
      addToast('Guideline deleted');
    }
    window.location.reload();
  };

  const items = useMemo(() => (
    getItems(guidelines, setConfirmDeleteId)
  ), [guidelines]);
  const filterOptions = useMemo(() => (
    getValueOptions(filterDimensions, items)
  ), [items]);

  const filteredItems = useMemo(() => (
    filterItems(items, filterSelections)
  ), [items, filterSelections]);

  const searchedItems = useMemo(() => {
    setPage(1);
    return searchItems(filteredItems, search, searchKeys);
  }, [filteredItems, search]);

  const tabbedItems = useMemo(() => {
    const itemsByState = objectGroupBy(searchedItems, (item) => item.state.value);
    setTabs(getTabs(itemsByState, setSelectedTab));

    return itemsByState;
  }, [searchedItems]);

  const sortedItems = useMemo(() => {
    const tabItems = tabbedItems[selectedTab]?.slice() ?? [];
    const byKeyDir = getItemSortBy(sort.key, sort.asc);

    return tabItems.sort(byKeyDir);
  }, [tabbedItems, selectedTab, sort]);

  const handleAdd = () => {
    setSelectedTab('editing');
    window.location.replace(newInternalGuidelinePath());
  };

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  const getTabLabel = (selected) => (
    tabs.find(({ value }) => value === selected)?.label
  );

  return (
    <>
      <Card className="u-flexColumn u-gap-24">
        <h5>Template Guidelines</h5>
        <Divider />
        <div className="u-flexColumn u-gap-16">
          <div className="u-flexRow u-justifyBetween u-alignEnd u-gap-16">
            <div className="u-flexRow u-alignCenter u-gap-8">
              <ItemsFilter
                dimensions={filterDimensions}
                isOpen={filterOpen}
                options={filterOptions}
                selections={filterSelections}
                onClose={() => setFilterOpen(false)}
                onOpen={() => setFilterOpen(true)}
                onSelect={handleFilterSelect}
              />
              <FilterTags
                dimensions={filterDimensions}
                selections={filterSelections}
                onClick={() => setFilterOpen(true)}
                onRemove={handleFilterSelect}
              />
            </div>
            <AddNewButton
              label="Add Guideline"
              onClick={handleAdd}
            />
          </div>
          <div>
            <Search
              placeholder="Search by Name, ID"
              value={search}
              onChange={setSearch}
            />
          </div>
        </div>
        <div>
          <Tabs
            currentTab={getTabLabel(selectedTab)}
            tabs={tabs}
            variant="button"
            showSingleTab
          />
          <ItemsTable
            className="u-scrollShadowRight"
            emptyTableContent={emptyState}
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={setPage}
            onSortChange={setSort}
          />
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isDefined(confirmDeleteId)}
        message="Are you sure you want to delete this guideline?"
        title="Confirm Guideline Delete"
        onClose={() => setConfirmDeleteId(null)}
        onConfirm={() => handleDelete(confirmDeleteId)}
      />
    </>
  );
}

Guidelines.propTypes = propTypes;
Guidelines.defaultProps = defaultProps;

export default Guidelines;
