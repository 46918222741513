import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from '@makeably/creativex-design-system';
import {
  getItem,
  queryProps,
  resultProps,
} from 'components/internal/ErrorQuery';
import { emptyState } from 'components/internal/shared';
import ItemsTable from 'components/molecules/ItemsTable';
import { addErrorToast } from 'components/organisms/Toasts';
import { getItemSortBy } from 'utilities/item';
import { get } from 'utilities/requests';
import { runInternalErrorQueryPath } from 'utilities/routes';
import {
  getPage,
  getParams,
} from 'utilities/url';

const propTypes = {
  queries: PropTypes.arrayOf(queryProps).isRequired,
  results: PropTypes.objectOf(resultProps).isRequired,
};

const headers = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Results',
    key: 'results',
  },
  {
    label: 'Last Run',
    key: 'lastRun',
  },
  {
    label: 'Autorun',
    key: 'autorun',
  },
  {
    label: '',
    key: 'run',
    sortable: false,
  },
];

function ErrorQueries({
  queries,
  results: initialResults,
}) {
  const params = getParams(window);
  const [sort, setSort] = useState({
    key: 'status',
    asc: false,
  });
  const [page, setPage] = useState(getPage(params));
  const [results, setResults] = useState(initialResults);
  const [running, setRunning] = useState(null);

  const handleRun = async (key) => {
    if (key) {
      setRunning(key);
      const { data, isError } = await get(runInternalErrorQueryPath(key));

      if (isError) {
        addErrorToast('Could not run query. Please try again later');
      } else {
        setResults((last) => ({
          ...last,
          [key]: data,
        }));
      }
      setRunning(null);
    }
  };

  useEffect(() => {
    const nextQuery = queries.find(({ key, autorun }) => autorun && !results[key]);
    if (nextQuery) {
      handleRun(nextQuery.key);
    }
  }, [queries, results]);

  const items = useMemo(() => (
    queries.map((query) => getItem(query, results, handleRun, running))
  ), [queries, results, handleRun, running]);
  const sortedItems = useMemo(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    return items.slice().sort(byKeyDir);
  }, [items, sort]);

  return (
    <Card>
      <ItemsTable
        className="u-scrollShadowRight"
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
    </Card>
  );
}

ErrorQueries.propTypes = propTypes;

export default ErrorQueries;
