import React, {
  useState,
  useEffect,
} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Spinner,
} from '@makeably/creativex-design-system';
import GuidelineFilter from 'components/admin/review_queue/GuidelineFilter';
import Accordion from 'components/atoms/Accordion';
import LinkButton from 'components/atoms/LinkButton';
import SupportLink from 'components/atoms/SupportLink';
import TabButton from 'components/atoms/TabButton';
import Guideline from 'components/rules/guidelines/Guideline';
import GuidelinesContext from 'components/rules/guidelines/GuidelinesContext';
import { get } from 'utilities/requests';
import {
  apiAdFormatsPath,
  apiBrandsPath,
  apiChannelsPath,
  apiContentTypesPath,
  apiGuidelinesPath,
  apiMarketsPath,
  eligibilityApiChannelsPath,
  guidelinesUrl,
} from 'utilities/routes';
import {
  splitSnakeCase,
  titleize,
} from 'utilities/string';
import { getParams } from 'utilities/url';
import styles from './Guidelines.module.css';

const ALL_VALUE = 'All';
const ACTIVE = 'active';
const INACTIVE = 'inactive';
const GUIDELINE_TYPE = 'guideline';
const FILTER_DIMENSIONS = [
  {
    key: 'ad_formats',
    label: 'Ad Formats',
    value: 'adFormats',
  },
  {
    key: 'asset_types',
    label: 'Asset Type',
    value: 'assetTypes',
  },
  {
    key: 'brands',
    label: 'Brand',
    value: 'brands',
  },
  {
    key: 'benchmarks',
    label: 'Benchmarks Applicability',
    value: 'benchmarks',
  },
  {
    key: 'campaign_objectives',
    label: 'Campaign Objective',
    value: 'campaignObjectives',
  },
  {
    key: 'campaign_status',
    label: 'Campaign Status',
    value: 'campaignStatus',
  },
  {
    key: 'channels',
    label: 'Channel',
    value: 'channels',
  },
  {
    key: 'content_types',
    label: 'Content Types',
    value: 'contentTypes',
  },
  {
    key: 'guidelines',
    label: 'Guideline',
    value: 'guidelines',
  },
  {
    key: 'markets',
    label: 'Market',
    value: 'markets',
  },
  {
    key: 'placements',
    label: 'Placement',
    value: 'placements',
  },
  {
    key: 'publishers',
    label: 'Publisher',
    value: 'publishers',
  },
  {
    key: 'scores',
    label: 'Score',
    value: 'scores',
  },
  {
    // The definitions filter segment is hidden in the modal.
    // It's only use is for applying a filter on page load based on url params.
    // The selected value can be cleared still, but new ones cannot be selected directly.
    key: 'definitions',
    hidden: true,
    label: 'Definition',
    value: 'definitions',
  },
];

const ASSET_TYPE_FILTER_OPTIONS = [
  {
    label: 'Image',
    value: 'image',
  },
  {
    label: 'Video',
    value: 'video',
  },
];

const BENCHMARKS_FILTER_OPTIONS = [
  {
    label: 'Benchmarks',
    value: true,
  },
  {
    label: 'Non-Benchmarks',
    value: false,
  },
];

const FIELD_ACCESSORS = {
  adFormats: (definition) => definition.eligibility.auditPost?.adFormats,
  assetTypes: (definition) => definition.eligibility.auditAsset?.creativeType,
  placements: (definition) => definition.eligibility.auditPost?.placements,
  brands: (definition) => definition?.eligibility.audit?.brands,
  campaignObjectives: (definition) => definition.eligibility.auditPost?.campaignObjectives,
  contentTypes: (definition) => definition.eligibility.auditPost?.contentTypes,
  channels: (definition) => definition.eligibility.audit?.channels,
  markets: (definition) => definition.eligibility.audit?.markets,
  publishers: (definition) => definition.eligibility.auditPost?.publishers,
  definitions: (definition) => definition.id.toString(),
};

const propTypes = {
  featureAccess: PropTypes.shape({
    isBenchmarksEnabled: PropTypes.bool,
    isCqEnabled: PropTypes.bool,
  }).isRequired,
};

const getGuidelines = async () => {
  const response = await get(apiGuidelinesPath());
  return response.data.data.items;
};

const getChannels = async () => {
  const response = await get(apiChannelsPath());
  return response.data.data.items;
};

const getChannelEligibilityMap = async () => {
  const response = await get(eligibilityApiChannelsPath());
  return response.data.data;
};

const getBrands = async () => {
  const response = await get(apiBrandsPath());
  return response.data.data.items;
};

const getMarkets = async () => {
  const response = await get(apiMarketsPath());
  return response.data.data.items;
};

const getAdFormats = async () => {
  const response = await get(apiAdFormatsPath());
  return response.data.data.items;
};

const getContentTypes = async () => {
  const response = await get(apiContentTypesPath());
  return response.data.data.items;
};

const getDetailChannels = (detail, allChannels) => {
  if (detail.eligibility.audit.channels.includes(ALL_VALUE)) {
    return allChannels;
  }

  return detail.eligibility.audit.channels.reduce((channels, key) => {
    const found = allChannels.find((channel) => channel.value === key);
    return found ? [...channels, found] : channels;
  }, []);
};

// Adds the channels found in definitions to the root guideline.
// Also, filters definitions based on the guideline's status.
const addChannelsToGuideline = (guideline, allChannels, isActive) => {
  const guidelineChannels = new Set();

  const guidelineDetails = guideline.guidelineDetails.reduce((details, guidelineDetail) => {
    const detailChannels = getDetailChannels(guidelineDetail, allChannels);

    if (detailChannels.length === 0) {
      return details;
    }

    // For non-retired guidelines, only show active channels at the top
    if (!isActive || guidelineDetail.state === ACTIVE) {
      detailChannels.forEach((detailChannel) => guidelineChannels.add(detailChannel));
    }

    return [
      ...details,
      {
        ...guidelineDetail,
        channels: detailChannels,
      },
    ];
  }, []);

  return {
    ...guideline,
    guidelineDetails,
    channels: Array.from(guidelineChannels),
  };
};

const placementToDisplayValue = (placement) => titleize(
  `${splitSnakeCase(placement.publisher)} - ${splitSnakeCase(placement.placement)}`,
);

// Maps placements to display values except when "All" is at the root.
const placementValuesTransformer = (placements) => {
  if (placements.length === 1 && placements[0] === ALL_VALUE) {
    return placements;
  }

  return placements.map(placementToDisplayValue);
};

// Converts channel => values map into filter options
const getFilterOptionsFromChannelEligibility = (
  channelToValuesMap,
  valueFn = (i) => i,
) => {
  const flattenedValues = [...new Set(
    Object.keys(channelToValuesMap).reduce(
      (values, channel) => [
        ...values,
        ...channelToValuesMap[channel].map(valueFn),
      ],
      [],
    ),
  )];

  return flattenedValues.map((value) => ({
    label: titleize(splitSnakeCase(value)),
    value,
  }));
};

// Filter out definitions, remove guidelines with no definitions left
function filterGuidelineDefinitions({
  guidelines,
  // Accessor fn for the field
  fieldAccessor,
  selectedOptions,
  // Optional "All" value replacements (channel => values)
  defaultValues,
  // Optional value transformer for converting items to their string
  // representation for comparison (filtersOption values are strings)
  valueTransformer = (i) => i,
}) {
  const isDefinitionMatch = (definition) => {
    // Get the value(s) at the path.
    // For undefined, default to All; this only applies to assetType in practice.
    const values = valueTransformer(fieldAccessor(definition) || ALL_VALUE);

    if ((Array.isArray(values) && values.includes(ALL_VALUE)) || values === ALL_VALUE) {
      // If "All" is present on eligibility fields, it does not mean "All" possible values.
      // Each channel has a subset of values that actually apply.
      if (defaultValues) {
        return definition.channels.some(
          (channel) => selectedOptions.some(
            (filterSelection) => valueTransformer(defaultValues[channel.value])
              ?.includes(filterSelection.value),
          ),
        );
      }

      // For fields that aren't limited by channels in the same way, this is always a match
      return true;
    }

    if (Array.isArray(values)) {
      return selectedOptions.some(
        (filterSelection) => values.includes(filterSelection.value),
      );
    }

    // Asset type is a single value
    return selectedOptions.some(
      (filterSelection) => values === filterSelection.value,
    );
  };

  return guidelines
    .map((guideline) => ({
      ...guideline,
      guidelineDetails: guideline.guidelineDetails.filter(isDefinitionMatch),
    }))
    .filter(({ guidelineDetails }) => guidelineDetails.length > 0);
}

function filterGuidelines(guidelines, filterSelections, channelEligibilityMap) {
  return Object.entries(filterSelections)
    .reduce((remainingGuidelines, [field, selectedOptions]) => {
      switch (field) {
        // Scores, guidelines, and benchmarks applicability are guideline-level filters
        case 'scores': {
          return remainingGuidelines.filter(
            (guideline) => guideline.activeScores.some(
              (score) => selectedOptions.some(
                (filterSelection) => filterSelection.value === score.label,
              ),
            ),
          );
        }
        case 'guidelines': {
          return remainingGuidelines.filter(
            (guideline) => selectedOptions.some(
              (filterSelection) => filterSelection.value === guideline.id.toString(),
            ),
          );
        }
        case 'benchmarks': {
          return remainingGuidelines.filter(
            (guideline) => selectedOptions.some(
              (filterSelection) => filterSelection.value === guideline.standard,
            ),
          );
        }
        // assetTypes, brands, channels, contentTypes,
        // markets, and definitions work at
        case 'campaignStatus': {
          return remainingGuidelines.filter(
            (guideline) => selectedOptions.some(
              (filterSelection) => filterSelection.value === guideline.applicability || guideline.applicability === 'all',
            ),
          );
        }
        // definition-level and are not governed by channel
        case 'assetTypes':
        case 'brands':
        case 'channels':
        case 'contentTypes':
        case 'markets':
        case 'definitions':
          return filterGuidelineDefinitions({
            guidelines: remainingGuidelines,
            fieldAccessor: FIELD_ACCESSORS[field],
            selectedOptions,
          });
        // ad formats, placements, campaignObjectives, and publishers work at
        // definition-level, and ARE governed by channel
        case 'adFormats':
          return filterGuidelineDefinitions({
            guidelines: remainingGuidelines,
            fieldAccessor: FIELD_ACCESSORS.adFormats,
            selectedOptions,
            defaultValues: channelEligibilityMap.ad_formats,
          });
        case 'placements':
          return filterGuidelineDefinitions({
            guidelines: remainingGuidelines,
            fieldAccessor: FIELD_ACCESSORS.placements,
            selectedOptions,
            defaultValues: channelEligibilityMap.placements,
            // placements are objects; tell the filter how to convert to a string for comparison
            valueTransformer: placementValuesTransformer,
          });
        case 'campaignObjectives':
          return filterGuidelineDefinitions({
            guidelines: remainingGuidelines,
            fieldAccessor: FIELD_ACCESSORS.campaignObjectives,
            selectedOptions,
            defaultValues: channelEligibilityMap.campaign_objectives,
          });
        case 'publishers':
          return filterGuidelineDefinitions({
            guidelines: remainingGuidelines,
            fieldAccessor: FIELD_ACCESSORS.publishers,
            selectedOptions,
            defaultValues: channelEligibilityMap.publishers,
          });
        default:
          return remainingGuidelines;
      }
    }, guidelines);
}

function Guidelines({ featureAccess }) {
  const {
    isBenchmarksEnabled,
    isCqEnabled,
  } = featureAccess;
  const [activeGuidelines, setActiveGuidelines] = useState([]);
  const [inactiveGuidelines, setInactiveGuidelines] = useState([]);
  const [filteredActiveGuidelines, setFilteredActiveGuidelines] = useState([]);
  const [filteredInactiveGuidelines, setFilteredInactiveGuidelines] = useState([]);
  const [selectedGuideline, setSelectedGuideline] = useState(null);

  const [channelEligibilityMap, setChannelEligibilityMap] = useState(null);
  const [allBrands, setAllBrands] = useState([]);
  const [allMarkets, setAllMarkets] = useState([]);
  const [allAdFormats, setAllAdFormats] = useState([]);
  const [allContentTypes, setAllContentTypes] = useState([]);

  // Only show benchmarks applicability filter if benchmarks are enabled
  const filterDimensions = isBenchmarksEnabled ? FILTER_DIMENSIONS : FILTER_DIMENSIONS.filter(({ key }) => key !== 'benchmarks');
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const allChannels = await getChannels();

      // The ChannelLogos component expects "value" instead of "key"
      const mappedChannels = allChannels
        .map((channel) => ({
          label: channel.label,
          value: channel.key,
        }))
        .sort((channelA, channelB) => channelA.label.localeCompare(channelB.label));

      // Sort guidelines by standard flag, then alpha
      const sortStandardThenAlpha = (guidelineA, guidelineB) => {
        if (guidelineA.standard) {
          if (!guidelineB.standard) {
            return -1;
          }
        } else if (guidelineB.standard) {
          return 1;
        }

        return guidelineA.name.localeCompare(guidelineB.name);
      };

      const allGuidelines = await getGuidelines();
      const guidelines = allGuidelines.filter((guideline) => guideline.ruleType === GUIDELINE_TYPE);
      const guidelinesByState = Object.groupBy(guidelines, ({ guidelineDetails }) => (
        guidelineDetails.some(({ state }) => state === ACTIVE) ? ACTIVE : INACTIVE
      ));
      const activeWithChannels = guidelinesByState.active?.map(
        (guideline) => addChannelsToGuideline(guideline, mappedChannels, true),
      ).sort(sortStandardThenAlpha);
      const inactiveWithChannels = guidelinesByState.inactive?.map(
        (guideline) => addChannelsToGuideline(guideline, mappedChannels, false),
      ).sort(sortStandardThenAlpha);

      setActiveGuidelines(activeWithChannels || []);
      setInactiveGuidelines(inactiveWithChannels || []);

      if (activeWithChannels) {
        setSelectedGuideline(activeWithChannels[0]);
      } else if (inactiveWithChannels) {
        setSelectedGuideline(inactiveWithChannels[0]);
      }

      const eligibilityMap = await getChannelEligibilityMap();
      setChannelEligibilityMap(eligibilityMap);

      const brands = await getBrands();
      setAllBrands(brands.map(({ name }) => name));

      const markets = await getMarkets();
      setAllMarkets(markets.map(({ name }) => name));

      const adFormats = await getAdFormats();
      setAllAdFormats(adFormats.map(({ label }) => label));

      const contentTypes = await getContentTypes();
      setAllContentTypes(contentTypes.map(({ label }) => label));

      // Construct filter options
      setFilterOptions({
        adFormats: adFormats.map((adFormat) => ({
          label: adFormat.label,
          value: adFormat.key,
        })),
        assetTypes: ASSET_TYPE_FILTER_OPTIONS,
        contentTypes,
        campaignStatus: [
          {
            label: 'In-Flight',
            value: 'audit',
          },
          {
            label: 'Pre-Flight',
            value: 'preflight',
          },
        ],
        brands: brands.map((brand) => ({
          label: brand.name,
          value: brand.name,
        })),
        channels: mappedChannels,
        markets: markets.map((market) => ({
          label: market.name,
          value: market.name,
        })),
        // Scores, guideline (ids), and benchmarks applicability are at the guideline-level
        scores: [...new Set(
          allGuidelines.reduce((scores, guideline) => [
            ...scores,
            ...guideline.activeScores.map((score) => score.label),
          ], []),
        )].map((score) => ({
          label: score,
          value: score,
        })),
        guidelines: allGuidelines.map((guideline) => ({
          label: guideline.name,
          value: guideline.id.toString(),
        })),
        benchmarks: BENCHMARKS_FILTER_OPTIONS,
        // definitions are at the definition-level
        definitions: allGuidelines.reduce((allDefinitions, guideline) => [
          ...allDefinitions,
          ...guideline.guidelineDetails.map((definition) => ({
            label: definition.description,
            value: definition.id.toString(),
          }))], []),
        // campaignObjectives, placements, and publishers can be obtained from the eligibility map
        campaignObjectives: getFilterOptionsFromChannelEligibility(
          eligibilityMap.campaign_objectives,
        ),
        placements: getFilterOptionsFromChannelEligibility(
          eligibilityMap.placements,
          // Placements are objects, not strings. Pass in a fn to convert them.
          placementToDisplayValue,
        ),
        publishers: getFilterOptionsFromChannelEligibility(
          eligibilityMap.publishers,
        ),
      });

      // For direct links to specific guidelines/definitions, set initial filters
      const params = getParams(window);
      const guidelineId = params.get('guideline');
      const definitionId = params.get('definition');
      const benchmarks = params.get('benchmarks');
      const channelId = params.get('channel');
      const brandName = params.get('brand');
      const marketName = params.get('market');
      const assetType = params.get('assetType');
      const campaignStatus = params.get('campaignStatus');
      const placements = params.get('placements');

      const initialFilters = {};

      if (guidelineId) {
        const matchingGuideline = allGuidelines.find(
          (guideline) => guideline.id.toString() === guidelineId,
        );

        if (matchingGuideline) {
          initialFilters.guidelines = [{
            value: guidelineId,
            label: matchingGuideline?.name || `Guideline: ${guidelineId}`,
          }];
        }
      }

      if (definitionId) {
        const matchingDefinition = allGuidelines.reduce(
          (foundDefinition, guideline) => foundDefinition
            || guideline.guidelineDetails.find(
              (definition) => definition.id.toString() === definitionId,
            ),
          null,
        );

        initialFilters.definitions = [{
          value: definitionId,
          label: matchingDefinition?.description || `Definition: ${definitionId}`,
        }];
      }

      if (benchmarks) {
        initialFilters.benchmarks = [{
          value: Boolean(benchmarks),
          label: benchmarks ? 'Benchmarks' : 'Non-Benchmarks',
        }];
      }

      if (channelId) {
        const matchingChannel = allChannels.find(
          (channel) => channel.key === channelId,
        );

        if (matchingChannel) {
          initialFilters.channels = [{
            value: channelId,
            label: matchingChannel?.label || `Channel: ${channelId}`,
          }];
        }
      }

      if (brandName) {
        initialFilters.brands = [{
          value: brandName,
          label: brandName,
        }];
      }

      if (marketName) {
        initialFilters.markets = [{
          value: marketName,
          label: marketName,
        }];
      }

      if (assetType) {
        initialFilters.contentTypes = [{
          value: assetType,
          label: titleize(assetType),
        }];
      }

      if (campaignStatus) {
        initialFilters.campaignStatus = [{
          value: campaignStatus,
          label: campaignStatus === 'audit' ? 'In-Flight' : 'Pre-Flight',
        }];
      }

      if (placements) {
        initialFilters.placements = [...placements.split(',').map((placement) => ({
          value: placement,
          label: placement,
        }))];
      }

      setFilterSelections(initialFilters);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    // Apply filters
    const filteredActive = filterGuidelines(
      activeGuidelines,
      filterSelections,
      channelEligibilityMap,
    );
    const filteredInactive = filterGuidelines(
      inactiveGuidelines,
      filterSelections,
      channelEligibilityMap,
    );

    setFilteredActiveGuidelines(filteredActive);
    setFilteredInactiveGuidelines(filteredInactive);

    if (filteredActive) {
      setSelectedGuideline(filteredActive[0]);
    } else if (filteredInactive) {
      setSelectedGuideline(filteredInactive[0]);
    } else {
      setSelectedGuideline(null);
    }
  }, [filterSelections, activeGuidelines, inactiveGuidelines]);

  useEffect(() => {
    if (!loading) {
      // If the page was loaded with a guideline/definition filter and that filter is removed,
      // make sure to reset the page url so refreshes don't reapply it.
      const params = getParams(window);
      const guidelineId = params.get('guideline');
      const definitionId = params.get('definition');

      const guidelineFilterRemoved = guidelineId
        && (!filterSelections.guidelines
          || filterSelections.guidelines.length !== 1
          || filterSelections.guidelines[0].value !== guidelineId);

      const definitionFilterRemoved = definitionId
        && (!filterSelections.definitions
          || filterSelections.definitions.length !== 1
          || filterSelections.definitions[0].value !== definitionId);

      if (guidelineFilterRemoved || definitionFilterRemoved) {
        window.history.replaceState(null, '', guidelinesUrl());
      }
    }
  }, [filterSelections]);

  const renderTabSection = (sectionName, guidelines, initiallyExpanded) => (
    <div className={styles.tabSection}>
      <Accordion
        header={(
          <h5>
            { `${sectionName} (${guidelines.length})` }
          </h5>
        )}
        initiallyExpanded={initiallyExpanded}
      >
        {
          guidelines.map((guideline) => (
            <div key={guideline.id} className="u-marginBottom-16">
              <TabButton
                icon={(isBenchmarksEnabled || isCqEnabled) && guideline.standard ? 'cxIcon' : ''}
                label={guideline.name}
                selected={guideline === selectedGuideline}
                onClick={() => setSelectedGuideline(guideline)}
              />
            </div>
          ))
        }
      </Accordion>
    </div>
  );

  const renderGuidelineSection = () => {
    if (activeGuidelines.length === 0 && inactiveGuidelines.length === 0) {
      return (
        <div className={styles.cardMargin}>
          <h5 className="u-marginBottom-8">No Guidelines Added</h5>
          <div className="t-subtitle u-marginBottom-16">
            { 'Please reach out to ' }
            <SupportLink />
            { ' to begin' }
          </div>
        </div>
      );
    }

    if (filteredActiveGuidelines.length === 0 && filteredInactiveGuidelines.length === 0) {
      return (
        <div className={styles.cardMargin}>
          <h5 className="u-marginBottom-8">No Results Found</h5>
          <div className="t-subtitle u-marginBottom-16">
            { 'Please broaden your search or ' }
            <LinkButton onClick={() => setFilterSelections({})}>Clear Filters</LinkButton>
          </div>
        </div>
      );
    }

    if (selectedGuideline) {
      return (
        <Guideline guideline={selectedGuideline} />
      );
    }

    return null;
  };

  if (loading) {
    return <Spinner />;
  }

  const context = {
    allBrands,
    allContentTypes,
    allMarkets,
    channelEligibilityMap,
    featureAccess,
    allAdFormats,
  };

  return (
    <GuidelinesContext.Provider value={context}>
      <Card className={styles.fixedCardHeight} padding={false}>
        <div className={styles.cardMargin}>
          <GuidelineFilter
            dimensions={filterDimensions}
            isOpen={filterOpen}
            options={filterOptions}
            selections={filterSelections}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelect={(selections) => setFilterSelections(selections)}
          />
        </div>
        <Divider />
        <div className={classnames('u-flexRow', styles.fullHeight, styles.subCard)}>
          <div className={classnames('u-col-4', styles.cardMargin, styles.scrollable)}>
            { renderTabSection('Guidelines', filteredActiveGuidelines, true) }
            { filteredInactiveGuidelines.length > 0 && (
              renderTabSection(
                'Inactive Guidelines',
                filteredInactiveGuidelines,
                filteredActiveGuidelines.length === 0,
              )
            ) }
          </div>
          <div><Divider vertical /></div>
          <div className="u-flexGrow">
            { renderGuidelineSection() }
          </div>
        </div>
      </Card>
    </GuidelinesContext.Provider>
  );
}

Guidelines.propTypes = propTypes;

export default Guidelines;
