import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import {
  Dropdown,
  MessageModal,
} from '@makeably/creativex-design-system';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  valuesPerCustomDimension: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      id: PropTypes.number,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  campaignCustomDimensions: PropTypes.arrayOf(PropTypes.string),
  campaignName: PropTypes.string,
};

const defaultProps = {
  campaignCustomDimensions: [],
  campaignName: '',
};

function getCopy(isConfirming) {
  if (isConfirming) {
    return {
      title: 'Confirm the below mapping',
      subtitle: '',
      actionButtonLabel: 'Confirm',
    };
  }
  return {
    title: 'Map Missing Segments',
    subtitle: 'Select the correct value from the below missing segments and click save.',
    actionButtonLabel: 'Save',
  };
}

function getCampaignDimensionsInfo(campaignCustomDimensions, mappedDimensions) {
  if (!campaignCustomDimensions) {
    return {
      campaignDimensionsMap: new Map(),
    };
  }

  const campaignDimensionsMap = new Map();

  campaignCustomDimensions.forEach((dimension) => {
    const [name, value] = dimension.split('::');
    const mappedValue = mappedDimensions[name]?.value;

    campaignDimensionsMap.set(name, {
      value: mappedValue || value,
      id: name,
      existing: !!value,
    });
  });

  return {
    campaignDimensionsMap,
  };
}

function CampaignModal({
  campaignCustomDimensions,
  campaignName,
  isOpen,
  onClose,
  onSubmit,
  valuesPerCustomDimension,
}) {
  if (!isOpen) {
    return null;
  }

  const [mappedDimensions, setMappedDimensions] = useState({});
  const [isConfirming, setIsConfirming] = useState(false);

  const { campaignDimensionsMap } = useMemo(
    () => getCampaignDimensionsInfo(campaignCustomDimensions, mappedDimensions),
    [campaignCustomDimensions, mappedDimensions],
  );
  const copy = useMemo(() => getCopy(isConfirming), [isConfirming]);

  const buttonDisabled = Object.keys(mappedDimensions).length === 0;

  const handleValueChange = (dimension, dimensionId, value) => {
    setMappedDimensions((prev) => ({
      ...prev,
      [dimension]: {
        dimensionId,
        value,
      },
    }));
  };

  const handleCancel = () => {
    setMappedDimensions({});
    onClose();
  };

  const handleActionButtonClick = () => {
    if (isConfirming) {
      onSubmit(mappedDimensions);
      setMappedDimensions({});
      setIsConfirming(false);
    } else {
      setIsConfirming(true);
    }
  };

  const dropdowns = () => {
    if (isConfirming) {
      return null;
    }

    const dimensions = Object.keys(valuesPerCustomDimension).sort();

    return dimensions.map((dimension) => {
      const value = campaignDimensionsMap.get(dimension).value || '';
      const { values, id } = valuesPerCustomDimension[dimension];
      const valueOptions = values.map((val) => ({
        label: val,
        value: val,
      }));

      return (
        <Dropdown
          key={id}
          disabled={campaignDimensionsMap.get(dimension).existing}
          label={dimension}
          menuProps={{ size: 'medium' }}
          options={valueOptions}
          selected={valueOptions.find((option) => option.value === value)}
          size="medium"
          onChange={(val) => handleValueChange(dimension, id, val.value)}
        />
      );
    });
  };

  return (
    <FocusLock disabled={!isOpen} returnFocus>
      <MessageModal
        actionButtonDisabled={buttonDisabled}
        actionButtonLabel={copy.actionButtonLabel}
        isOpen={isOpen}
        subtitle={copy.subtitle}
        title={copy.title}
        onActionButtonClick={handleActionButtonClick}
        onClose={handleCancel}
      >
        <div className="u-flexColumn u-gap-8">
          { isConfirming && (
            <>
              <div className="t-subtitle">
                Campaign Name:
              </div>
              <div className="t-body-2 u-marginBottom-16">
                { campaignName }
              </div>
              { Object.keys(mappedDimensions).map((dimension) => (
                <React.Fragment key={dimension}>
                  <div className="t-subtitle">
                    { `${dimension}:` }
                  </div>
                  <div className="t-body-2">
                    { mappedDimensions[dimension].value }
                  </div>
                </React.Fragment>
              )) }
            </>
          ) }
          { dropdowns() }
        </div>
      </MessageModal>
    </FocusLock>
  );
}

CampaignModal.propTypes = propTypes;
CampaignModal.defaultProps = defaultProps;

export default CampaignModal;
