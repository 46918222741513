import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ChannelLogo from "components/atoms/ChannelLogo"
import MixpanelTracker from "components/utils/MixpanelTracker"
import { Icon } from '@makeably/creativex-design-system'
import { auditScorecardPath } from 'utilities/routes';

# This component is responsible for displaying a clickable/static card for an audit creative
class AuditCreativeCard extends React.Component
  @propTypes =
    creative: PropTypes.shape(
      isVideo: PropTypes.bool
      url: PropTypes.string
    )
    id: PropTypes.string.isRequired
    assetId: PropTypes.string
    quality: PropTypes.shape(
      lowestRank: PropTypes.string
      maxScore: PropTypes.string
      minScore: PropTypes.string
    )
    regulatory: PropTypes.shape(
      rating: PropTypes.string
    )
    usage: PropTypes.shape(
      channels: PropTypes.arrayOf( PropTypes.string )
      count: PropTypes.number
      pretest: PropTypes.bool
    ),
    filterQueryId: PropTypes.string
    page: PropTypes.number

  renderScore: (quality) ->
    return unless quality.maxScore

    score = FormatUtil.metricFormat('audit_score', quality.maxScore)
    if quality.minScore
      score = "#{score} - #{FormatUtil.metricFormat('audit_score', quality.minScore)}"

    scoreClasses = classNames(
      "auditScore",
      'u-marginLeft',
      "auditScore--#{quality.lowestRank}"
    )

    `<div className={scoreClasses}>{score}</div>`

  renderPretestUsage: ->
    return unless @props.contentProps.usage.pretest
    `<div className='auditScore'>Pre-Flight</div>`

  renderRegulatoryIcon: (rating) ->
    return if rating == 'unavailable'

    if rating is 'mixed'
      icon = 'exclamationCircle'
      color = 'orange'
    else if rating is 'compliant'
      icon = 'checkCircle'
      color = 'green'
    else if rating is 'noncompliant'
      icon = 'xCircle'
      color = 'red'

    `<Icon name={icon} color={color} />`

  renderRegulatorySection: ->
    regulatory = @props.contentProps.regulatory
    return unless regulatory

    `<div className='u-flexRow u-flexAlignCenter'>
      <Icon name='clipboard' />
      <div className='complianceScore u-marginLeft'>
        {this.renderRegulatoryIcon(regulatory.rating)}
        {this.renderPretestUsage()}
      </div>
    </div>`

  renderAuditScore: ->
    quality = @props.contentProps.quality
    return unless quality

    `<div className='auditScore-container u-flexAlignCenter'>
      <Icon name='thumbsUp' />
      {this.renderScore(quality)}
      {this.renderPretestUsage()}
    </div>`

  renderChannel: (channel, idx) ->
    `<ChannelLogo
      key={idx}
      channel={channel}
      size={'size-24'}
    />`

  renderChannels: ->
    return unless @props.contentProps.usage.channels

    `<div className='u-flexRow u-spaceBetween u-marginAboveSm'>
      <div className='auditCreative-channels'>
        {this.props.contentProps.usage.channels.map(this.renderChannel)}
      </div>
    </div>`

  renderUsageCount: ->
    return unless @props.contentProps.usage.count > 1
    `<div className='auditCreative-usageCount'>{this.props.contentProps.usage.count}</div>`

  render: ->
    imageAlt = "creativex_id_#{@props.contentProps.id}"
    imageContainerClasses = classNames(
      'auditCreative-imageContainer',
      'auditCreative-imageContainer--video': @props.contentProps.creative.isVideo
    )

    if @props.contentProps.creative.isVideo
      videoIcon =
        `<div className='videoIconContainer'>
          <Icon name='play' />
        </div>`

    auditScoreSection = this.renderAuditScore()
    regulatorySection = this.renderRegulatorySection()

    creativeCardClasses = classNames(
      'auditCreative-card',
      'auditCreative-card--lg': auditScoreSection && regulatorySection
    )
  
    detailsClasses = classNames(
      'auditCreative-details',
      'auditCreative-details--lg': auditScoreSection && regulatorySection
    )

    url = auditScorecardPath(@props.contentProps.assetId, {
      include_matched_posts: true,
      filter_query: @props.contentProps.filterQueryId,
      page: @props.contentProps.page
    })

    outerDiv = if @props.contentProps.assetId
      `<a href={url} className={creativeCardClasses} id={this.props.contentProps.id}>
        <div className={imageContainerClasses}>
          {this.renderUsageCount()}
          { videoIcon }
          <img className='auditCreative-image' src={this.props.contentProps.creative.url} alt={imageAlt} />
        </div>
        <div className={detailsClasses}>
          {auditScoreSection}
          {regulatorySection}
          {this.renderChannels()}
        </div>
      </a>`
    else
      `<div className={creativeCardClasses}  id={this.props.contentProps.id}>
        <div className={imageContainerClasses}>
          {this.renderUsageCount()}
          { videoIcon }
          <img className='auditCreative-image' src={this.props.contentProps.creative.url} alt={imageAlt} />
        </div>
        <div className={detailsClasses}>
          {auditScoreSection}
          {regulatorySection}
          {this.renderChannels()}
        </div>
      </div>`

    outerDiv

export default AuditCreativeCard
