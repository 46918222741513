import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Button,
  Card,
  Divider,
  Dropdown,
  RadioGroup,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import { clientProps } from 'components/internal/connections/NewGoogleAdsAccount';
import {
  addClientWarning,
  areValid,
  createRow,
  duplicateLastRow,
  hasContent,
  parseCsv,
} from 'components/internal/connections/shared';
import { optionArrayProps } from 'components/internal/shared';
import BulkSelect from 'components/molecules/BulkSelect';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import {
  findDuplicates,
  findObjectByValue,
  removeByIndex,
  replaceAtIndex,
  splitArray,
} from 'utilities/array';
import {
  loadTextFile,
  saveItemsCsvFile,
} from 'utilities/file';
import {
  addFormDataArray,
  post,
} from 'utilities/requests';
import { internalDV360AdAccountsPath } from 'utilities/routes';
import { removeNonDigits } from 'utilities/string';
import {
  getParams,
  redirectWithParam,
} from 'utilities/url';

const propTypes = {
  companyOptions: optionArrayProps.isRequired,
  initialLevel: PropTypes.string.isRequired,
  brandOptions: optionArrayProps,
  clients: clientProps,
  marketOptions: optionArrayProps,
  partnerOptions: optionArrayProps,
  selectedCompanyId: PropTypes.number,
  userOptions: optionArrayProps,
};

const defaultProps = {
  brandOptions: [],
  clients: [],
  marketOptions: [],
  partnerOptions: [],
  selectedCompanyId: undefined,
  userOptions: [],
};

const ADVERTISER_LEVEL = 'advertiser';
const CONNECT_BATCH_SIZE = 3;

const levelOptions = [
  {
    label: 'Advertiser',
    value: ADVERTISER_LEVEL,
  },
  {
    label: 'Campaign',
    value: 'campaign',
  },
];

const headers = [
  {
    key: 'advertiserId',
    label: 'Advertiser ID',
    type: 'text',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    alternateLabel: 'Managing Agency',
    key: 'partner',
    label: 'Partner',
  },
  {
    alternateLabel: 'Connecting User (probably you)',
    key: 'user',
    label: 'User Email',
  },
];

function getComboId({
  advertiserId,
  brand,
  market,
}) {
  return `${advertiserId}::${brand?.value}::${market?.value}`;
}

function flagErrors(connections, level) {
  if (level.value === ADVERTISER_LEVEL) {
    const advertiserIds = connections.map(({ advertiserId }) => advertiserId);
    const duplicateSet = findDuplicates(advertiserIds);
    const error = 'Advertiser IDs must be unique for Advertiser-level ad accounts';

    return connections.map((connection) => ({
      ...connection,
      error: duplicateSet.has(connection.advertiserId) ? error : null,
    }));
  }

  const ids = connections.map(getComboId);
  const duplicateSet = findDuplicates(ids);
  const error = 'Brand and market combinations must be unique for Campaign-level ad accounts with the same advertiser ID';

  return connections.map((connection) => ({
    ...connection,
    error: duplicateSet.has(getComboId(connection)) ? error : null,
  }));
}

function getFormData(selectedCompany, level, connections) {
  const converted = connections.map((connection) => ({
    affiliate_id: connection.partner.value,
    api_customer_id: removeNonDigits(connection.advertiserId),
    brand: connection.brand.label,
    level: level.value,
    market: connection.market.label,
    user_id: connection.user.value,
    uuid: connection.uuid,
  }));

  const formData = new FormData();
  formData.append('company_id', selectedCompany.value);
  addFormDataArray(formData, 'connections', converted);

  return formData;
}

async function postBatch(selectedCompany, level, batch) {
  const formData = getFormData(selectedCompany, level, batch);
  const { data, isError } = await post(internalDV360AdAccountsPath(), formData);

  if (isError) {
    const error = 'An unknown error occured. Please try again later';

    return {
      successes: [],
      failures: batch.map(({ uuid }) => ({
        error,
        uuid,
      })),
    };
  }

  return {
    successes: data.results.filter(({ created }) => created),
    failures: data.results.filter(({ created }) => !created),
  };
}

function NewDv360AdAccount({
  brandOptions,
  clients,
  companyOptions,
  marketOptions,
  partnerOptions,
  initialLevel,
  selectedCompanyId,
  userOptions,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(
    findObjectByValue(companyOptions, selectedCompanyId),
  );
  const [level, setLevel] = useState(
    findObjectByValue(levelOptions, initialLevel),
  );
  const [connections, setConnections] = useState([createRow(headers)]);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsByKey = {
    brand: brandOptions,
    market: marketOptions,
    partner: partnerOptions,
    user: userOptions,
  };
  const hasErrors = connections.some(({ error }) => Boolean(error));
  const canConnect = !hasErrors && areValid(connections, headers);

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  useEffect(() => {
    if (!selectedCompanyId && lastCompanyId) {
      const found = companyOptions.find((option) => option.value === lastCompanyId);

      if (found) {
        handleCompanyChange(found);
      }
    }
  }, [selectedCompanyId, companyOptions, lastCompanyId]);

  const handleLevelChange = (value) => {
    setLevel(findObjectByValue(levelOptions, value));
    redirectWithParam('level', value, params, window);
  };

  const handleCsvUpload = async () => {
    const csv = await loadTextFile('.csv');
    const parsed = parseCsv(csv, headers, optionsByKey);
    const checked = parsed.map((connection) => addClientWarning(connection, clients));
    const existing = connections.filter((connection) => hasContent(connection, headers));

    const toAdd = [...existing, ...checked];
    setConnections(flagErrors(toAdd, level));
    addToast(`Parsed data for ${checked.length} connection(s)`);
  };

  const handleConnectionChange = (index, key, value) => {
    setConnections((last) => {
      const updated = {
        ...last[index],
        [key]: value,
      };

      const checked = addClientWarning(updated, clients);
      const updatedConnections = replaceAtIndex(last, index, checked);
      return flagErrors(updatedConnections, level);
    });
  };

  const updateConnections = (successes, failures) => {
    setConnections((last) => (
      last.reduce((arr, connection) => {
        const success = successes.find(({ uuid }) => uuid === connection.uuid);
        const failure = failures.find(({ uuid }) => uuid === connection.uuid);

        if (failure?.error) {
          return [
            ...arr,
            {
              ...connection,
              error: failure.error,
            },
          ];
        }
        if (success) {
          return arr;
        }
        return [...arr, connection];
      }, [])
    ));
  };

  const handleConnect = async () => {
    setIsConnecting(true);

    const batches = splitArray(connections, CONNECT_BATCH_SIZE);

    const [succeeded, failed] = await batches.reduce((promise, batch) => (
      promise.then(async ([ok, bad]) => {
        const { successes, failures } = await postBatch(selectedCompany, level, batch);

        updateConnections(successes, failures);
        return [ok + successes.length, bad + failures.length];
      })), Promise.resolve([0, 0]));

    if (succeeded > 0) {
      addToast(`Connected ${succeeded} DV360 ad accounts`);
    }
    if (failed > 0) {
      addErrorToast(`Could not connect ${failed} DV360 ad accounts`);
    }

    setIsConnecting(false);
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <Dropdown
        label="Company"
        menuProps={{ size: 'medium' }}
        options={companyOptions}
        selected={selectedCompany}
        size="medium"
        onChange={handleCompanyChange}
      />
      <Divider />
      <div className="u-flexRow u-alignEnd u-justifyBetween u-gap-16">
        <RadioGroup
          label="Connection Level"
          name={level.label}
          options={levelOptions}
          value={level.value}
          onChange={(e) => handleLevelChange(e.target.value)}
        />
        <div className="u-flexRow u-gap-16">
          <Button
            label="Download CSV Template"
            variant="tertiary"
            onClick={() => saveItemsCsvFile('dv360', [], headers)}
          />
          <Button
            label="Upload CSV"
            variant="secondary"
            onClick={handleCsvUpload}
          />
        </div>
      </div>
      <BulkSelect
        disabled={isConnecting}
        headers={headers}
        optionsByKey={optionsByKey}
        rows={connections}
        onAdd={() => setConnections((last) => [...last, createRow(headers)])}
        onChange={handleConnectionChange}
        onDuplicate={() => setConnections((last) => duplicateLastRow(last, headers))}
        onRemove={(index) => setConnections((last) => (
          flagErrors(removeByIndex(last, index), level)
        ))}
      />
      <div className="u-flexRow u-justifyEnd">
        <div className="u-flexRow u-gap-8">
          <Button
            label="Reset"
            variant="secondary"
            onClick={() => setConnections([createRow(headers)])}
          />
          <ActionButton
            active={isConnecting}
            disabled={!canConnect}
            label="Connect"
            onClick={handleConnect}
          />
        </div>
      </div>
    </Card>
  );
}

NewDv360AdAccount.propTypes = propTypes;
NewDv360AdAccount.defaultProps = defaultProps;

export default NewDv360AdAccount;
