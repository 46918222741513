import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import AssetImage from 'components/molecules/AssetImage';
import { stringToInt } from 'utilities/number';
import {
  dateToString,
  toConciseSpend,
} from 'utilities/string';
import styles from './CampaignCard.module.css';

const NUM_ASSETS_PREVIEWED = 8;

export const campaignProps = PropTypes.shape({
  accountId: PropTypes.string,
  apiId: PropTypes.string,
  assets: PropTypes.arrayOf(PropTypes.string),
  channel: PropTypes.string,
  channelLabel: PropTypes.string,
  customDimensions: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number,
  name: PropTypes.string,
  spend: PropTypes.number,
  spendDate: PropTypes.string,
});

const propTypes = {
  campaign: campaignProps.isRequired,
  onAssetClick: PropTypes.func.isRequired,
  onMapSegments: PropTypes.func.isRequired,
};

function renderAsset(info, onAssetClick) {
  const [url, videoLength, videoSource] = info.split('::');

  return (
    <button
      key={info}
      type="button"
      onClick={() => onAssetClick(url, videoSource)}
    >
      <div className={styles.assetPreview}>
        <AssetImage
          alt="Asset Preview"
          src={url}
          videoLength={stringToInt(videoLength)}
        />
      </div>
    </button>
  );
}

function renderAssets(assets, onAssetClick, emptyAssets) {
  const assetCount = assets.length;

  if (assetCount === 0) {
    return emptyAssets;
  }

  const previewAssets = assets.slice(0, NUM_ASSETS_PREVIEWED);

  return (
    <div className="u-flexRow u-gap-24">
      { previewAssets.map((assetInfo) => renderAsset(assetInfo, onAssetClick)) }
    </div>
  );
}

function renderInfo(label, value) {
  return (
    <div className="u-flexRow">
      <div className="t-body-2">{ `${label}:` }</div>
      <div className={`t-body-2 ${styles.id}`}>
        { value }
      </div>
    </div>
  );
}

function CampaignCard({
  campaign,
  onAssetClick,
  onMapSegments,
}) {
  const {
    accountId,
    assets,
    channel,
    name,
    spend,
    spendDate,
  } = campaign;

  const handleMapSegments = () => {
    onMapSegments(campaign);
  };

  const emptyAssets = (
    <div className={`t-empty ${styles.empty}`}>
      This campaign has no assets to display at this time
    </div>
  );

  return (
    <Card>
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-gap-8 u-alignCenter">
          <div className={styles.logo}>
            <ChannelLogo channel={channel} size="size-24" />
          </div>
          <div className={styles.name} data-testid="campaign-name">
            { name }
          </div>
        </div>
        <Divider />
        <div className={styles.info}>
          { renderInfo('Ad Account ID', accountId) }
          { renderInfo('Date', spendDate ? dateToString(spendDate) : 'N/A') }
          { renderInfo('Spend', toConciseSpend(spend)) }
        </div>
        { renderAssets(assets, onAssetClick, emptyAssets) }
        <Divider />
        <div>
          <Button
            label="Map Missing Segments"
            onClick={handleMapSegments}
          />
        </div>
      </div>
    </Card>
  );
}

CampaignCard.propTypes = propTypes;

export default CampaignCard;
