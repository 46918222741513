export function getValuesPerCustomDimension(customDimensionsWithValues) {
  return customDimensionsWithValues.reduce((acc, dimension) => {
    const {
      id,
      name,
      value,
    } = dimension;

    if (!acc[name]) {
      acc[name] = {
        id,
        values: [value],
      };
    } else {
      acc[name].values.push(value);
    }

    return acc;
  }, {});
}
