import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Card,
} from '@makeably/creativex-design-system';
import styles from './ButtonCard.module.css';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  url: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  className: undefined,
  disabled: false,
  onClick: undefined,
  url: undefined,
};

function ButtonCard({
  children,
  className,
  disabled,
  onClick,
  url,
}) {
  const classes = classNames(
    styles.buttonCard,
    className,
  );

  const cardClasses = classNames({
    [styles.card]: !disabled,
  });

  if (url && !disabled) {
    return (
      <a
        className={classes}
        data-testid="button-card"
        href={url}
        onClick={() => onClick?.()}
      >
        <Card className={cardClasses}>
          { children }
        </Card>
      </a>
    );
  }

  return (
    <button
      className={classes}
      data-testid="button-card"
      disabled={disabled}
      type="button"
      onClick={() => onClick?.()}
    >
      <Card className={cardClasses}>
        { children }
      </Card>
    </button>
  );
}

ButtonCard.propTypes = propTypes;
ButtonCard.defaultProps = defaultProps;

export default ButtonCard;
