import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  MoreButton,
} from '@makeably/creativex-design-system';
import AssetImage from 'components/molecules/AssetImage';
import { arrayIf } from 'utilities/array';
import { stringToInt } from 'utilities/number';
import {
  editCreativeLifecycleCampaignPath,
  newCreativeLifecycleCoreAssetUploadPath,
} from 'utilities/routes';
import styles from './Campaign.module.css';

const NUM_ASSETS_PREVIEWED = 4;
const NUM_PARTNERS_PREVIEWED = 2;

export const campaignShape = {
  assets: PropTypes.arrayOf(PropTypes.string),
  brand: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  partners: PropTypes.arrayOf(PropTypes.string),
  userCreated: PropTypes.bool,
};

const propTypes = {
  campaign: PropTypes.shape(campaignShape).isRequired,
  canModify: PropTypes.bool.isRequired,
  setCampaign: PropTypes.func.isRequired,
  showDeactivateModal: PropTypes.func.isRequired,
  showEditModal: PropTypes.func.isRequired,
};

function renderAsset(info, index, assetCount) {
  const hasOverflow = index === NUM_ASSETS_PREVIEWED - 1 && assetCount > NUM_ASSETS_PREVIEWED;
  const [url, videoLength] = info.split('::');

  return (
    <div key={url} className={styles.imagePreview}>
      <AssetImage
        key={url}
        alt="Core Asset Preview"
        src={url}
        videoLength={stringToInt(videoLength)}
      />
      { hasOverflow && (
        <>
          <div className={styles.overlay} />
          <div className={classNames(styles.overlayText, 't-button-bold')}>
            { `+ ${assetCount - NUM_ASSETS_PREVIEWED}` }
          </div>
        </>
      ) }
    </div>
  );
}

function renderEmptyPreviewAssets(campaignId) {
  const newAssetLink = newCreativeLifecycleCoreAssetUploadPath({ campaign_id: campaignId });

  return (
    <div className={styles.imagePreviewContainer}>
      <div className={styles.emptyPreview}>
        <a className="t-button" href={newAssetLink}>
          <Button iconLeft="plus" label="Add Assets" variant="tertiary" />
        </a>
      </div>
    </div>
  );
}

function renderAssets(assets, campaignId, campaignUrl) {
  const assetCount = assets.length;

  if (assetCount === 0) {
    return renderEmptyPreviewAssets(campaignId);
  }

  const previewAssets = assets.slice(0, NUM_ASSETS_PREVIEWED);

  return (
    <a href={campaignUrl}>
      <div className={styles.imagePreviewContainer}>
        { previewAssets.map((assetInfo, index) => renderAsset(assetInfo, index, assetCount)) }
      </div>
    </a>
  );
}

function renderPartners(partners) {
  let partnersText = partners;

  if (partners.length > 0) {
    if (partners.length > NUM_PARTNERS_PREVIEWED) {
      const overflowPartners = `+ ${partners.length - NUM_PARTNERS_PREVIEWED} Production Partners`;
      partnersText = [...partners.slice(0, NUM_PARTNERS_PREVIEWED), overflowPartners];
    }
  } else {
    partnersText = ['No Production Partners'];
  }

  const formattedText = partnersText.reduce((arr, current) => {
    const partnerElement = <React.Fragment key={current}>{ current }</React.Fragment>;
    return [...arr, <Divider key={`divider_${current}`} vertical />, partnerElement];
  });

  const partnerContainerClasses = classNames(
    styles.partnerContainer,
    't-caption-1',
  );

  return <div className={partnerContainerClasses}>{ formattedText }</div>;
}

function Campaign({
  campaign: {
    assets,
    brand,
    id,
    name,
    partners,
  },
  canModify,
  setCampaign,
  showDeactivateModal,
  showEditModal,
}) {
  const assetCount = assets.length;
  const campaignUrl = editCreativeLifecycleCampaignPath(id);
  const menuOptions = [
    ...arrayIf(canModify, {
      label: 'Edit Campaign Name',
      onClick: () => {
        setCampaign({
          name,
          id,
        });
        showEditModal();
      },
    }),
    {
      label: 'Edit Campaign Assets',
      url: editCreativeLifecycleCampaignPath(id),
    },
    ...arrayIf(canModify, {
      label: 'Delete Campaign',
      onClick: () => {
        setCampaign({
          name,
          id,
        });
        showDeactivateModal();
      },
    }),
  ];

  return (
    <div className={styles.campaignRow}>
      <div className="u-flexRow">
        { renderAssets(assets, id, campaignUrl) }
        <div className={styles.detailsContainer}>
          <a className="t-subtitle" href={campaignUrl}>{ name }</a>
          <div className="t-body-2">{ brand }</div>
          { renderPartners(partners) }
          <div className="t-caption-1">{ `${assetCount} Core Assets` }</div>
        </div>
      </div>
      <MoreButton options={menuOptions} />
    </div>
  );
}

Campaign.propTypes = propTypes;

export default Campaign;
