// Filter button and modal Filter for filtering an array of items
// Takes the filter options, filter dimension, and current selections, along with open state
// Takes calls for filter open, close, and filter selection change
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';
import Filter from 'components/molecules/Filter';

const optionsProps = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
      ]).isRequired,
    }),
  ),
);

const dimensionProps = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  }),
);

const propTypes = {
  dimensions: dimensionProps.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: optionsProps.isRequired,
  selections: optionsProps.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function ItemsFilter({
  dimensions,
  options,
  selections,
  onSelect,
  isOpen,
  onClose,
  onOpen,
}) {
  return (
    <>
      <Button
        iconLeft="filter"
        label="Filter"
        variant="secondary"
        onClick={onOpen}
      />
      <Filter
        isOpen={isOpen}
        options={options}
        segments={dimensions}
        selections={selections}
        onClose={onClose}
        onOpen={onOpen}
        onSelectionsChange={onSelect}
      />
    </>
  );
}

ItemsFilter.propTypes = propTypes;

export default ItemsFilter;
