import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  Search,
} from '@makeably/creativex-design-system';
import DeleteButton from 'components/atoms/DeleteButton';
import {
  emptyState,
  getDateTimeDimension,
} from 'components/internal/shared';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  addToast,
  addErrorToast,
} from 'components/organisms/Toasts';
import {
  isDefined,
  getItemSortBy,
} from 'utilities/item';
import { searchItems } from 'utilities/itemSearch';
import { destroy } from 'utilities/requests';
import {
  newInternalGuidelineSubscriptionPath,
  internalGuidelineSubscriptionPath,
} from 'utilities/routes';
import {
  getPage,
  getParams,
} from 'utilities/url';

const subscriptionProps = PropTypes.shape({
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  guidelineId: PropTypes.number.isRequired,
});

const propTypes = {
  guidelineId: PropTypes.number.isRequired,
  guidelineName: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(subscriptionProps).isRequired,
};

const headers = [
  {
    key: 'id',
    label: 'Guideline ID',
  },
  {
    label: 'Company',
    key: 'companyName',
  },
  {
    key: 'createdAt',
    label: 'Subscribed',
  },
  {
    key: 'unsubscribe',
    label: '',
    sortable: false,
  },
];

const searchKeys = [
  'companyName',
  'id',
];

function getItems(subscriptions, handleUnsubscribe) {
  return subscriptions.map(({
    companyId,
    companyName,
    createdAt,
    guidelineId,
  }) => ({
    companyId: { value: companyId },
    companyName: { value: companyName },
    createdAt: getDateTimeDimension(createdAt),
    id: { value: guidelineId },
    unsubscribe: {
      element: (
        <DeleteButton
          label="Unsubscribe"
          onClick={() => handleUnsubscribe(companyId)}
        />
      ),
      value: companyId,
    },
  }));
}

function Subscriptions({
  guidelineId,
  guidelineName,
  subscriptions,
}) {
  const params = getParams(window);
  const [search, setSearch] = useState('');
  const [confirmUnsubscribeId, setConfirmUnsubscribeId] = useState(null);
  const [sort, setSort] = useState({
    key: 'companyName',
    asc: false,
  });
  const [page, setPage] = useState(getPage(params));

  const handleUnsubscribe = async (id) => {
    const { isError } = await destroy(internalGuidelineSubscriptionPath(guidelineId, id));

    if (isError) {
      addErrorToast('Could not unsubscribe. Please try again later');
    } else {
      addToast('Unsubscribed from template');
      window.location.reload();
    }
  };

  const items = useMemo(() => (
    getItems(subscriptions, setConfirmUnsubscribeId)
  ), [subscriptions]);

  const searchedItems = useMemo(() => {
    setPage(1);
    return searchItems(items, search, searchKeys);
  }, [items, search]);

  const sortedItems = useMemo(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    return searchedItems.slice().sort(byKeyDir);
  });

  return (
    <>
      <Card className="u-flexColumn u-gap-24">
        <h5>{ `Subscriptions for '${guidelineName} (${guidelineId})'` }</h5>
        <div className="u-flexRow u-justifyBetween u-gap-16">
          <Search
            placeholder="Search by Company, ID"
            value={search}
            onChange={setSearch}
          />
          <AddNewButton
            label="Add Subscriptions"
            url={newInternalGuidelineSubscriptionPath(guidelineId)}
          />
        </div>
        <ItemsTable
          className="u-scrollShadowRight"
          emptyTableContent={emptyState}
          headers={headers}
          items={sortedItems}
          page={page}
          sort={sort}
          onPageChange={setPage}
          onSortChange={setSort}
        />
      </Card>
      <ConfirmationModal
        isOpen={isDefined(confirmUnsubscribeId)}
        message={`Are you sure you want to unsubscribe this company from '${guidelineName}'?`}
        title="Confirm Unsubscribe"
        onClose={() => setConfirmUnsubscribeId(null)}
        onConfirm={() => handleUnsubscribe(confirmUnsubscribeId)}
      />
    </>
  );
}

Subscriptions.propTypes = propTypes;

export default Subscriptions;
