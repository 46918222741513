// Helper functions for generating item display elements from content and display params
//
// To add a new element type, add a generateItemXXX function and call it in generateItemElement
import React from 'react';
import classnames from 'classnames';
import { updateValues } from 'utilities/object';

// Combines overlapping matches, and puts them in order
// Splits item label into an array of matched and unmatched substrings
function getMatchedSnippets(label, matches) {
  const sorted = matches.slice().sort((a, b) => a.start - b.start);
  const combined = sorted.reduce((all, match, index, array) => {
    const afters = array.slice(index + 1);
    const combinedEnd = afters.reduce((newEnd, after) => {
      const hasOverlap = after.start <= newEnd && after.end > newEnd;

      if (hasOverlap) {
        return after.end;
      }
      return newEnd;
    }, match.end);

    return [
      ...all,
      {
        ...match,
        end: combinedEnd,
      },
    ];
  }, []);

  const noOverlaps = combined.reduce((all, match, index, array) => {
    const befores = array.slice(0, index);
    const isContained = befores.some((before) => (
      before.start <= match.start && before.end >= match.end
    ));

    if (isContained) {
      return all;
    }
    return [...all, match];
  }, []);

  const firstEnd = noOverlaps[0]?.start;
  const first = {
    matched: false,
    label: label.slice(0, firstEnd),
  };

  const snippets = noOverlaps.reduce((all, match, index, array) => {
    const nextStart = array[index + 1]?.start;
    const on = {
      matched: true,
      label: label.slice(match.start, match.end),
    };
    const off = {
      matched: false,
      label: label.slice(match.end, nextStart),
    };

    return [...all, on, off];
  }, [first]);

  return snippets.filter((snippet) => snippet.label !== '');
}

// Generates an element showing item label with matched substrings highlighted
export function generateItemMatches(label, matches) {
  if (matches?.length > 0) {
    const snippets = getMatchedSnippets(label, matches);

    return (
      <span>
        { snippets.map((section, index) => (
          <span
            key={`${index}-${section.label}`} // eslint-disable-line react/no-array-index-key
            className={section.matched ? 'highlight' : ''}
          >
            { section.label }
          </span>
        )) }
      </span>
    );
  }
  return label;
}

// @note: onAuxClick handles the middle mouse click
// @note: onContextMenu handles the right mouse click
function generateItemUrl(content, display) {
  const classes = classnames({
    warning: display.warning,
  });

  return (
    <a
      className={classes}
      data-confirm={display.confirmationMessage}
      data-method={display.method}
      download={display.download}
      href={display.url}
      onAuxClick={display.onAuxClick}
      onClick={display.onClick}
      onContextMenu={display.onContextMenu}
    >
      { content }
    </a>
  );
}

// TODO: remove generateItemUrls once AdAccounts actions are updated
function generateItemUrls(urls) {
  return (
    <span>
      { urls.map((display) => (
        <span key={display.url || display.label} className="u-marginRightSm">
          { generateItemUrl(display.label, display) }
        </span>
      )) }
    </span>
  );
}

export function generateItemButton(content, display) {
  return (
    <button
      className="button-link"
      data-testid={display.testId}
      disabled={display.disabled}
      type="button"
      onClick={display.button || display}
    >
      { content }
    </button>
  );
}

function generateItemImage(url, image) {
  return (
    <img
      alt={image.alt}
      className={image.className}
      src={url}
    />
  );
}

export function generateItemElement(label, display) {
  let content = label;

  if (display.matches) {
    content = generateItemMatches(content, display.matches);
  }

  if (display.url) {
    return generateItemUrl(content, display);
  }

  if (display.urls) {
    return generateItemUrls(display.urls);
  }

  if (display.button) {
    return generateItemButton(content, display);
  }

  if (display.img) {
    return generateItemImage(label, display.img);
  }

  return content;
}

export function setItemElement(item) {
  const setElement = (data) => {
    const label = data?.label ?? data?.text ?? data?.value?.toString();

    if (label !== undefined && data?.display !== undefined) {
      const element = generateItemElement(label, data.display);

      return {
        ...data,
        element,
      };
    }
    return data;
  };

  return updateValues(item, setElement);
}

export function setItemsElement(items) {
  return items.map((item) => setItemElement(item));
}
