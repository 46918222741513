import AjaxContainer from "components/higher_order_components/ajax_container.coffee"
import AuditCreativeCard from "components/audit/audit_creative_card"
import AuditPerformanceSelector from "components/audit/audit_performance_selector"
import CustomFiltersFormContainer from "components/settings/custom_filters_form_container"
import FormContainer from "components/higher_order_components/form_container"
import ImageCardList from "components/containers/image_card_list"
import ImageCardWithMetrics from "components/category/image_card_with_metrics"
import ImageCardWithMetricsCollection from "components/image_cards/image_card_with_metrics_collection"
import InfiniteScrollContainer from "components/higher_order_components/infinite_scroll_container"
import ModalContainer from "components/higher_order_components/modal_container"
import SearchForm from "components/search/search_form"
import SnapchatAdAccountSelect from "components/account_setup/snapchat_ads/SnapchatAdAccountSelect"

# NB: This file is to place random containers so we don't keep generating tons of files with one line
#
# This is a div that contains a controlled ImageCardList
export AjaxImageCardList = AjaxContainer('div', ImageCardList, true)

# Modal Launch Button
import { Button } from  "@duik/it"
export ModalLaunchButton = ModalContainer(Button)

# Image Cards wrapped to include control for a primary modal click action
export ImageCardsWithModal = ModalContainer(ImageCardWithMetricsCollection)
export ImageCardWithModal = ModalContainer(ImageCardWithMetrics)
export AuditCreativeCards = InfiniteScrollContainer(AuditCreativeCard)

# Audit Performance Selection Form
export AuditPerformanceForm = FormContainer(AuditPerformanceSelector)

# Snapchat Ad Account Selection
export SnapchatAdAccountSelectForm = FormContainer(SnapchatAdAccountSelect)

# Custom Filters Creation Form
export CustomFiltersForm = FormContainer(CustomFiltersFormContainer)
