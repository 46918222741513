import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Logo,
  Tag,
} from '@makeably/creativex-design-system';
import { useViewPage } from 'utilities/mixpanel';
import { thirdPartyIntegrationConfigPath } from 'utilities/routes';
import styles from './IntegrationConfigs.module.css';

const propTypes = {
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      hasEligibilityRules: PropTypes.bool,
      id: PropTypes.number,
      logoUrl: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
    }),
  ).isRequired,
};

function renderLogo(logoUrl) {
  if (logoUrl) {
    return <img alt="Platform Logo" src={logoUrl} />;
  }

  return <Logo name="DEFAULT" />;
}

function renderConfigCard({
  description,
  hasEligibilityRules,
  id,
  logoUrl,
  name,
  state,
}) {
  return (
    <Card key={id}>
      <div className="u-flexRow u-alignCenter u-gap-8">
        <div className={styles.logo}>
          { renderLogo(logoUrl) }
        </div>
        <h5>{ name }</h5>
        { state === 'pending' && <Tag color="orange" label="Pending" /> }
        { !hasEligibilityRules && <Tag color="red" label="Missing Eligibility" /> }
      </div>
      <div className="t-body-1 u-marginTop-16">
        { description }
      </div>
      <div className={`u-marginTop-24 ${styles.button}`}>
        <Button
          iconRight="arrowRight"
          label="View Details"
          url={thirdPartyIntegrationConfigPath(id)}
          variant="tertiary"
        />
      </div>
    </Card>
  );
}

function IntegrationConfigs({ configs }) {
  useViewPage();

  return (
    <div className={styles.cards}>
      { configs.map(renderConfigCard) }
    </div>
  );
}

IntegrationConfigs.propTypes = propTypes;

export default IntegrationConfigs;
