import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Button,
  Card,
  Checkbox,
  Search,
} from '@makeably/creativex-design-system';
import { emptyState } from 'components/internal/shared';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  addToast,
  addErrorToast,
} from 'components/organisms/Toasts';
import { getItemSortBy } from 'utilities/item';
import { searchItems } from 'utilities/itemSearch';
import {
  addProperties,
  toggleProperty,
} from 'utilities/object';
import {
  addFormDataArray,
  post,
} from 'utilities/requests';
import { internalGuidelineSubscriptionsPath } from 'utilities/routes';
import {
  getPage,
  getParams,
} from 'utilities/url';

const propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  guidelineId: PropTypes.number.isRequired,
  guidelineName: PropTypes.string.isRequired,
};

const headers = [
  {
    key: 'selected',
    label: 'Subscribe',
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'id',
    label: 'Company ID',
  },
];

const searchKeys = [
  'company',
  'id',
];

function getItems(companies, selected, handleSelect) {
  return companies.map(({ id, name }) => {
    const isSelected = Boolean(selected[id]);

    return {
      company: { value: name },
      id: { value: id },
      selected: {
        element: (
          <Checkbox
            checked={isSelected}
            onChange={() => handleSelect(id)}
          />
        ),
        value: isSelected,
      },
    };
  });
}

function NewSubscription({
  companies,
  guidelineId,
  guidelineName,
}) {
  const params = getParams(window);
  const [selected, setSelected] = useState({});
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    key: 'company',
    asc: true,
  });
  const [page, setPage] = useState(getPage(params));
  const [isAdding, setIsAdding] = useState(false);
  const selectedCount = Object.values(selected).length;

  const handleSelect = (id) => {
    setSelected((last) => toggleProperty(last, id));
  };

  const items = useMemo(() => (
    getItems(companies, selected, handleSelect)
  ), [companies, selected]);

  const searchedItems = useMemo(() => {
    setPage(1);
    return searchItems(items, search, searchKeys);
  }, [items, search]);

  const sortedItems = useMemo(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    return searchedItems.slice().sort(byKeyDir);
  });

  const handleSelectAll = () => {
    const ids = items.map(({ id }) => id.value);

    setSelected((last) => addProperties(last, ids));
  };

  const handleClose = () => {
    window.location.replace(internalGuidelineSubscriptionsPath(guidelineId));
  };

  const handleAdd = async () => {
    setIsAdding(true);
    const companyIds = Object.keys(selected);

    const url = internalGuidelineSubscriptionsPath(guidelineId);
    const formData = new FormData();
    addFormDataArray(formData, 'company_ids', companyIds);

    const { isError } = await post(url, formData);

    if (isError) {
      addErrorToast('Could not add subscriptions. Please try again later');
      setIsAdding(false);
    } else {
      addToast(`Added ${companyIds.length} subscriptions`);
      handleClose();
    }
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <h5>{ `Add subscriptions for '${guidelineName} (${guidelineId})'` }</h5>
      <Search
        placeholder="Search by Company , ID"
        size="large"
        value={search}
        onChange={setSearch}
      />
      <div className="u-flexRow u-alignCenter u-gap-16">
        { `${selectedCount} selected` }
        <Button
          label="Select All"
          variant="tertiary"
          onClick={handleSelectAll}
        />
        <Button
          disabled={selectedCount === 0}
          label="Select None"
          variant="tertiary"
          onClick={() => setSelected({})}
        />
      </div>
      <ItemsTable
        className="u-scrollShadowRight"
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
      <div className="u-flexRow u-justifyEnd u-gap-8">
        <Button
          label="Cancel"
          variant="secondary"
          onClick={handleClose}
        />
        <ActionButton
          active={isAdding}
          disabled={selectedCount === 0}
          label="Add"
          onClick={handleAdd}
        />
      </div>
    </Card>
  );
}

NewSubscription.propTypes = propTypes;

export default NewSubscription;
