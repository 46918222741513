import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import AssetImage from 'components/molecules/AssetImage';
import { findObjectByValue } from 'utilities/array';
import { stringToInt } from 'utilities/number';
import styles from './Campaign.module.css';
import {
  dateToString,
  toConciseSpend,
} from '../../utilities/string';

const NUM_ASSETS_PREVIEWED = 8;

export const campaignProps = PropTypes.shape({
  accountId: PropTypes.string,
  assets: PropTypes.arrayOf(PropTypes.string),
  brandCode: PropTypes.string,
  brandId: PropTypes.number,
  channel: PropTypes.string,
  channelLabel: PropTypes.string,
  id: PropTypes.number,
  market: PropTypes.string,
  marketCode: PropTypes.string,
  name: PropTypes.string,
  spend: PropTypes.number,
  spendDate: PropTypes.string,
  status: PropTypes.string,
});

export const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

const propTypes = {
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  campaign: campaignProps.isRequired,
  isLoading: PropTypes.bool.isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
  showAssetModal: PropTypes.func.isRequired,
  showCampaignModal: PropTypes.func.isRequired,
};

function renderAsset(info, showAssetModal) {
  const [url, videoLength, videoSource] = info.split('::');

  return (
    <button
      key={url}
      type="button"
      onClick={() => showAssetModal(url, videoSource)}
    >
      <div className={styles.assetPreview}>
        <AssetImage
          alt="Asset Preview"
          src={url}
          videoLength={stringToInt(videoLength)}
        />
      </div>
    </button>
  );
}

function renderEmptyAssets() {
  return (
    <div className={`t-empty ${styles.empty}`}>
      This campaign has no assets to display at this time
    </div>
  );
}

function renderAssets(assets, showAssetModal) {
  const assetCount = assets.length;

  if (assetCount === 0) {
    return renderEmptyAssets();
  }

  const previewAssets = assets.slice(0, NUM_ASSETS_PREVIEWED);

  return (
    <div className="u-flexRow u-gap-24">
      { previewAssets.map((assetInfo) => renderAsset(assetInfo, showAssetModal)) }
    </div>
  );
}

function renderInfo(label, value) {
  return (
    <div className="u-flexRow">
      <div className="t-body-2">{ `${label}:` }</div>
      <div className={`t-body-2 ${styles.id}`}>
        { value }
      </div>
    </div>
  );
}

function Campaign({
  brandOptions,
  campaign,
  isLoading,
  marketOptions,
  showAssetModal,
  showCampaignModal,
}) {
  const {
    accountId,
    assets,
    brandId: initialBrandId,
    channel,
    market: initialMarket,
    name,
    spend,
    spendDate,
  } = campaign;

  const [brand, setBrand] = useState(findObjectByValue(brandOptions, initialBrandId));
  const [market, setMarket] = useState(initialMarket);

  return (
    <Card>
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-alignCenter u-spaceBetween">
          <div className="u-flexRow u-gap-8 u-alignCenter">
            <div className={styles.logo}>
              <ChannelLogo channel={channel} size="size-24" />
            </div>
            <div className={styles.name}>
              { name }
            </div>
          </div>
        </div>
        <Divider />
        <div className={styles.info}>
          { renderInfo('Ad Account ID', accountId) }
          { renderInfo('Date', spendDate ? dateToString(spendDate) : 'N/A') }
          { renderInfo('Spend', toConciseSpend(spend)) }
        </div>
        { renderAssets(assets, showAssetModal) }
        <Divider />
        <div className="u-flexRow u-alignEnd u-spaceBetween">
          <div className="u-flexRow u-gap-16">
            <Dropdown
              disabled={isLoading || initialBrandId !== null}
              label="Brand"
              menuProps={{ size: 'medium' }}
              options={brandOptions}
              selected={brand}
              size="medium"
              onChange={(opt) => setBrand(opt)}
            />
            <Dropdown
              disabled={isLoading || initialMarket !== null}
              label="Market"
              menuProps={{ size: 'medium' }}
              options={marketOptions}
              selected={marketOptions.find((opt) => opt.value === market)}
              size="medium"
              onChange={(opt) => setMarket(opt.value)}
            />
          </div>
          <Button
            disabled={isLoading || !(brand && market)}
            label="Map"
            onClick={() => showCampaignModal(campaign, brand, market)}
          />
        </div>
      </div>
    </Card>
  );
}

Campaign.propTypes = propTypes;

export default Campaign;
