import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';
import ItemDetails from 'components/internal/review/ItemDetails';
import {
  NULL_LABEL,
  NULL_VALUE,
  getBoolDimension,
  getDateTimeDimension,
} from 'components/internal/shared';
import { itemProps } from 'utilities/item';
import {
  decamelize,
  titleize,
} from 'utilities/string';

export const definitionProps = PropTypes.shape({
  description: PropTypes.string.isRequired,
  eligibilityAdFormats: PropTypes.string.isRequired,
  eligibilityBrands: PropTypes.string.isRequired,
  eligibilityCampaignObjectives: PropTypes.string.isRequired,
  eligibilityChannels: PropTypes.string.isRequired,
  eligibilityContentTypes: PropTypes.string.isRequired,
  eligibilityMarkets: PropTypes.string.isRequired,
  eligibilityOrientations: PropTypes.string.isRequired,
  eligibilityPlacements: PropTypes.string.isRequired,
  eligibilityPublishers: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  eligibilityCreativeType: PropTypes.string,
  evaluationStrategy: PropTypes.string,
  metadata: PropTypes.objectOf(PropTypes.string),
  updatedAt: PropTypes.string,
});

const propTypes = {
  item: itemProps.isRequired,
  isOpen: PropTypes.bool,
  onIsOpenToggle: PropTypes.func,
};

const defaultProps = {
  isOpen: true,
  onIsOpenToggle: undefined,
};

const details = [
  {
    key: 'state',
    label: 'State',
  },
  {
    key: 'metadata',
    label: 'Metadata',
  },
  {
    key: 'updatedAt',
    label: 'Last Updated',
  },
  {
    key: 'evaluationStrategy',
    label: 'Evaluation Strategy',
  },
];

const eligibilityDetails = [
  {
    key: 'orientations',
    label: 'Orientations',
  },
  {
    key: 'assetType',
    label: 'Asset Type',
  },
  {
    key: 'brands',
    label: 'Brands',
  },
  {
    key: 'markets',
    label: 'Markets',
  },
  {
    key: 'channels',
    label: 'Channels',
  },
  {
    key: 'adFormats',
    label: 'Ad Formats',
  },
  {
    key: 'placements',
    label: 'Placements',
  },
  {
    key: 'publishers',
    label: 'Publishers',
  },
  {
    key: 'contentTypes',
    label: 'Content Types',
  },
  {
    key: 'campaignObjectives',
    label: 'Campaign Objectives',
  },
];

function parseEvaluationStrategyLabel(value) {
  if (typeof value !== 'string') return value;

  return decamelize(value.replace(/EvaluationStrategy:*/g, ''));
}

function getPlacementLabel(value) {
  if (typeof value === 'string') return value;

  return `${titleize(value.publisher)} - ${titleize(decamelize(value.placement))}`;
}

function getJsonArrayDimension(json, getLabel) {
  const values = JSON.parse(json);
  const options = values.map((value) => ({
    label: getLabel?.(value) ?? value,
    value: JSON.stringify(value),
  }));
  const fullLabel = options.length > 0 ? options.map(({ label }) => label).join(',') : NULL_LABEL;

  return {
    label: fullLabel,
    options,
    value: json,
  };
}

export function getDefinitionItem(
  {
    description,
    eligibilityAdFormats,
    eligibilityBrands,
    eligibilityCampaignObjectives,
    eligibilityChannels,
    eligibilityContentTypes,
    eligibilityCreativeType,
    eligibilityMarkets,
    eligibilityOrientations,
    eligibilityPlacements,
    eligibilityPublishers,
    evaluationStrategy,
    id,
    metadata,
    name,
    state,
    updatedAt,
  },
  getChannelLabel,
  getAdFormatLabel,
) {
  return {
    adFormats: getJsonArrayDimension(eligibilityAdFormats, getAdFormatLabel),
    brands: getJsonArrayDimension(eligibilityBrands),
    campaignObjectives: getJsonArrayDimension(eligibilityCampaignObjectives),
    channels: getJsonArrayDimension(eligibilityChannels, getChannelLabel),
    contentTypes: getJsonArrayDimension(eligibilityContentTypes),
    assetType: {
      label: eligibilityCreativeType ? titleize(eligibilityCreativeType) : NULL_LABEL,
      value: eligibilityCreativeType ?? NULL_VALUE,
    },
    description: { value: description },
    evaluationStrategy: {
      label: parseEvaluationStrategyLabel(evaluationStrategy) ?? NULL_LABEL,
      value: evaluationStrategy ?? NULL_VALUE,
    },
    id: { value: id },
    markets: getJsonArrayDimension(eligibilityMarkets),
    metadata: getBoolDimension(Boolean(metadata)),
    orientations: getJsonArrayDimension(eligibilityOrientations),
    placements: getJsonArrayDimension(eligibilityPlacements, getPlacementLabel),
    publishers: getJsonArrayDimension(eligibilityPublishers),
    name: { value: name },
    state: {
      label: titleize(state),
      value: state,
    },
    updatedAt: getDateTimeDimension(updatedAt),
  };
}

function DefinitionDisplay({
  isOpen,
  item,
  onIsOpenToggle,
}) {
  return (
    <div className="u-flexColumn u-gap-24">
      <div className="u-flexColumn u-gap-8">
        <div className="u-flexRow u-justifyBetween">
          <h4>{ `${item.name.value} (${item.id.value})` }</h4>
          { onIsOpenToggle && (
            <Button
              iconLeft={isOpen ? 'chevronUp' : 'chevronDown'}
              variant="round"
              onClick={() => onIsOpenToggle()}
            />
          ) }
        </div>
        <div>{ item.description.value }</div>
      </div>
      { isOpen && (
        <>
          <ItemDetails
            details={details}
            item={item}
          />
          <div>
            <h4 className="u-marginBottom-8">Eligibility</h4>
            <ItemDetails
              details={eligibilityDetails}
              item={item}
            />
          </div>
        </>
      ) }
    </div>
  );
}

DefinitionDisplay.propTypes = propTypes;
DefinitionDisplay.defaultProps = defaultProps;

export default DefinitionDisplay;
