import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  MetricCard,
} from '@makeably/creativex-design-system';
import { metricTooltips } from 'components/creative_lifecycle/shared';
import { getAssetCount } from 'components/creative_lifecycle/utilities';
import MetricVisualization from 'components/reporting/MetricVisualization';
import { getUniqueValues } from 'utilities/array';
import { useViewPage } from 'utilities/mixpanel';
import { valueIf } from 'utilities/object';
import {
  toMultiplier,
  toPercent,
  titleize,
} from 'utilities/string';
import styles from './Usage.module.css';

export const coreAssetProps = PropTypes.shape({
  assetId: PropTypes.number.isRequired,
  assetType: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  campaign: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  measurementPartner: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  postIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  rank: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
});

export const activatedYearProps = PropTypes.shape({
  channel: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
});

const propTypes = {
  filteredCoreAssets: PropTypes.arrayOf(coreAssetProps).isRequired,
  filteredPostIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  qualityRateLabel: PropTypes.string.isRequired,
};

const chartSegments = ['market', 'brand', 'campaign', 'partner'];

function metricObjects(qualityRateLabel) {
  return [
    {
      label: 'Activation Rate',
      tooltip: metricTooltips.activationRate,
      value: 'activationRate',
    },
    {
      label: 'Repurposed Rate',
      tooltip: metricTooltips.repurposedRate,
      value: 'repurposedRate',
    },
    {
      label: 'Reusage Rate',
      tooltip: metricTooltips.reusageRate,
      value: 'reusageRate',
    },
    {
      format: 'percentage',
      label: qualityRateLabel,
      tooltip: metricTooltips.qualityRate,
      value: 'qualityRate',
    },
  ];
}

function getBaseMetrics(assets, filteredPostIds) {
  const metricCounts = assets.reduce((totals, asset) => {
    const legitPost = asset.channel && asset.market && asset.assetId && asset.rank
      && asset.postIds.some((postId) => filteredPostIds.has(postId));
    const filteredAssetPostIds = asset.postIds.filter((postId) => filteredPostIds.has(postId));
    const uniquePostsLength = getUniqueValues(filteredAssetPostIds, [...totals.postIds]).length;

    return {
      activatedAssets:
        legitPost ? totals.activatedAssets.add(asset.uuid) : totals.activatedAssets,
      activatedIds:
        legitPost ? totals.activatedIds.add(asset.assetId) : totals.activatedIds,
      uniqueCoreAssets: totals.uniqueCoreAssets.add(asset.uuid),
      postIds: legitPost ? new Set([...totals.postIds, ...filteredAssetPostIds]) : totals.postIds,
      qualityCount: totals.qualityCount
        + (legitPost && asset.rank === 'highest' ? uniquePostsLength : 0),
    };
  }, {
    activatedAssets: new Set([]),
    activatedIds: new Set([]),
    uniqueCoreAssets: new Set([]),
    postIds: new Set([]),
    qualityCount: 0,
  });

  return {
    activatedCoreAssets: metricCounts.activatedAssets.size,
    matchedAssets: metricCounts.activatedIds.size,
    assetCount: metricCounts.uniqueCoreAssets.size,
    localizations: metricCounts.postIds.size,
    qualityCount: metricCounts.qualityCount,
  };
}

function getBins(assets) {
  const emptyBin = { assets: [] };

  return assets.reduce((bins, asset) => {
    const marketBin = bins.marketBins[asset.market] ?? emptyBin;
    const brandBin = bins.brandBins[asset.brand] ?? emptyBin;
    const campaignBin = bins.campaignBins[asset.campaign] ?? emptyBin;
    const partnerBin = bins.partnerBins[asset.partner] ?? emptyBin;

    return {
      marketBins: {
        ...bins.marketBins,
        ...valueIf(asset.market, asset.market, { assets: [...marketBin.assets, asset] }),
      },
      brandBins: {
        ...bins.brandBins,
        [asset.brand]: {
          assets: [...brandBin.assets, asset],
        },
      },
      campaignBins: {
        ...bins.campaignBins,
        [asset.campaign]: {
          assets: [...campaignBin.assets, asset],
        },
      },
      partnerBins: {
        ...bins.partnerBins,
        [asset.partner]: {
          assets: [...partnerBin.assets, asset],
        },
      },
    };
  }, {
    marketBins: {},
    brandBins: {},
    campaignBins: {},
    partnerBins: {},
  });
}

function getBinActivationRates(key, bins, filteredPostIds, allAssets) {
  const vizItems = Object.keys(bins).map((bin, index) => {
    const { activatedCoreAssets, assetCount } = getBaseMetrics(bins[bin].assets, filteredPostIds);
    const assetCountToUse = allAssets ? getAssetCount(allAssets) : assetCount;
    const activationRate = (activatedCoreAssets / assetCountToUse);

    return {
      id: { value: bin },
      index: { value: index + 1 },
      [key]: { value: bin },
      activationRate: {
        value: Math.round(activationRate * 1000) / 10,
        label: key === 'market' && !activationRate ? undefined : toPercent(activationRate, 1),
      },
    };
  });

  return (vizItems
    .filter(({ activationRate }) => activationRate.label !== undefined)
    .sort((a, b) => a.activationRate.value - b.activationRate.value)
  );
}

function getVizItems(coreAssets, filteredPostIds) {
  const {
    marketBins,
    brandBins,
    campaignBins,
    partnerBins,
  } = getBins(coreAssets);
  return {
    market: getBinActivationRates('market', marketBins, filteredPostIds, coreAssets),
    brand: getBinActivationRates('brand', brandBins, filteredPostIds),
    campaign: getBinActivationRates('campaign', campaignBins, filteredPostIds),
    partner: getBinActivationRates('partner', partnerBins, filteredPostIds),
  };
}

function getMetricValues(coreAssets, filteredPostIds) {
  const {
    activatedCoreAssets,
    matchedAssets,
    assetCount,
    localizations,
    qualityCount,
  } = getBaseMetrics(coreAssets, filteredPostIds);
  const activationRate = toPercent((activatedCoreAssets / assetCount), 1);
  const repurposedRate = activatedCoreAssets > 0 ? toMultiplier((matchedAssets / activatedCoreAssets), 1) : '0x';
  const reusageRate = activatedCoreAssets > 0 ? toMultiplier((localizations / activatedCoreAssets), 1) : '0x';
  const qualityRate = localizations > 0 ? qualityCount / localizations : null;

  return {
    activationRate,
    repurposedRate,
    reusageRate,
    qualityRate,
    activatedCoreAssets,
    assetCount,
  };
}

const getChartLabel = (segment, sortedItems) => {
  const count = sortedItems[segment] ? sortedItems[segment].length : 0;
  const formattedSegmentName = segment === 'partner' ? 'Production Partner' : titleize(segment);
  return `Activation Rate By ${formattedSegmentName} (${count})`;
};

function Usage({
  filteredCoreAssets,
  filteredPostIds,
  qualityRateLabel,
}) {
  const [metricValues, setMetricValues] = useState({});
  const [sortedItems, setSortedItems] = useState({});

  useViewPage('usage');

  useEffect(() => {
    setMetricValues(getMetricValues(filteredCoreAssets, filteredPostIds));
    setSortedItems(getVizItems(filteredCoreAssets, filteredPostIds));
  }, [filteredCoreAssets, filteredPostIds]);

  const renderMetricCard = (metric) => {
    const { activatedCoreAssets, assetCount } = metricValues;

    const metricWithValue = {
      ...metric,
      value: assetCount > 0 ? metricValues[metric.value] : 'No data',
    };
    if (metric.value === 'activationRate' && assetCount > 0) {
      metricWithValue.caption = `${activatedCoreAssets} activated core assets out of ${assetCount} core assets uploaded`;
    }

    return (
      <div key={metric.value} className="u-col-3">
        <MetricCard alert={false} metric={metricWithValue} />
      </div>
    );
  };

  return (
    <div className="u-flexColumn u-gap-24">
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-gap-16 u-justifyBetween">
          { metricObjects(qualityRateLabel).map((metric) => renderMetricCard(metric)) }
        </div>
      </div>
      { chartSegments.map((segment) => (
        <Card key={segment} padding={false}>
          <h5 className={styles.chartLabel}>{ getChartLabel(segment, sortedItems) }</h5>
          <Divider />
          <MetricVisualization
            displayMetric={{ value: 'activationRate' }}
            items={sortedItems[segment] || []}
            selectedSegments={[{ value: segment }]}
          />
        </Card>
      )) }
    </div>
  );
}

Usage.propTypes = propTypes;

export default Usage;
