import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';
import ItemDetails from 'components/internal/review/ItemDetails';
import {
  getBoolDimension,
  getDateTimeDimension,
} from 'components/internal/shared';
import { itemProps } from 'utilities/item';
import {
  decamelize,
  titleize,
} from 'utilities/string';

export const guidelineProps = PropTypes.shape({
  applicability: PropTypes.string.isRequired,
  brandCue: PropTypes.bool.isRequired,
  branded: PropTypes.bool.isRequired,
  company: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  evaluationOrder: PropTypes.number.isRequired,
  global: PropTypes.bool.isRequired,
  guidelineType: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  organizationOrder: PropTypes.number.isRequired,
  paid: PropTypes.bool.isRequired,
  samplingRate: PropTypes.number.isRequired,
  standard: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string.isRequired,
  subscriptionCount: PropTypes.number,
});

const propTypes = {
  item: itemProps.isRequired,
  isOpen: PropTypes.bool,
  onIsOpenToggle: PropTypes.func,
};

const defaultProps = {
  isOpen: true,
  onIsOpenToggle: undefined,
};

export const applicabilityLabels = {
  all: 'All',
  audit: 'In-Flight',
  pretest: 'Pre-Flight',
};

export const globalOptions = [
  {
    global: true,
    label: 'Global',
    value: 'global',
  },
  {
    global: false,
    label: 'Local',
    value: 'local',
  },
];

export const roleOptions = [
  {
    label: 'Creative Quality',
    standard: true,
    brandCue: false,
    value: 'creativeQuality',
  },
  {
    label: 'Creative Multiplier',
    standard: false,
    brandCue: false,
    value: 'creativeMultiplier',
  },
  {
    label: 'DBA',
    standard: false,
    brandCue: true,
    value: 'dba',
  },
];

const details = [
  {
    key: 'guidelineType',
    label: 'Guideline Type',
  },
  {
    key: 'role',
    label: 'Guideline Role',
  },
  {
    key: 'applicability',
    label: 'Applicability',
  },
  {
    key: 'global',
    label: 'Global / Local',
  },
  {
    key: 'updatedAt',
    label: 'Last Updated',
  },
  {
    key: 'definitionsUpdatedAt',
    label: 'Definitions Last Updated',
  },
  {
    key: 'organizationOrder',
    label: 'Position',
  },
  {
    key: 'evaluationOrder',
    label: 'Evaluation Postion',
  },
  {
    key: 'samplingRate',
    label: 'Sampling Rate',
  },
  {
    key: 'paid',
    label: 'Include in Usage Reporting',
  },
  {
    key: 'branded',
    label: 'Branded',
  },
  {
    key: 'state',
    label: 'State',
  },
];

export function parseGuidelineTypeLabel(guidelineType) {
  return decamelize(guidelineType.replace(/Guideline:*/g, ''));
}

export function getGuidelineItem({
  applicability,
  brandCue,
  branded,
  company,
  description,
  evaluationOrder,
  global,
  guidelineType,
  id,
  name,
  organizationOrder,
  paid,
  samplingRate,
  standard,
  state,
  updatedAt,
}, definitionsUpdatedAt) {
  const globalOption = globalOptions.find((option) => option.global === global);
  const roleOption = roleOptions.find((option) => (
    option.standard === standard && option.brandCue === brandCue
  ));

  return {
    applicability: {
      label: applicabilityLabels[applicability],
      value: applicability,
    },
    branded: getBoolDimension(branded, true),
    company: { value: company },
    definitionsUpdatedAt: getDateTimeDimension(definitionsUpdatedAt),
    description: { value: description },
    evaluationOrder: { value: evaluationOrder },
    global: globalOption,
    guidelineType: {
      label: parseGuidelineTypeLabel(guidelineType),
      value: guidelineType,
    },
    id: { value: id },
    name: { value: name },
    organizationOrder: { value: organizationOrder },
    role: roleOption,
    samplingRate: {
      label: `${samplingRate}%`,
      value: samplingRate,
    },
    standard: getBoolDimension(standard, true),
    state: {
      label: titleize(state),
      value: state,
    },
    updatedAt: getDateTimeDimension(updatedAt),
    paid: getBoolDimension(paid, true),
  };
}

function GuidelineDisplay({
  isOpen,
  item,
  onIsOpenToggle,
}) {
  return (
    <div className="u-flexColumn u-gap-24">
      <div className="u-flexColumn u-gap-8">
        <div className="u-flexRow u-justifyBetween">
          <h4>{ `${item.company.value} Guideline: ${item.name.value} (${item.id.value})` }</h4>
          { onIsOpenToggle && (
            <Button
              iconLeft={isOpen ? 'chevronUp' : 'chevronDown'}
              variant="round"
              onClick={() => onIsOpenToggle()}
            />
          ) }
        </div>
        <div>{ item.description.value }</div>
      </div>
      { isOpen && (
        <ItemDetails
          details={details}
          item={item}
        />
      ) }
    </div>
  );
}

GuidelineDisplay.propTypes = propTypes;
GuidelineDisplay.defaultProps = defaultProps;

export default GuidelineDisplay;
