import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  MessageModal,
} from '@makeably/creativex-design-system';

const propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  actionButtonLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  actionButtonLabel: 'Confirm',
  isOpen: false,
  title: '',
  type: undefined,
};

function renderIcon(type) {
  switch (type) {
    case 'error':
      return <Icon color="red" name="exclamationCircle" />;
    case 'warning':
      return <Icon color="orange" name="exclamationTriangle" />;
    default:
      return null;
  }
}

function ConfirmationModal({
  actionButtonLabel,
  isOpen,
  message,
  onClose,
  onConfirm,
  title,
  type,
}) {
  return (
    <MessageModal
      actionButtonLabel={actionButtonLabel}
      isOpen={isOpen}
      title={title}
      onActionButtonClick={onConfirm}
      onClose={onClose}
    >
      <div className="u-flexRow u-gap-8">
        { renderIcon(type) }
        { message }
      </div>
    </MessageModal>
  );
}

ConfirmationModal.propTypes = propTypes;
ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
