import React from "react"
import Button from "components/reusable/button"
import PropTypes from "prop-types"
import classNames from "classnames"
import EndOfList from "components/reusable/end_of_list"
import Loader from "components/reusable/Loader"
import { Icon } from '@makeably/creativex-design-system'

# A higher order component that is responsible for feching data from an end point on user scroll.
# Takes a Component.
#
# Displays Loader while loading is happening and EndOfList end of list is reached
# There are some assumptions here about how `data` is returned... See InfiniteScrollPresenter.rb for more details.
InfiniteScrollContainer = (Component) -> class extends React.Component
  #NB: document must be at least 1px taller than window to trigger scroll, the buffer gurantees the window is scrollable
  BUFFER_HEIGHT = 200
  WINDOW_HEIGHT_TO_TRIGGER_SCROLL = 2

  @propTypes =
    # asset modal allowed on this collection, and uses jquery stuff from the pre-react era. It expects
    # the dom to be structured in a particular way.
    # FUTURE TODO: when moving asset modal to react, remove from here, as infinite scroll shouldn't
    #              have to know about it
    allowModal: PropTypes.bool
    collection: PropTypes.array.isRequired               # an array of props to render Component
    collectionClassName: PropTypes.string
    collectionMessage: PropTypes.string                  # a message to display above the collection
    emptyCollectionMessage: PropTypes.string.isRequired
    onContentChange: PropTypes.func                       # For use as a controlled component
    pagination: PropTypes.shape(
      currentPage: PropTypes.number
      perPage: PropTypes.number
      getMoreUrl: PropTypes.string
    )
    scrollToId: PropTypes.string
    pageOfElement: PropTypes.number

  @defaultProps =
    pagination: {}
    scrollToId: null
    pageOfElement: null

  classes: ->
    classNames(
      'infiniteScrollContainer',
      'infiniteScrollContainer--noResults' if @state.collection.length == 0,
      @props.styleName
    )

  constructor: (props) ->
    super(props)

    @state =
      collection: props.collection
      currentPage: props.pagination.currentPage
      isLoadingData: false
      loadedAllData: false
      newContentLocation: null
      newIdx: null
      showScrollTopButton: false
      getMoreUrl: props.pagination.getMoreUrl

  # TODO: Need to update
  #  * Move data fetching code or side effects to componentDidUpdate.
  #  * If you're updating state whenever props change, refactor your code to
  #     use memoization techniques or move it to static getDerivedStateFromProps.
  #     Learn more at: https://fb.me/react-derived-state
  #
  # Since we render off of state, we want to re-set the state if we get new
  # props. Constructor will not be called again.
  # NB: Used in conjunction with an AjaxContainer, see AjaxImageCardList in
  # miscellaneous_containers.js.jsx.coffee
  UNSAFE_componentWillReceiveProps: (nextProps) ->
    @setState(
      collection: nextProps.collection
      currentPage: nextProps.pagination.currentPage
    )

  # NB: For use when it is acting as a controlled component with ajax_container
  componentDidUpdate: (_prevProps, prevState) ->
    if @props.onContentChange and prevState.isLoadingData isnt @state.isLoadingData
      changes =
        collection: @state.collection
        pagination: $.extend(true, @props.pagination, { currentPage: @state.currentPage })
      @props.onContentChange(changes, @state.isLoadingData)

  # Adds height to the document if the window is not scrollable
  # (can't infinite scroll if you can't trigger the first scroll event!)
  ensureScrollabeDocument: ->
    if $(document).height() - $(window).height() <= BUFFER_HEIGHT
      $('body').css('min-height', $(document).height() + BUFFER_HEIGHT)

  toggleScrollButton: =>
    if $(window).scrollTop() > $(window).height()
      @setState({ showScrollTopButton: true })
    else
      @setState({ showScrollTopButton: false })

  scrollHandler: =>
    return if @state.isLoadingData or @state.loadedAllData

    windowScrollTop = $(window).scrollTop()
    windowHeight = $(window).height()

    documentHeight = $(document).height()

    # NB: getMore if we are within N windows of the bottom
    if WINDOW_HEIGHT_TO_TRIGGER_SCROLL * windowHeight > documentHeight - (windowHeight + windowScrollTop)
      @getMore()

  # is the list even paginatable?
  paginatableList: =>
    @props.pagination.currentPage

  #initial paginated set is returned, but the height of the window will not allow scrolling
  getMore: =>
    @setState({ isLoadingData: true })

    $.get(
      @state.getMoreUrl, { page: @state.currentPage + 1 }
    ).done((resp) =>
      newCollection = resp.collection
      isLastPage = newCollection.length < @props.pagination.perPage
      @setState(
        collection: @state.collection.concat(resp.collection)
        currentPage: @state.currentPage + 1
        loadedAllData: isLastPage
      )
    ).fail( ->
      Materialize.toast("Oops... something went wrong.", 'rounded')
    ).always(=>
      @setState({ isLoadingData: false })
      $(window).off 'scroll' if @state.loadedAllData
    )

  scrollTop: ->
    $('body, html').animate({ "scrollTop": "0" }, 300)

  scrollToElement: (elementId) ->
    element = $("##{elementId}")
    if element.length
      # Element is visible, scroll to it
      $(".railsPageContentLayout").animate({ scrollTop: element.offset().top }, 300)

  componentDidMount: ->
    return unless @paginatableList()

    if @props.scrollToId and @props.pageOfElement
      @setState(
        currentPage: @props.pageOfElement,
        getMoreUrl: @props.pagination.getMoreUrl.replace(/scroll_to_page=\d+/, "")
      )
      @scrollToElement(@props.scrollToId)

    @ensureScrollabeDocument()
    $('.railsPageContentLayout').on 'scroll', _.throttle(@scrollHandler, 300, this)
    $('.railsPageContentLayout').on 'scroll', _.throttle(@toggleScrollButton, 500, this)
    # TODO: this is required for the asset modal to load more. Legacy approach, if/when the asset modal moves to
    #       react, use an alternative approach
    $(document).on 'InfiniteScroll.getMore', _.throttle(@getMore, 300, this)

  componentWillUnmount: ->
    return unless @paginatableList()

    $(window).off 'scroll'

  # For modal navigation, used to get the contentLocation of the modal at the given idx in the collection
  getModalContentLocationAtIdx: (idx) =>
    return unless idx >= 0 and idx < @state.collection.length
    newContentLocation = @state.collection[idx]['modal']['contentLocation']

    @setState(
      newContentLocation: newContentLocation,
      newIdx: idx
    )

  renderCollectionMessage: ->
    if @state.collection.length == 0
      `<div className="center" >
        <h5 className='center'>{this.props.emptyCollectionMessage}</h5>
      </div>`
    else if @props.collectionMessage
      `<div >
        <h5 >{this.props.collectionMessage}</h5>
      </div>`

  renderLoader: ->
    if @state.isLoadingData
      `<Loader />`

  renderEndOfList: ->
    if @state.loadedAllData
      `<EndOfList />`

  render: ->
    scrollTopClasses = classNames(
      'btn-floating',
      'scrollTopBtn',
      'scrollTopBtn--show': !@state.showScrollTopButton
    )

    modalNavProps = {
      getModalContentLocationAtIdx: @getModalContentLocationAtIdx,
      lastIdx: @state.collection.length - 1,
      newContentLocation: @state.newContentLocation,
      newIdx: @state.newIdx
    }

    # extracted out to appease fat arrow linter and to add modalNavProps to each component
    renderedCollection = @state.collection.map((componentProps, idx) ->
      key = componentProps.id || idx
      `<Component
        key={key}
        idx={idx}
        modalNavProps={modalNavProps}
        {...componentProps}
      />`
    )

    `<div>
      {this.renderCollectionMessage()}

      <div className={this.props.collectionClassName}>
        {renderedCollection}
      </div>

      <a className={scrollTopClasses} onClick={this.scrollTop} >
        <Icon name="arrowUp" color='darkPurple' />
      </a>

      {this.renderLoader()}
      {this.renderEndOfList()}
    </div>`

export default InfiniteScrollContainer
