import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Toggle,
  Tooltip,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import { gridOptionProps } from 'components/admin/review_queue/post_info/GridSelector';
import Video from 'components/molecules/Video';
import VideoPlaybackRateControls, { defaultPlaybackRate } from 'components/molecules/VideoPlaybackRateControls';
import CustomVideo from 'components/organisms/VideoPlayer';
import Gridlines from 'components/reusable/GridLines';
import styles from './Asset.module.css';

const propTypes = {
  gridOptions: gridOptionProps.isRequired,
  isVideo: PropTypes.bool.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  auditAssetId: PropTypes.number,
  checkId: PropTypes.number,
  onAssetReady: PropTypes.func,
};

const defaultProps = {
  auditAssetId: undefined,
  checkId: undefined,
  onAssetReady: undefined,
};

const ASSET_WIDTH = 300;

function updateSelectedCoordinates(x, y, selectedCoords, setSelectedCoords) {
  const filteredCoordinates = selectedCoords.filter((p) => !(p.x === x && p.y === y));

  if (filteredCoordinates.length === selectedCoords.length) {
    setSelectedCoords((prevState) => [...prevState, {
      x,
      y,
    }]);
  } else {
    setSelectedCoords(filteredCoordinates);
  }
}

function Asset({
  auditAssetId,
  checkId,
  gridOptions,
  isVideo,
  onAssetReady,
  sourceUrl,
}) {
  const [firstRender, setFirstRender] = useState(true);

  const [gridHeight, setGridHeight] = useState(1);
  const [gridOffsetLeft, setGridOffsetLeft] = useState(0);
  const [gridOffsetTop, setGridOffsetTop] = useState(0);
  const [allGridOptions, setAllGridOptions] = useState(gridOptions);
  const [gridWidth, setGridWidth] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(defaultPlaybackRate);
  const [selectedCoordinates, setSelectedCoordinates] = useState([]);
  const [useOldPlayer, setUseOldPlayer] = useLocalStorage('reviewVideoPlayer', false);

  useEffect(() => setFirstRender(false), []);

  useEffect(() => {
    if (!firstRender) {
      setAllGridOptions(gridOptions);
    }
  }, [gridOptions]);

  useEffect(() => {
    setPlaybackRate(defaultPlaybackRate);
    setSelectedCoordinates([]);
  }, [auditAssetId, checkId]);

  useEffect(() => {
    setGridHeight(1);
    setGridOffsetLeft(0);
    setGridOffsetTop(0);
    setGridWidth(1);
  }, [sourceUrl]);

  const handleSelectedCoordinates = (x, y) => {
    updateSelectedCoordinates(x, y, selectedCoordinates, setSelectedCoordinates);
  };

  const setImageGrid = (event) => {
    const image = event.target;
    const {
      width,
      height,
    } = image;

    const imageRectangle = image.getBoundingClientRect();
    const imageParentRectangle = image.parentElement.getBoundingClientRect();

    // Given the image and image parent div, find the distance from the top left
    // corner of the image parent div to the top left corner of the image
    const newGridOffsetLeft = imageRectangle.x - imageParentRectangle.x;
    const newGridOffsetTop = imageRectangle.y - imageParentRectangle.y;

    setGridWidth(width);
    setGridHeight(height);
    setGridOffsetLeft(newGridOffsetLeft);
    setGridOffsetTop(newGridOffsetTop);
  };

  const handleImageLoad = (event) => {
    setImageGrid(event);
    onAssetReady?.();
  };

  const setVideoGrid = (htmlVideo) => {
    const videoContainerRect = htmlVideo.getBoundingClientRect();

    // By default, the video width and height will equal the container size
    let videoWidth = videoContainerRect.width;
    let videoHeight = videoContainerRect.height;
    let newGridOffsetTop = 0;
    let newGridOffsetLeft = 0;

    const videoAspectRatio = (htmlVideo.videoWidth / htmlVideo.videoHeight);

    // If container width:height ratio is less than actual width:height ratio, we
    // know that the video is bound by it's width
    const boundByWidth = (videoWidth / videoHeight) <= videoAspectRatio;

    if (boundByWidth) {
      // When bound by width, find the real video height
      videoHeight = (htmlVideo.videoHeight / htmlVideo.videoWidth) * videoWidth;
      // The video will be centered in the container, so the offset will be half
      // of the difference from the video container height and
      newGridOffsetTop = Math.ceil((videoContainerRect.height - videoHeight) / 2);
    } else {
      videoWidth = videoAspectRatio * videoHeight;
      newGridOffsetLeft = Math.ceil((videoContainerRect.width - videoWidth) / 2);
    }

    const newGridWidth = Math.ceil(videoWidth);
    const newGridHeight = Math.ceil(videoHeight);

    setGridWidth(newGridWidth);
    setGridHeight(newGridHeight);
    setGridOffsetLeft(newGridOffsetLeft);
    setGridOffsetTop(newGridOffsetTop);
  };

  const handleVideoReady = (htmlVideo) => {
    setVideoGrid(htmlVideo);
    onAssetReady?.();
  };

  const {
    gridFillColor,
    gridlineColor,
    showGridlines,
  } = allGridOptions;

  let selectedCoordinateCount;
  if (showGridlines && selectedCoordinates.length > 0) {
    selectedCoordinateCount = `${selectedCoordinates.length * 2.5}%`;
  }

  const toggleLabel = (
    <div className="u-flexRow u-gap-8 u-alignCenter">
      Use old video player
      <Tooltip label="The old video player will be removed as soon as we confirm that the new player is working as intended." />
    </div>
  );

  return (
    <div className={styles.column}>
      <div className={styles.assetWrapper}>
        <Gridlines
          height={gridHeight}
          hideGrid={!showGridlines}
          lineColor={gridlineColor}
          offsetLeft={gridOffsetLeft}
          offsetTop={gridOffsetTop}
          rectangleColor={gridFillColor}
          selectedCoordinates={selectedCoordinates}
          width={gridWidth}
          onSelection={(x, y) => handleSelectedCoordinates(x, y)}
        >
          { isVideo && useOldPlayer && (
            <Video
              playbackRate={playbackRate}
              url={sourceUrl}
              width={ASSET_WIDTH}
              onError={() => onAssetReady?.()}
              onReady={(reactPlayer) => handleVideoReady(reactPlayer.getInternalPlayer())}
            />
          ) }
          { isVideo && !useOldPlayer && (
            <CustomVideo
              height="420px"
              source={sourceUrl}
              width={`${ASSET_WIDTH}px`}
              hasOptions
              onError={() => onAssetReady?.()}
              onReady={handleVideoReady}
            />
          ) }
          { !isVideo && (
            <img
              alt="audit-post-asset"
              src={sourceUrl}
              width={ASSET_WIDTH}
              onError={() => onAssetReady?.()}
              onLoad={handleImageLoad}
            />
          ) }
        </Gridlines>
        <span className={classNames('t-label-1', styles.gridLabel)}>
          { selectedCoordinateCount }
        </span>
      </div>
      { isVideo && useOldPlayer && (
        <VideoPlaybackRateControls
          playbackRate={playbackRate}
          onRateChange={(rate) => setPlaybackRate(parseFloat(rate))}
        />
      ) }
      { isVideo && (
        <div className="u-marginTop-16">
          <Toggle
            checked={useOldPlayer}
            label={toggleLabel}
            right
            onChange={(e) => setUseOldPlayer(e.target.checked)}
          />
        </div>
      ) }
    </div>
  );
}

Asset.propTypes = propTypes;
Asset.defaultProps = defaultProps;

export default Asset;
