import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Icon,
  Tag,
  Tooltip,
} from '@makeably/creativex-design-system';
import VideoInfo from 'components/atoms/VideoInfo';
import ChannelLogos from 'components/molecules/ChannelLogos';
import { itemValueProps } from 'utilities/item';
import styles from './ActivationCard.module.css';

export const activationProps = {
  auditAssetId: itemValueProps.isRequired,
  channel: itemValueProps.isRequired,
  id: itemValueProps.isRequired,
  market: itemValueProps.isRequired,
  rank: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  score: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  url: itemValueProps.isRequired,
  videoLength: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
};

const propTypes = {
  item: PropTypes.shape(activationProps).isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
};

const defaultProps = {
  onClick: undefined,
  onExpand: undefined,
  selectable: false,
  selected: false,
};

function renderCount(options) {
  const names = options.map(({ label }) => label).join(', ');

  return (
    <Tooltip label={names}>
      { options?.length }
    </Tooltip>
  );
}

function renderChannels(item) {
  const options = item.channel?.options;

  if (options?.length > 2) {
    return renderCount(options);
  }

  return <ChannelLogos channels={options} shownMax={5} />;
}

function getScore(item) {
  const options = item.score?.options;

  if (options?.length > 1) {
    const highest = options.at(-1).label;
    const lowest = options[0].label;

    if (highest === lowest) {
      return highest;
    }

    return `${highest} - ${lowest}`;
  }

  return item.score?.label;
}

function renderScore(item) {
  const rankColors = {
    highest: 'green',
    medium: 'orange',
    lowest: 'red',
  };

  const tooltip = "Your company's default score for this activation";
  const color = rankColors[item.rank?.value];
  const label = getScore(item);

  if (!label) return null;

  return (
    <div className={styles.score}>
      <Tooltip label={tooltip}>
        <Tag color={color} label={getScore(item)} />
      </Tooltip>
    </div>
  );
}

function ActivationCard({
  item,
  onClick,
  onExpand,
  selectable,
  selected,
}) {
  const classes = classNames(
    styles.card,
    { [styles.selected]: selected },
  );

  return (
    <Card className={classes} padding={false}>
      <div className={styles.image}>
        <img alt="Activation Preview" src={item.url?.value} />
        <VideoInfo length={item.videoLength?.value} />
      </div>
      <div className={styles.details}>
        <div>
          <div className="t-label-1">CreativeX ID</div>
          <h5>{ item.auditAssetId?.value }</h5>
        </div>
        <div>
          <div className="t-label-1">Markets</div>
          <h5>{ renderCount(item.market?.options) }</h5>
        </div>
        <div>
          <div className="t-label-1">Channels</div>
          <h5>{ renderChannels(item) }</h5>
        </div>
      </div>
      { renderScore(item) }
      { selected && (
        <div className={styles.overlay}>
          <Icon color="current" name="checkCircle" />
        </div>
      ) }
      <button
        aria-label={`select-asset-${item.id?.value}`}
        className={styles.cardButton}
        type="button"
        onClick={onClick}
      />
      { selectable && (
        <div className={styles.expandButton}>
          <Button
            iconLeft="expand"
            variant="secondary"
            onClick={onExpand}
          />
        </div>
      ) }
    </Card>
  );
}

ActivationCard.propTypes = propTypes;
ActivationCard.defaultProps = defaultProps;

export default ActivationCard;
