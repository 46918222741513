import React, {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
// TODO: update Tabs to TableTabs once they are in the DS
import {
  AddNewButton,
  Button,
  Card,
  Search,
  Tabs,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import DefinitionCard from 'components/internal/guidelines/DefinitionCard';
import {
  definitionProps,
  getDefinitionItem,
} from 'components/internal/guidelines/DefinitionDisplay';
import GuidelineDisplay, {
  getGuidelineItem,
  guidelineProps,
} from 'components/internal/guidelines/GuidelineDisplay';
import {
  emptyState,
  filterProps,
  optionArrayProps,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { searchItems } from 'utilities/itemSearch';
import {
  addProperties,
  toggleProperty,
  objectGroupBy,
} from 'utilities/object';
import { post } from 'utilities/requests';
import {
  editInternalGuidelinePath,
  newAdminGuidelineGuidelineDetailPath,
  internalGuidelineSubscriptionsPath,
  updateStateInternalGuidelinePath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';
import {
  getParams,
  setParam,
} from 'utilities/url';

const propTypes = {
  adFormatOptions: optionArrayProps.isRequired,
  channelOptions: optionArrayProps.isRequired,
  definitions: PropTypes.arrayOf(definitionProps).isRequired,
  guideline: guidelineProps.isRequired,
  filterSelections: filterProps,
  isTemplate: PropTypes.bool,
};

const defaultProps = {
  filterSelections: {},
  isTemplate: false,
};

const filterDimensions = [
  {
    value: 'adFormats',
    label: 'Ad Format',
  },
  {
    value: 'assetType',
    label: 'Asset Type',
  },
  {
    value: 'brands',
    label: 'Brand',
  },
  {
    value: 'campaignObjectives',
    label: 'Campaign Objective',
  },
  {
    value: 'channels',
    label: 'Channel',
  },
  {
    value: 'contentTypes',
    label: 'Content Type',
  },
  {
    value: 'markets',
    label: 'Market',
  },
  {
    value: 'orientations',
    label: 'Orientation',
  },
  {
    value: 'placements',
    label: 'Placement',
  },
  {
    value: 'publishers',
    label: 'Publisher',
  },
];

const searchKeys = [
  'description',
  'id',
  'name',
];

const sortDefault = {
  key: 'channels',
  asc: true,
};

function getTabs(itemsByState, setSelectedTab) {
  const states = ['active', 'inactive'];

  return states.map((value) => ({
    label: `${titleize(value)} (${itemsByState[value]?.length ?? 0})`,
    value,
    onClick: () => setSelectedTab(value),
  }));
}

function getSubscriptionLabel(count) {
  switch (count) {
    case 1:
      return 'View 1 Subscription';
    default:
      return `View ${count} Subscriptions`;
  }
}

function Guideline({
  adFormatOptions,
  channelOptions,
  definitions,
  guideline,
  filterSelections: initialFilterSelections,
  isTemplate,
}) {
  const params = getParams(window);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [search, setSearch] = useState('');
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useLocalStorage('cxIntDefinitionTab', 'active');
  const [openIds, setOpenIds] = useState({});

  const definitionsUpdatedAt = useMemo(() => (
    definitions.reduce((max, { updatedAt }) => (
      updatedAt > (max ?? '') ? updatedAt : max
    ), null)
  ), [definitions]);

  const guidelineItem = useMemo(() => (
    getGuidelineItem(guideline, definitionsUpdatedAt)
  ), [guideline, definitionsUpdatedAt]);

  const items = useMemo(() => {
    const getChannelLabel = (key) => {
      const option = channelOptions.find(({ value }) => value === key);
      return option?.label ?? key;
    };
    const getAdFormatLabel = (input) => {
      const option = adFormatOptions.find(({ value }) => {
        const obj = JSON.parse(value);
        return obj.ad_format === input.ad_format && obj.channel === input.channel;
      });
      return option?.label;
    };

    return definitions.map((def) => getDefinitionItem(def, getChannelLabel, getAdFormatLabel));
  }, [definitions]);

  const filterOptions = useMemo(() => (
    getValueOptions(filterDimensions, items)
  ), [items]);

  const filteredItems = useMemo(() => (
    filterItems(items, filterSelections)
  ), [items, filterSelections]);

  const searchedItems = useMemo(() => (
    searchItems(filteredItems, search, searchKeys)
  ), [filteredItems, search]);

  const tabbedItems = useMemo(() => {
    const itemsByState = objectGroupBy(searchedItems, (item) => item.state.value);
    setTabs(getTabs(itemsByState, setSelectedTab));

    return itemsByState;
  }, [searchedItems]);

  const sortedItems = useMemo(() => {
    const tabItems = tabbedItems[selectedTab]?.slice() ?? [];
    const byKeyDir = getItemSortBy(sortDefault.key, sortDefault.asc);

    return tabItems.sort(byKeyDir);
  }, [tabbedItems, selectedTab]);

  const getTabIds = () => (
    tabbedItems[selectedTab].map(({ id }) => id.value)
  );

  const getTabLabel = (selected) => (
    tabs.find(({ value }) => value === selected)?.label
  );

  const handleAdd = () => {
    setSelectedTab('inactive');
    window.location.replace(newAdminGuidelineGuidelineDetailPath(guideline.id));
  };

  const handleFilterSelect = async (value) => {
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  const updateState = async (state) => {
    const formData = new FormData();
    formData.append('state', state);

    const { isError } = await post(updateStateInternalGuidelinePath(guideline.id), formData);

    if (isError) {
      addErrorToast('Guideline state could not be updated. Please try again later');
    } else {
      addToast('Updated guideline state');
    }
    setSelectedTab('active');
    window.location.reload();
  };

  return (
    <div className="u-flexColumn u-gap-24">
      <Card>
        { isTemplate && guideline.id && (
          <div className="u-flexRow u-justifyEnd u-alignCenter u-gap-8">
            <Button
              iconRight="arrowRight"
              label={getSubscriptionLabel(guideline.subscriptionCount)}
              url={internalGuidelineSubscriptionsPath(guideline.id)}
              variant="tertiary"
            />
          </div>
        ) }
        <GuidelineDisplay item={guidelineItem} />
        <div className="u-flexRow u-justifyEnd u-marginTop-16 u-gap-8">
          { guideline.state === 'editing' && (
            <>
              <Button
                disabled={definitions.length === 0}
                label="Activate"
                variant="secondary"
                onClick={() => updateState('active')}
              />
              <Button
                disabled={definitions.length === 0}
                label="Trial"
                variant="secondary"
                onClick={() => updateState('trial')}
              />
            </>
          ) }
          <Button
            label="Edit"
            url={editInternalGuidelinePath(guideline.id)}
            variant="secondary"
          />
        </div>
      </Card>
      <div className="u-flexColumn u-gap-16">
        <h4>Definitions</h4>
        <div className="u-flexRow u-justifyBetween u-alignEnd u-gap-16">
          <div className="u-flexRow u-alignCenter u-gap-8">
            <ItemsFilter
              dimensions={filterDimensions}
              isOpen={filterOpen}
              options={filterOptions}
              selections={filterSelections}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelect={handleFilterSelect}
            />
            <FilterTags
              dimensions={filterDimensions}
              selections={filterSelections}
              onClick={() => setFilterOpen(true)}
              onRemove={handleFilterSelect}
            />
          </div>
          <AddNewButton
            label="Add Definition"
            onClick={handleAdd}
          />
        </div>
        <div className="u-flexRow u-justifyBetween u-alignEnd u-gap-16">
          <Search
            placeholder="Search Definitions"
            value={search}
            onChange={setSearch}
          />
          <div className="u-flexRow u-gap-16">
            <Button
              label="Close All"
              variant="tertiary"
              onClick={() => setOpenIds({})}
            />
            <Button
              label="Open All"
              variant="tertiary"
              onClick={() => setOpenIds((last) => addProperties(last, getTabIds()))}
            />
          </div>
        </div>
      </div>
      <div>
        <Tabs
          currentTab={getTabLabel(selectedTab)}
          tabs={tabs}
          variant="button"
          showSingleTab
        />
        { sortedItems.length === 0 && emptyState }
        <div className="u-flexColumn u-gap-24">
          { sortedItems.map((item) => (
            <DefinitionCard
              key={item.id.value}
              guideline={guideline}
              isOpen={Boolean(openIds[item.id.value])}
              item={item}
              onIsOpenToggle={() => setOpenIds((last) => toggleProperty(last, item.id.value))}
            />
          )) }
        </div>
      </div>
    </div>
  );
}

Guideline.propTypes = propTypes;
Guideline.defaultProps = defaultProps;

export default Guideline;
